import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/tetris-optimizer/')({
  component: createPage({
    titleKey: 'titles.tetrisOptimizer',
    lazyImport: {
      fn: () => import('opti/tetrisScenario/components/TetrisScenariosList'),
      name: 'TetrisScenariosList',
    },
  }),
})
