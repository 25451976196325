import { i18n } from 'config/i18n'
import { ApiErrorServer, Endpoint, EndpointParam } from 'interfaces/api.interfaces'
import { ApiError, ApiTypingErrors } from 'packages/api'

export enum ApiErrorsCodes {
  NotFound = 'not_found',
  NotValid = 'not_valid',
  UnknownError = 'unknown_error',
}

export const apiTypingErrors = new ApiTypingErrors<ApiError, ApiErrorServer>({
  notValidStatus: 400,
  initialError: () => ({
    message: i18n.get('apiErrors.unknownError'),
    code: ApiErrorsCodes.UnknownError,
  }),
  notFoundError: () => ({
    message: i18n.get('apiErrors.notFound'),
    code: ApiErrorsCodes.NotFound,
  }),
  notValidError: () => ({
    message: i18n.get('apiErrors.notValid'),
    code: ApiErrorsCodes.NotValid,
  }),
  validationReplaceToCamelCase: true,
})

export const { errorInterceptor, createApiError } = apiTypingErrors

export const createEndpoint = (route: string, params?: Record<string, EndpointParam>): Endpoint => {
  let url = route
  if (params) {
    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        const regex = new RegExp(':' + key, 'g')
        url = url.replace(regex, String(params[key]))
      }
    }
  }
  return {
    apiRoute: route,
    url,
  }
}
