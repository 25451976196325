import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/jume/project/$projectId/forecast')({
  component: createPage({
    titleKey: 'titles.forecast',
    lazyImport: {
      fn: () => import('jume/forecast/components/ForecastBlock'),
      name: 'ForecastBlock',
    },
  }),
})
