import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseMasterDataSearchText } from './types'

export const useMasterDataSearchText = create<UseMasterDataSearchText>()(
  devtools(
    (set) => ({
      searchText: '',
      setSearchText: (searchText) => set({ searchText }),
    }),
    {
      store: 'masterDataSearchText',
    },
  ),
)
