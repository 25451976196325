import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseTetrisScenariosParams } from './types'

export const useTetrisScenariosParams = create<UseTetrisScenariosParams>()(
  devtools(
    (set) => ({
      filters: {},
      setFilters: (filters) => set({ filters }),
      clearFilters: () => set({ filters: {} }),
      search: '',
      setSearch: (search) => set({ search }),
      clearSearch: () => set({ search: '' }),
      ordering: null,
      setOrdering: (ordering) => set({ ordering }),
      clearOrdering: () => set({ ordering: null }),
    }),
    {
      store: 'tetrisScenariosParams',
    },
  ),
)
