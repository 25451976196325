import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/jobs-detail/$jobId')({
  component: createPage({
    titleKey: 'titles.job',
    lazyImport: {
      fn: () => import('modules/jobs/components/JobDetailBlock'),
      name: 'JobDetailBlock',
    },
  }),
})
