import { toast } from 'react-toastify'

import { redirect } from '@tanstack/react-router'
import { setAxiosAuthorizationToken } from 'config/axios'
import { i18n } from 'config/i18n'
import storage from 'config/storage'
import { RouterHistory } from 'interfaces/router.interfaces'
import { authApi } from 'modules/auth/api'

export const ssoLoader = async (history?: RouterHistory) => {
  const search = history ? history.location.search : window.location.search
  const params = new URLSearchParams(search)
  const ssoCode = params.get('code')

  if (ssoCode) {
    try {
      window.history.replaceState(null, '', window.location.pathname)
      const { accessToken, refreshToken } = await authApi.getAuthSsoTokens(ssoCode)
      storage.setToken(accessToken, true)
      storage.setRefresh(refreshToken, true)
      setAxiosAuthorizationToken(accessToken)
    } catch (e) {
      toast.error(i18n.get('ssoLoginError'))
      if (history) {
        history.push('/login')
      } else {
        redirect({ to: '/login' })
      }
    }
  }
}
