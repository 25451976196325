import { useState } from 'react'

import { ColumnDef, ColumnOrderState } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'
import { isStringArray } from 'ui/Table/helpers/isStringArray'

export const useColumnOrder = <TData extends TRow>(
  columns?: ColumnDef<TData, any>[],
  columnOrder?: ColumnOrderState,
  setColumnOrder?: (columnOrder: ColumnOrderState) => void,
) => {
  const tableColumnOrder = columnOrder ?? (columns ?? []).map((column) => column.id as string)
  const [columnOrderInternal, setColumnOrderInternal] = useState<ColumnOrderState>(tableColumnOrder)
  const onColumnOrderChange = (newColumnOrder: unknown) => {
    if (isStringArray(newColumnOrder)) {
      setColumnOrder?.(newColumnOrder)
    }
  }
  return {
    columnOrderInternal: columnOrder ? tableColumnOrder : columnOrderInternal,
    setColumnOrderInternal: columnOrder ? onColumnOrderChange : setColumnOrderInternal,
  }
}
