import { useEffect } from 'react'

import { useDebounceFn } from 'ahooks'
import { SESSION_OVER_MS } from 'constants/env'
import { useLogout } from 'hooks/useLogout'
import { useLoadingFileCount } from 'modules/session/store'

export const useSessionOver = (cb?: () => void) => {
  const isLoadingFile = useLoadingFileCount((state) => state.fileCount > 0)
  const { logout } = useLogout()
  const { run: sessionOverDebounce, cancel: cancelSessionOver } = useDebounceFn(
    () => {
      cb?.()
      window.addEventListener('beforeunload', logout)
    },
    { wait: SESSION_OVER_MS },
  )

  const addEvents = () => {
    window.addEventListener('mousemove', sessionOverDebounce)
    window.addEventListener('mouseup', sessionOverDebounce)
    window.addEventListener('mousedown', sessionOverDebounce)
    window.addEventListener('wheel', sessionOverDebounce)
    window.addEventListener('keyup', sessionOverDebounce)
    window.addEventListener('keydown', sessionOverDebounce)
  }

  const removeEvents = () => {
    window.removeEventListener('mousemove', sessionOverDebounce)
    window.removeEventListener('mouseup', sessionOverDebounce)
    window.removeEventListener('mousedown', sessionOverDebounce)
    window.removeEventListener('wheel', sessionOverDebounce)
    window.removeEventListener('keyup', sessionOverDebounce)
    window.removeEventListener('keydown', sessionOverDebounce)
  }

  useEffect(() => {
    if (isLoadingFile) {
      removeEvents()
      cancelSessionOver()
      return
    }

    sessionOverDebounce()
    addEvents()

    return () => {
      removeEvents()
      cancelSessionOver()
    }
  }, [isLoadingFile])

  useEffect(() => () => window.removeEventListener('beforeunload', logout), [])
}
