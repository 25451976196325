import { AnimationTypes } from 'components/template/Animation'

export const parseTypeAnimation = (
  typeAnimation: 'scaleTo' | AnimationTypes,
  isTop: boolean,
  position: 'auto' | 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight' = 'auto',
): AnimationTypes => {
  const isCenter = ['auto', 'top', 'bottom'].includes(position)
  const isLeft = ['topLeft', 'bottomLeft'].includes(position)
  const isRight = ['topRight', 'bottomRight'].includes(position)

  let parseType = typeAnimation

  if (typeAnimation === 'scaleTo') {
    parseType = isTop ? AnimationTypes.ScaleToUp : AnimationTypes.ScaleToDown
  }

  if (typeAnimation === AnimationTypes.ScaleIn) {
    if (isTop) {
      if (isCenter) {
        parseType = AnimationTypes.ScaleInTop
      }
      if (isLeft) {
        parseType = AnimationTypes.ScaleInTopLeft
      }
      if (isRight) {
        parseType = AnimationTypes.ScaleInTopRight
      }
    } else {
      if (isCenter) {
        parseType = AnimationTypes.ScaleInBottom
      }
      if (isLeft) {
        parseType = AnimationTypes.ScaleInBottomLeft
      }
      if (isRight) {
        parseType = AnimationTypes.ScaleInBottomRight
      }
    }
  }

  if (typeAnimation === AnimationTypes.ScaleInMini) {
    if (isTop) {
      if (isCenter) {
        parseType = AnimationTypes.ScaleInMiniTop
      }
      if (isLeft) {
        parseType = AnimationTypes.ScaleInMiniTopLeft
      }
      if (isRight) {
        parseType = AnimationTypes.ScaleInMiniTopRight
      }
    } else {
      if (isCenter) {
        parseType = AnimationTypes.ScaleInMiniBottom
      }
      if (isLeft) {
        parseType = AnimationTypes.ScaleInMiniBottomLeft
      }
      if (isRight) {
        parseType = AnimationTypes.ScaleInMiniBottomRight
      }
    }
  }

  return parseType as AnimationTypes
}
