import { FC } from 'react'

import { TaggedText, useTranslate } from '@ayub-begimkulov/i18n'
import { useCurrentRecommendations } from 'demand/recommendations/store'
import { Switch, SwitchColors, SwitchVariants } from 'ui/Switch'

export const RecommendationsSwitch: FC = () => {
  const { open, setOpen } = useCurrentRecommendations()
  const translate = useTranslate()

  return (
    <Switch
      color={SwitchColors.Primary}
      label={
        <TaggedText
          tags={{
            1: (text) => <span className="bold">{text}</span>,
          }}
          text={translate('demand.recommendations.label')}
        />
      }
      onChange={() => setOpen(!open)}
      value={open}
      variant={SwitchVariants.Gradient}
    />
  )
}
