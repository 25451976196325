import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseSelectedInnovationTable } from './types'

export const useSelectedInnovationTable = create<UseSelectedInnovationTable>()(
  devtools(
    (set) => ({
      selectedIds: [],
      setSelectedIds: (state) => {
        set((prev) => {
          const selectedIds = typeof state === 'function' ? state(prev.selectedIds) : state
          return { selectedIds }
        })
      },
      clear: () => set({ selectedIds: [] }),
    }),
    {
      store: 'selectedInnovationTable',
    },
  ),
)
