import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseTwoFactorAuth {
  email: string
  setEmail: (email: string) => void
}

export const useTwoFactorAuth = create<UseTwoFactorAuth>()(
  devtools(
    persist(
      (set) => ({
        email: '',
        setEmail: (email) => set({ email }),
      }),
      { name: 'twoFactorAuth' },
    ),
    { store: 'twoFactorAuth' },
  ),
)
