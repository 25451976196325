import { ReactNode } from 'react'

import { i18n } from 'config/i18n'
import { OptionTreeItem } from 'interfaces/components.interfaces'
import { isEqual, sortBy } from 'lodash'
import { toArray } from 'packages/helper'
import { flattenOptionTree } from 'utils/flattenOptionTree'

interface LabelParsingOptions<ValueType extends string | number | (number | string)[]> {
  isTree?: boolean
  multiple?: boolean
  showMultipleLabel?: boolean
  options?: OptionTreeItem[]
  value?: ValueType | null
  valueInternal?: ValueType | null
  valueStringWhenNotLoaded?: string
  valueTreeWhenNotLoaded?: Record<number, string>
  label?: string
  labelWithItems?: boolean
  selectedElementsNames?: Record<number, string>
  selectedOptions?: OptionTreeItem[]
  placeholder: ReactNode
  showApplyButton: boolean | undefined
  showCounter: boolean | undefined
  valueString?: string
}
export const parseLabel = <ValueType extends string | number | (number | string)[]>({
  isTree,
  multiple,
  showMultipleLabel,
  options,
  value,
  valueInternal,
  valueStringWhenNotLoaded,
  valueTreeWhenNotLoaded,
  label,
  labelWithItems,
  selectedElementsNames,
  selectedOptions,
  placeholder,
  showApplyButton,
  showCounter,
  valueString,
}: LabelParsingOptions<ValueType>) => {
  if (valueString !== undefined && valueString !== null) {
    return valueString
  }

  let labelInternal =
    (!multiple || (multiple && showMultipleLabel) ? valueInternal ?? value : undefined) ??
    valueStringWhenNotLoaded ??
    placeholder ??
    label

  if (!isTree && (!multiple || (multiple && showMultipleLabel))) {
    labelInternal = isEqual((showApplyButton ? value : valueInternal) ?? value, [])
      ? placeholder ?? label
      : flattenOptionTree(options)?.find((item) => item.value === valueInternal)?.label ??
        valueStringWhenNotLoaded ??
        placeholder ??
        label
  }

  if (
    !isTree &&
    multiple &&
    showMultipleLabel &&
    selectedOptions?.length &&
    !isEqual((showApplyButton ? value : valueInternal) ?? value, [])
  ) {
    const values = flattenOptionTree(sortBy(selectedOptions, 'label'))
      ?.filter((item) => toArray((showApplyButton ? value : valueInternal) ?? value).includes(item.value as any))
      .map((item) => item.label)
    labelInternal = showCounter ? values[0] : values.join(', ')
    if (labelInternal === '') {
      labelInternal = valueStringWhenNotLoaded ?? placeholder ?? label
    }
  }

  if (
    isTree &&
    !multiple &&
    showMultipleLabel &&
    valueStringWhenNotLoaded &&
    !flattenOptionTree(options)?.find((item) => item.value === valueInternal)
  ) {
    labelInternal = valueStringWhenNotLoaded
  }

  if (isTree && multiple && showMultipleLabel && valueInternal) {
    if ((valueInternal as unknown as string[]).includes('allSelect')) {
      labelInternal = i18n.get('selectAll')
    } else {
      labelInternal =
        (valueInternal as unknown as number[])
          .map(
            (id) =>
              selectedElementsNames?.[id] ??
              flattenOptionTree(options)?.find((item) => item.value === id)?.label ??
              selectedOptions?.find((item) => item.value === id)?.label ??
              valueTreeWhenNotLoaded?.[id],
          )
          .join(', ') ||
        (placeholder ?? label)
    }
  }

  if (labelWithItems && valueInternal && valueInternal !== 'all' && labelInternal) {
    labelInternal = `${label}: ${
      multiple ? labelInternal : options?.find((option) => option.value === valueInternal)?.label || valueInternal
    }`
  }

  if (labelWithItems && (!valueInternal || valueInternal === 'all' || isEqual(valueInternal, []))) {
    labelInternal = `${label}: ${i18n.get('select.any')}`
  }

  if (!labelInternal) {
    labelInternal = label
  }

  return labelInternal
}
