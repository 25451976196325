import { devtools } from 'config/devtools'
import { SelectionUpdatingData } from 'demand/selections/api'
import { getTypeKf } from 'demand/selections/helpers'
import { last } from 'lodash'
import { deleteUndefined, removeByIndex, updateById, updateByIndex } from 'packages/helper'
import { setOrders } from 'utils/setOrders'
import { create } from 'zustand'

import { UseCurrentSelection } from './types'

export const useCurrentSelection = create<UseCurrentSelection>()(
  devtools(
    (set) => ({
      currentSelectionId: null,
      item: null,
      setCurrentSelectionId: (currentSelectionId) => set({ currentSelectionId }),
      updateItem: (item) =>
        set((prev) => ({ item: item ? ({ ...prev.item, ...item } as SelectionUpdatingData) : null })),
      setCurrentSelection: (item) => set({ item }),
      isSaved: false,
      setIsSaved: (isSaved) => set({ isSaved }),
      createTimeRow: () =>
        set((prev) => ({
          item: {
            ...prev.item,
            time: [
              ...(prev.item?.time || []),
              {
                showTotal: false,
              },
            ],
          },
        })),
      removeTimeRow: (index) =>
        set((prev) => {
          const time = removeByIndex(prev.item?.time, index)
          return {
            item: deleteUndefined({
              ...prev.item,
              time: time.length ? time : undefined,
            }),
          }
        }),
      updateTimePeriod: (index, granularity) =>
        set((prev) => ({
          item: {
            ...prev.item,
            time: updateByIndex(prev.item?.time, index, (item) =>
              deleteUndefined({ ...item, granularity: granularity ?? undefined, start: undefined, end: undefined }),
            ),
          },
        })),
      updateTimeType: (index, type) =>
        set((prev) => ({
          item: {
            ...prev.item,
            time: updateByIndex(prev.item?.time, index, (item) => ({ ...item, type: type ?? undefined })),
          },
        })),
      updateTimeFrom: (index, start) =>
        set((prev) => ({
          item: {
            ...prev.item,
            time: updateByIndex(prev.item?.time, index, (item) => ({ ...item, start })),
          },
        })),
      updateTimeTo: (index, end) =>
        set((prev) => ({
          item: {
            ...prev.item,
            time: updateByIndex(prev.item?.time, index, (item) => ({ ...item, end })),
          },
        })),
      updateShowTotal: (index, showTotal) =>
        set((prev) => ({
          item: {
            ...prev.item,
            time: updateByIndex(prev.item?.time, index, (item) => ({ ...item, showTotal })),
          },
        })),
      addAttribute: (attribute) =>
        set((prev) => {
          const rows = prev.item?.selectionFields?.rows || []
          const lastRow = last(rows)
          const index = lastRow?.type === 'kf' ? (rows.length || 1) - 1 : rows.length || 0
          rows.splice(index, 0, {
            mdAttributeId: attribute.id,
            type: 'attribute',
            order: prev.item?.selectionFields?.rows.length || 1,
            active: false,
          })
          return {
            item: {
              ...prev.item,
              attributes: [...(prev.item?.attributes || []), attribute],
              selectionFields: {
                rows: setOrders(rows),
                columns: prev.item?.selectionFields?.columns || [],
              },
            },
          }
        }),
      removeAttribute: (attributeId) =>
        set((prev) => {
          const attributes = (prev.item?.attributes || []).filter((item) => item.id !== attributeId)
          return {
            item: deleteUndefined({
              ...prev.item,
              attributes: attributes.length ? attributes : undefined,
              selectionFields: {
                rows: setOrders(
                  (prev.item?.selectionFields?.rows || []).filter((item) => item.mdAttributeId !== attributeId),
                ),
                columns: setOrders(
                  (prev.item?.selectionFields?.columns || []).filter((item) => item.mdAttributeId !== attributeId),
                ),
              },
            }),
          }
        }),
      addKF: (kf) =>
        set((prev) => ({
          item: {
            ...prev.item,
            keyFigures: {
              ...prev.item?.keyFigures,
              [getTypeKf(prev.item?.isShortTerm)]: setOrders([
                ...(prev.item?.keyFigures?.[getTypeKf(prev.item?.isShortTerm)] || []),
                { ...kf, order: 0 },
              ]),
            },
          },
        })),
      removeKF: (kfId) =>
        set((prev) => {
          const keyFigures = setOrders(
            (prev.item?.keyFigures?.[getTypeKf(prev.item?.isShortTerm)] || []).filter((item) => item.id !== kfId),
          )
          return {
            item: deleteUndefined({
              ...prev.item,
              keyFigures: deleteUndefined({
                ...prev.item?.keyFigures,
                [getTypeKf(prev.item?.isShortTerm)]: keyFigures.length ? keyFigures : undefined,
              }),
            }),
          }
        }),
      setOrderKF: (order) =>
        set((prev) => {
          const keyFigures = [...(prev.item?.keyFigures?.[getTypeKf(prev.item?.isShortTerm)] || [])]
          keyFigures.sort((a, b) => {
            const indexA = order.indexOf(a.id)
            const indexB = order.indexOf(b.id)
            return indexA - indexB
          })

          return {
            item: {
              ...prev.item,
              keyFigures: {
                ...prev.item?.keyFigures,
                [getTypeKf(prev.item?.isShortTerm)]: setOrders(keyFigures),
              },
            },
          }
        }),
      setOrderFieldsRows: (order) =>
        set((prev) => ({
          item: {
            ...prev.item,
            selectionFields: {
              rows: setOrders(order),
              columns: prev.item?.selectionFields?.columns || [],
            },
          },
        })),
      setOrderFieldsColumns: (order) =>
        set((prev) => ({
          item: {
            ...prev.item,
            selectionFields: {
              rows: prev.item?.selectionFields?.rows || [],
              columns: setOrders(order),
            },
          },
        })),
      setFieldActive: (key: 'rows' | 'columns', index: number, active: boolean) =>
        set((prev) => ({
          item: {
            ...prev.item,
            selectionFields: {
              ...(prev.item?.selectionFields || { rows: [], columns: [] }),
              [key]: (prev.item?.selectionFields?.[key] || []).map((item, i) => ({
                ...item,
                active: i === index ? active : item.active,
              })),
            },
          },
        })),
      moveFieldsToRows: () =>
        set((prev) => {
          const activeItems = prev.item?.selectionFields?.columns.filter((item) => item.active) || []
          return {
            item: {
              ...prev.item,
              selectionFields: {
                rows: [
                  ...(prev.item?.selectionFields?.rows || []),
                  ...activeItems.map((item) => ({ ...item, active: false })),
                ],
                columns: (prev.item?.selectionFields?.columns || []).filter((item) => !item.active),
              },
            },
          }
        }),
      moveFieldsToColumns: () =>
        set((prev) => {
          const activeItems = prev.item?.selectionFields?.rows.filter((item) => item.active) || []
          return {
            item: {
              ...prev.item,
              selectionFields: {
                rows: (prev.item?.selectionFields?.rows || []).filter((item) => !item.active),
                columns: [
                  ...(prev.item?.selectionFields?.columns || []),
                  ...activeItems.map((item) => ({ ...item, active: false })),
                ],
              },
            },
          }
        }),
      setAttributeFilter: (attributeFilter) =>
        set((prev) => ({
          item: {
            ...prev.item,
            attributeFilter,
          },
        })),
      setValueBasedFilter: (valueBasedFilter) =>
        set((prev) => ({
          item: {
            ...prev.item,
            valueBasedFilter,
          },
        })),
      setFormattingRules: (formattingRules) =>
        set((prev) => ({
          item: {
            ...prev.item,
            formattingRules,
          },
        })),
      setFormattingRulesActive: (ids) =>
        set((prev) => ({
          item: {
            ...prev.item,
            formattingRules: prev.item?.formattingRules?.map((item) => ({
              ...item,
              isActive: ids.includes(item.id),
            })),
          },
        })),
      setFormattingRuleValue: (ruleId, value) =>
        set((prev) => ({
          item: {
            ...prev.item,
            formattingRules: updateById(prev.item?.formattingRules, ruleId, (item) => ({ ...item, value })),
          },
        })),
      setUom: (uom) =>
        set((prev) => ({
          item: {
            ...prev.item,
            uom,
          },
        })),
      setName: (name) =>
        set((prev) => ({
          item: {
            ...prev.item,
            name,
          },
        })),
      setIsPublic: (isPublic) =>
        set((prev) => ({
          item: {
            ...prev.item,
            isPublic,
          },
        })),
      setIsFavorite: (isFavorite) =>
        set((prev) => ({
          item: {
            ...prev.item,
            isFavorite,
          },
        })),
      setIsShortTerm: (isShortTerm) =>
        set((prev) => ({
          item: {
            ...prev.item,
            isShortTerm,
          },
        })),
    }),
    {
      store: 'currentSelection',
    },
  ),
)
