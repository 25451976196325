import { Pagination } from 'interfaces/api.interfaces'

export interface RealignmentItem {
  id: number
  productSourceId: number | null
  productTargetId: number | null
  techBillToSourceExternalId: string | null
  techBillToTargetExternalId: string | null
  businessUnitSourceExternalId: string | null
  businessUnitTargetExternalId: string | null
  chainSourceExternalId: string | null
  chainTargetExternalId: string | null
  locationSourceExternalId: string | null
  locationTargetExternalId: null
  rfaSourceExternalId: string | null
  rfaTargetExternalId: string | null
  nfaSourceExternalId: string | null
  nfaTargetExternalId: string | null
  dateFrom: string | null
  dateTo: string | null
  copyFactor: number | null
  deleteSourceFlag: number | null
  status: RealignmentStatus
  job: RealignmentJob | null
  keyFiguresSubset: string[]
  areAllKeyFiguresSelected: boolean
  stepDoneTime: string | null
}

export interface RealignmentStatus {
  id: number
  name: string
  code: string
}

export interface RealignmentJob {
  id: number
  executionEnd: string | null
}

export type RealignmentFilters = Partial<
  Record<RealignmentFields, { include?: (string | number)[]; exclude?: (string | number)[] }>
>

export enum RealignmentFields {
  ProductSourceId = 'productSourceId',
  ProductTargetId = 'productTargetId',
  CustomerSourceExternalId = 'customerSourceExternalId',
  CustomerTargetExternalId = 'customerTargetExternalId',
  TechBillToSourceExternalId = 'techBillToSourceExternalId',
  TechBillToTargetExternalId = 'techBillToTargetExternalId',
  BusinessUnitSourceExternalId = 'businessUnitSourceExternalId',
  BusinessUnitTargetExternalId = 'businessUnitTargetExternalId',
  ChainSourceExternalId = 'chainSourceExternalId',
  ChainTargetExternalId = 'chainTargetExternalId',
  LocationSourceExternalId = 'locationSourceExternalId',
  LocationTargetExternalId = 'locationTargetExternalId',
  RfaSourceExternalId = 'rfaSourceExternalId',
  RfaTargetExternalId = 'rfaTargetExternalId',
  NfaSourceExternalId = 'nfaSourceExternalId',
  NfaTargetExternalId = 'nfaTargetExternalId',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  CopyFactor = 'copyFactor',
  DeleteSourceFlag = 'deleteSourceFlag',
  StatusName = 'statusName',
  KeyFigureName = 'keyFigureName',
  StepDoneTime = 'stepDoneTime',
}

export enum RealignmentTypes {
  Editing = 'editing',
  History = 'history',
}

export interface RealignmentGetParameters {
  filters?: RealignmentFilters
  type: RealignmentTypes
}

export interface RealignmentUpdateParameters {
  update?: RealignmentUpdateItem[]
  delete?: (string | number)[]
}

export interface RealignmentUpdateItem {
  id: number
  statusId?: number
  keyFiguresIds?: number[]
}

export interface RealignmentGetFilter {
  fieldName: RealignmentFields
  search?: string
  type: RealignmentTypes
  pagination?: Pagination
}

export interface RealignmentKF {
  id: number
  name: string
  code: string
}
