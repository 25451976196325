import { devtools } from 'config/devtools'
import { RealignmentFilters } from 'demand/realignment/api'
import { create } from 'zustand'

interface UseRealignmentFilter {
  filters: RealignmentFilters
  setFilterValue: <TKey extends keyof RealignmentFilters>(filter: TKey, value: RealignmentFilters[TKey]) => void
  clearFilters: () => void
}

export const useRealignmentFilter = create<UseRealignmentFilter>()(
  devtools(
    (set) => ({
      filters: {},
      setFilterValue: (filter, value) => {
        set(({ filters }) => {
          filters[filter] = value
          return { filters: { ...filters } }
        })
      },
      clearFilters: () => set({ filters: {} }),
    }),
    {
      store: 'demand.realignment.filter',
    },
  ),
)
