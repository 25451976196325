import { useNavigate, useRouterState, useSearch } from '@tanstack/react-router'

export const useSearchParams = (): [Record<string, any>, (searchParams: object) => void] => {
  const navigate = useNavigate()
  const {
    location: { pathname },
  } = useRouterState()
  const search = useSearch({ strict: false })

  const setSearchParams = (searchParams: object) => {
    navigate({
      to: pathname,
      search: searchParams,
      replace: true,
    })
  }

  return [search, setSearchParams]
}
