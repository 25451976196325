export enum WidgetCategoryTypes {
  Forecast = 'FORECAST_WIDGET_LIST',
}

export enum WidgetTypes {
  Kpi = 'FORECAST_KPI_FULL',
  Main = 'FORECAST_MAIN_CHARACTERISTIC',
  DemandDrivers = 'FORECAST_DEMAND_DRIVERS',
  Recommendations = 'FORECAST_COGNITIVE',
}

export enum DraggableItemSizes {
  Small = 'small',
  Big = 'big',
}

export interface DraggableItem {
  id: number
  order: number
  size?: DraggableItemSizes
}

export enum DragAreaName {
  Forecast = 'forecast',
}
