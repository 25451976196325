import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/jume/data')({
  component: createPage({
    titleKey: 'titles.dataSources',
    lazyImport: {
      fn: () => import('jume/dataSources/components/DataSourcesList'),
      name: 'DataSourcesList',
    },
  }),
})
