import { FC, ReactNode, RefObject, useEffect, useRef } from 'react'

import { useClickAway, useEventListener, usePrevious, useSafeState, useUpdateEffect } from 'ahooks'
import cx from 'clsx'
import { Animation, AnimationTypes } from 'components/template/Animation'
import { Portal } from 'components/template/Portal'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { useIsMountedMs } from 'hooks/useIsMountedMs'
import { useSize } from 'hooks/useSize'
import { useSyncWidth } from 'hooks/useSyncWidth'
import { toArray } from 'packages/helper'
import { renderElement } from 'utils/renderElement'
import { renderTicks } from 'utils/renderTicks'

import classes from './Dropdown.module.scss'
import { Position } from './interfaces'
import { isNotFitLeft, isNotFitRight } from './isNotFit'
import { parseTypeAnimation } from './parseTypeAnimation'

const TOP_INDENT = 10
const BOTTOM_INDENT = 10
const LEFT_INDENT = 25
const RIGHT_INDENT = 25

export interface DropdownProps {
  refWrap?: RefObject<HTMLElement>
  refClickAway?: RefObject<HTMLDivElement> | RefObject<HTMLDivElement>[]
  refDropdown?: RefObject<HTMLDivElement>
  getPopupContainer?: () => HTMLElement | null
  open?: boolean
  setOpen?: (open: boolean) => void
  disableClickAway?: (event: Event) => boolean
  children?: ReactNode
  classNamePortal?: string
  className?: string
  offsetY?: number
  offsetX?: number
  onClickAway?: (event: Event) => void
  maxOffsetTop?: number | undefined
  maxOffsetLeft?: number | undefined
  typeAnimation?: 'scaleTo' | AnimationTypes
  position?: Position
  defaultCenter?: boolean
  defaultTop?: boolean
  hideOnWheel?: boolean
  syncWidths?: boolean
  onChangePosition?: (newPosition: Position) => void
  setIsMounted?: (isMounted: boolean) => void
}

export const Dropdown: FC<DropdownProps> = ({
  refWrap,
  refClickAway,
  refDropdown,
  getPopupContainer,
  open,
  setOpen,
  disableClickAway,
  children,
  classNamePortal,
  className,
  offsetY = 10,
  offsetX = 0,
  onClickAway,
  maxOffsetTop,
  maxOffsetLeft,
  typeAnimation = 'scaleTo',
  position = 'auto',
  defaultCenter,
  defaultTop,
  hideOnWheel = true,
  syncWidths = false,
  onChangePosition,
  setIsMounted,
}) => {
  const [isMounted] = useIsMountedMs(open, setIsMounted)
  const [isReady, setIsReady] = useSafeState(false)
  const [isRenderForPosition, setIsRenderForPosition] = useSafeState(false)
  const [isTop, setIsTop] = useSafeState(false)
  const [positionWindow, setPositionWindow] = useSafeState({ top: 0, left: 0 })
  const refDropdownInternal = useRef<HTMLDivElement>(null)
  const { cbRef: cbRefDropdown } = useCombinedRef<HTMLDivElement>(refDropdown, refDropdownInternal)
  const sizeWindow = useSize(document.body, { enabled: isReady || isRenderForPosition })
  const previousSizeWindow = usePrevious(sizeWindow)

  const getPopupContainerInternal = (): RefObject<HTMLElement> => {
    const container: HTMLElement = document.querySelector('[data-container]') ?? document.body
    if (getPopupContainer) {
      return { current: getPopupContainer() ?? container }
    }
    return { current: container }
  }

  const onVisibleChange = async (newOpen: boolean) => {
    if (!refWrap) {
      return
    }
    setOpen?.(newOpen)
    if (newOpen) {
      const rectWrap = refWrap.current?.getBoundingClientRect()
      const rectContainer = getPopupContainerInternal().current?.getBoundingClientRect()
      if (rectWrap && rectContainer) {
        setIsRenderForPosition(true)
        await renderElement(refDropdownInternal)
        const rect = refDropdownInternal.current?.getBoundingClientRect() || {
          height: 300,
          width: 0,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }
        const height = rect.height
        const width = rect.width
        const topContainer = rectContainer.top > 0 ? rectContainer.top : 0
        const leftContainer = rectContainer.left > 0 ? rectContainer.left : 0
        const offsetTop =
          typeof maxOffsetTop === 'number' && rectWrap.height + offsetY > maxOffsetTop
            ? maxOffsetTop
            : rectWrap.height + offsetY
        const offsetLeft =
          typeof maxOffsetLeft === 'number' && rectWrap.width + offsetX > maxOffsetLeft ? maxOffsetLeft : offsetX
        const top = rectWrap.top - topContainer + offsetTop + TOP_INDENT
        const left = rectWrap.left - leftContainer + offsetLeft + LEFT_INDENT
        const topWindow = rectWrap.top - topContainer - offsetTop - height
        const newIsTop =
          (['top', 'topLeft', 'topRight', ...(defaultTop ? ['auto'] : [])].includes(position) && topWindow > 0) ||
          (window.innerHeight - top < height && top - offsetTop - BOTTOM_INDENT > height)
        const isCenter = ['top', 'bottom', ...(defaultCenter ? ['auto'] : [])].includes(position)
        const isRight =
          ['topRight', 'bottomRight'].includes(position) ||
          isNotFitRight({ windowWidth: window.innerWidth, elementWidth: width, left })
        if (position === 'auto' && onChangePosition) {
          onChangePosition(newIsTop ? 'top' : 'bottom')
        }
        const positionWindowTop = rectWrap.top - rectContainer.top + (newIsTop ? -offsetY : offsetTop)
        let positionWindowLeft =
          rectWrap.left -
          rectContainer.left +
          offsetX +
          (isRight ? rectWrap.width - width : isCenter ? (rectWrap.width - width) / 2 : 0)

        if (isNotFitRight({ windowWidth: window.innerWidth, elementWidth: width, left: positionWindowLeft })) {
          positionWindowLeft = window.innerWidth - width - RIGHT_INDENT
        }
        if (isNotFitLeft({ left: positionWindowLeft, leftIndent: LEFT_INDENT })) {
          positionWindowLeft = LEFT_INDENT
        }

        setPositionWindow({
          top: positionWindowTop,
          left: positionWindowLeft,
        })
        setIsTop(newIsTop)
      }
      setIsRenderForPosition(false)
      await renderTicks(2)
      setIsReady(true)
    }
  }

  useSyncWidth(refWrap, refDropdownInternal, undefined, undefined, syncWidths)

  useEventListener('wheel', () => {
    if (hideOnWheel) {
      onVisibleChange(false)
    }
  })

  useClickAway(
    (event) => {
      if (disableClickAway?.(event)) {
        return
      }
      onVisibleChange(false)
      onClickAway?.(event)
    },
    [refDropdownInternal, ...toArray(refClickAway ?? refWrap)],
    ['mousedown', 'click'],
  )

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (!open) {
      timer = setTimeout(() => {
        setIsTop(false)
      }, 300)
      setIsReady(false)
    } else {
      timer = setTimeout(() => {
        onVisibleChange(true)
      })
    }
    return () => {
      clearTimeout(timer)
    }
  }, [open])

  useUpdateEffect(() => {
    if (!previousSizeWindow) {
      return
    }
    onVisibleChange(false)
  }, [sizeWindow, previousSizeWindow])

  return (
    <Portal container={getPopupContainerInternal()} disableRender={!isMounted && !isRenderForPosition}>
      <div className={cx(classes.portal, classNamePortal)}>
        <div
          className={cx(classes.dropdown, className, classes[position], {
            [classes.isTop]: isTop,
            [classes.isRenderForPosition]: isRenderForPosition,
          })}
          style={positionWindow}
        >
          <Animation
            initVisible={isRenderForPosition}
            key={String(isRenderForPosition)}
            ref={cbRefDropdown}
            type={parseTypeAnimation(typeAnimation, isTop, position)}
          >
            {(isReady || isRenderForPosition) && children}
          </Animation>
        </div>
      </div>
    </Portal>
  )
}
