import { useEffect, useState } from 'react'

import { ApiErrorsCodes } from 'config/api'
import { Streams } from 'constants/streams'
import { useWs } from 'hooks/useWs'
import { isOpti } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { SystemUpdating } from 'modules/systemUpdating/api'
import { checkUpdateStatusInterval } from 'modules/systemUpdating/constants/checkUpdateStatusInterval'
import { gitlabDeployInfo, typeSendMessageDeployInfo } from 'modules/systemUpdating/constants/ws'
import { useUpdatingSystemOptiWS } from 'modules/systemUpdating/hooks'
import { useSystemUpdating } from 'modules/systemUpdating/queries'

export const useUpdatingSystemHandler = () => {
  const [isFutureUpdating, setIsFutureUpdating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const currentStream = useCurrentStream((state) => state.currentStream)

  const { data, refetch, error } = useSystemUpdating()

  const { isUpdatingWS } = useUpdatingSystemOptiWS()

  useWs(gitlabDeployInfo, {
    enabled: !isOpti(currentStream),
    onPublish: {
      [typeSendMessageDeployInfo]: (dataWS: SystemUpdating) => {
        setIsFutureUpdating(dataWS?.status)
        if (dataWS?.status) {
          setTimeout(() => refetch(), checkUpdateStatusInterval)
        }
      },
    },
    stream: Streams.Modules,
    module: 'systemUpdating',
  })

  useEffect(() => {
    if (isUpdatingWS) {
      setIsFutureUpdating(true)
      setTimeout(() => refetch(), checkUpdateStatusInterval)
    }
  }, [isUpdatingWS])

  useEffect(() => {
    if (data) {
      setIsUpdating(false)
      setIsFutureUpdating(data.status)
    }
  }, [data])

  useEffect(() => {
    if (error?.code === ApiErrorsCodes.UnknownError) {
      setIsUpdating(true)
      setIsFutureUpdating(false)
    }
  }, [error])

  return { isFutureUpdating, isUpdating }
}
