import { Frame } from 'ui/Table/interfaces'

export const getBorders = (frame: Frame) => {
  const { startRowIdx, startColumnIdx, endRowIdx, endColumnIdx } = frame
  const minColumn = Math.min(startColumnIdx, endColumnIdx)
  const maxColumn = Math.max(startColumnIdx, endColumnIdx)
  const minRow = Math.min(startRowIdx, endRowIdx)
  const maxRow = Math.max(startRowIdx, endRowIdx)
  const width = maxColumn - minColumn + 1
  const height = maxRow - minRow + 1
  return { minColumn, maxColumn, minRow, maxRow, width, height }
}
