import { FC } from 'react'

import { useTranslate } from '@ayub-begimkulov/i18n'
import FeedbackIcon from 'assets/images/feedback.svg?react'

import classes from './FeedbackButton.module.scss'

interface Props {
  onClick: () => void
}

export const FeedbackButton: FC<Props> = ({ onClick }) => {
  const translate = useTranslate()
  return (
    <div className={classes.button} onClick={onClick}>
      <FeedbackIcon className={classes.icon} /> {translate('feedback.title')}
    </div>
  )
}
