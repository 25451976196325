import { createEndpoint } from 'config/api'
import { OneParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = 'projects'

interface WebSockets {
  scenarios: OneParamEndpointConstructor
}

export const tetrisScenarioWebSocketEndPoint: WebSockets = {
  scenarios: (projectId) => createEndpoint(`${baseUrl}/:projectId/tetris-scenarios`, { projectId }),
}
