import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { UseHierarchyTreeCurrentItems } from './types'

export const useHierarchyTreeCurrentItems = create<UseHierarchyTreeCurrentItems>()(
  devtools(
    persist(
      (set) => ({
        hierarchyTree: {},
        hierarchyTreeArchive: {},
        setTree: (newTree) => set(({ hierarchyTree }) => ({ hierarchyTree: { ...hierarchyTree, ...newTree } })),
        setArchive: (newTree) =>
          set(({ hierarchyTreeArchive }) => ({ hierarchyTreeArchive: { ...hierarchyTreeArchive, ...newTree } })),
        clearTree: () => set({ hierarchyTree: {} }),
        clearArchive: () => set({ hierarchyTreeArchive: {} }),
      }),
      {
        name: 'hierarchyTree',
      },
    ),
    {
      store: 'hierarchyTree',
    },
  ),
)
