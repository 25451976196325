import { getItems } from 'components/common/Draggable/helpers/getItems'
import { DraggableParams } from 'components/common/Draggable/placeholderStrategies'
import { last } from 'lodash'

export const calculateMovingData = (params: DraggableParams) => {
  if (!params.refPositions.current || !params.refList.current) {
    return {
      positions: [],
      items: [],
      listTop: 0,
    }
  }
  const items = getItems(params)
  const listTop = params.refList.current.getBoundingClientRect().top
  const lastIndex = items.length - 1
  const bottom =
    params.refInitialIndex.current !== null &&
    params.refRectItem.current &&
    params.refInitialIndex.current === lastIndex + 1
      ? (last(params.refPositions.current) || 0) + params.refRectItem.current.height
      : items[lastIndex].getBoundingClientRect().bottom - listTop
  const lastItem = last(items)
  const positions = lastItem ? [...params.refPositions.current, bottom] : params.refPositions.current

  return {
    positions,
    items,
    listTop,
  }
}
