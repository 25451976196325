import { createRootRoute, redirect } from '@tanstack/react-router'
import 'assets/scss/app.scss'
import { isAuthenticated } from 'config/auth/isAuthenticated'
import { isLoginPage } from 'config/auth/isLoginPage'
import { requestTokensLoader, ssoLoader, twoFactorLoader } from 'config/router/loaders'
import { RootLayout } from 'layouts/RootLayout'
import { NotFoundBlock } from 'modules/notFound/components/NotFoundBlock'
import { Loader, LoaderTypes } from 'ui/Loader'

export const Route = createRootRoute({
  component: RootLayout,
  beforeLoad: async ({ location }) => {
    await requestTokensLoader()
    twoFactorLoader()
    ssoLoader()
    if (!isAuthenticated() && !isLoginPage(location)) {
      throw redirect({
        to: '/login',
      })
    } else if (isAuthenticated() && isLoginPage(location)) {
      throw redirect({
        to: '/',
      })
    }
  },
  notFoundComponent: NotFoundBlock,
  pendingComponent: () => <Loader type={LoaderTypes.SpinnerCenter} />,
})
