import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'

import { UseOpenEditMode } from './types'

export const useOpenEditMode = create<UseOpenEditMode>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set, get) => ({
          open: false,
          set: (open) => set({ open }),
          toggle: () => set({ open: !get().open }),
          clear: () => set({ open: false }),
        }),
        {
          name: 'openEditMode',
        },
      ),
      {
        store: 'openEditMode',
      },
    ),
  ),
)
