import { tokensBroadcastChannel } from 'config/auth/broadcastChannels'
import { getTokens } from 'config/auth/getTokens'
import { TokenResponse } from 'interfaces/auth.interfaces'

enum MessageEvents {
  TokensRequest = 'tokens_request',
  TokensReceive = 'tokens_receive',
}

export const requestBroadcastTokens = async (): Promise<TokenResponse> =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject()
    }, 2000)

    tokensBroadcastChannel.postMessage({
      type: MessageEvents.TokensRequest,
    })

    tokensBroadcastChannel.onmessage = (event: MessageEvent) => {
      if (event.data.type === MessageEvents.TokensRequest) {
        const tokens = getTokens()
        tokensBroadcastChannel.postMessage({
          type: MessageEvents.TokensReceive,
          tokens,
        })
      }
      if (event.data.type === MessageEvents.TokensReceive) {
        clearTimeout(timer)
        resolve(event.data.tokens)
      }
    }
  })
