import { FC, Fragment } from 'react'

import { NavMenuJobs } from 'modules/jobs/components/NavMenuJobs'
import { useJobsPermissionsByStream } from 'modules/jobs/handlers'

import { getDeployMenu } from './deployMenu'
import { NavMenuDeployItem } from './NavMenuDeployItem'

export const NavMenuDeploy: FC = () => {
  const { canReadJobsDb, canEditJobsDb } = useJobsPermissionsByStream()

  return (
    <>
      {getDeployMenu().map((menuItem) => (
        <Fragment key={menuItem.id}>
          <NavMenuDeployItem menuItem={menuItem} />
        </Fragment>
      ))}
      {(canReadJobsDb || canEditJobsDb) && <NavMenuJobs />}
    </>
  )
}
