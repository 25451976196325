import { useEffect, useState } from 'react'

import { isDemand, isDeploy, isPrgm } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { usePermissions } from 'modules/user/queries'

export const useJobsPermissionsByStream = () => {
  const [canReadJobsDb, setCanReadJobsDb] = useState(false)
  const [canEditJobsDb, setCanEditJobsDb] = useState(false)
  const [canRunJobsDb, setCanRunJobsDb] = useState(false)

  const currentStream = useCurrentStream((state) => state.currentStream)

  const { permissions } = usePermissions()

  useEffect(() => {
    if (isPrgm(currentStream)) {
      setCanReadJobsDb(permissions.readJobsDbPrgm)
      setCanEditJobsDb(permissions.editJobsDbPrgm)
      setCanRunJobsDb(permissions.runJobsPrgm)
    }
    if (isDemand(currentStream)) {
      setCanReadJobsDb(permissions.readJobsDbDemand)
      setCanEditJobsDb(permissions.editJobsDbDemand)
      setCanRunJobsDb(permissions.runJobsDemand)
    }
    if (isDeploy(currentStream)) {
      setCanReadJobsDb(permissions.readJobsDbDeploy)
      setCanEditJobsDb(permissions.editJobsDbDeploy)
      setCanRunJobsDb(permissions.runJobsDeploy)
    }
  }, [permissions, currentStream])

  return { canReadJobsDb, canEditJobsDb, canRunJobsDb }
}
