import { useCallback, useEffect, useState } from 'react'

import { tokensBroadcastChannel } from 'config/auth/broadcastChannels'
import { getTokens } from 'config/auth/getTokens'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { useLogoutClear } from 'hooks/useLogoutClear'

enum MessageEvents {
  TokensRequest = 'tokens_request',
  TokensReceive = 'tokens_receive',
  Logout = 'logout',
}

type MessageEvent = {
  data:
    | {
        type: MessageEvents.TokensRequest
      }
    | {
        type: MessageEvents.TokensReceive
        tokens?: {
          access: string
          refresh: string
        }
      }
    | {
        type: MessageEvents.Logout
      }
}

export const useBroadcastAuth = () => {
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel | null>()
  const { logoutClear } = useLogoutClear()

  const broadcastLogout = useCallback(() => {
    broadcastChannel?.postMessage({
      type: MessageEvents.Logout,
    })
  }, [broadcastChannel])

  useEffect(() => {
    tokensBroadcastChannel.onmessage = (event: MessageEvent) => {
      if (event.data.type === MessageEvents.TokensRequest) {
        const tokens = getTokens()
        tokensBroadcastChannel.postMessage({
          type: MessageEvents.TokensReceive,
          tokens,
        })
      }
    }

    const newBroadcastChannel = new BroadcastChannel('auth')
    setBroadcastChannel(newBroadcastChannel)
    let timer: NodeJS.Timeout | undefined

    const token = storage.getToken()
    if (!token) {
      setLoading(true)
      newBroadcastChannel.postMessage({
        type: MessageEvents.TokensRequest,
      })
      timer = setTimeout(() => {
        setLoading(false)
        setReady(true)
      }, 2000)
    } else {
      setReady(true)
    }

    newBroadcastChannel.onmessage = (event: MessageEvent) => {
      switch (event.data.type) {
        case MessageEvents.TokensRequest:
          const tokens = getTokens()
          if (tokens) {
            newBroadcastChannel.postMessage({
              type: MessageEvents.TokensReceive,
              tokens,
            })
          }
          break
        case MessageEvents.TokensReceive:
          clearTimeout(timer)
          if (event.data.tokens) {
            storage.setToken(event.data.tokens.access, false)
            storage.setRefresh(event.data.tokens.refresh, false)
            setAxiosAuthorizationToken(event.data.tokens.access)
            setLoading(false)
            setReady(true)
          }
          break

        case MessageEvents.Logout:
          if (document.visibilityState === 'visible') {
            return
          }
          logoutClear()
          break
      }
    }
    return () => {
      if (!broadcastChannel) {
        return
      }
      broadcastChannel.onmessage = null
    }
  }, [])

  return {
    loading,
    ready,
    broadcastLogout,
  }
}
