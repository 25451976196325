import { FC, MouseEventHandler, TouchEventHandler } from 'react'

import { ColumnResizeMode, Table } from '@tanstack/react-table'
import cx from 'clsx'
import classes from 'ui/Table/Table.module.scss'

interface ResizerProps {
  columnResizable?: boolean
  isResizing?: boolean
  onMouseDown?: MouseEventHandler<HTMLDivElement>
  onMouseUp?: MouseEventHandler<HTMLDivElement>
  onTouchStart?: TouchEventHandler<HTMLDivElement>
  columnResizeMode?: ColumnResizeMode
  table: Table<any>
  previousResizer?: {
    isResizing?: boolean
    onMouseDown?: MouseEventHandler<HTMLDivElement>
    onTouchStart?: TouchEventHandler<HTMLDivElement>
    onMouseUp?: MouseEventHandler<HTMLDivElement>
  }
  isLast?: boolean
  widthFullAlways?: boolean
}

export const Resizer: FC<ResizerProps> = ({
  columnResizable,
  isResizing,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  columnResizeMode,
  table,
  previousResizer,
  isLast,
  widthFullAlways,
}) => {
  if (!columnResizable) {
    return
  }

  return (
    <>
      {previousResizer && (
        <div
          className={cx(classes.resizer, classes.previousResizer, {
            [classes.isResizing]: previousResizer.isResizing,
          })}
          onMouseDown={previousResizer.onMouseDown}
          onMouseUp={previousResizer.onMouseUp}
          onTouchStart={previousResizer.onTouchStart}
          style={{
            transform:
              columnResizeMode === 'onEnd' && previousResizer.isResizing
                ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                : '',
          }}
        />
      )}
      {!(isLast && widthFullAlways) && (
        <div
          className={cx(classes.resizer, {
            [classes.isResizing]: isResizing,
          })}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onTouchStart={onTouchStart}
          style={{
            transform:
              columnResizeMode === 'onEnd' && isResizing
                ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                : '',
          }}
        />
      )}
    </>
  )
}
