import { axios } from 'config/axios'
import { Streams } from 'constants/streams'
import { GetOneResponse } from 'interfaces/api.interfaces'
import { TokenResponse } from 'interfaces/auth.interfaces'

import { authEndpoints } from './endpoints'
import {
  AuthTokens,
  ForgotData,
  LoginData,
  LogoutInformParams,
  ResendTwoFactorCodeData,
  TwoFactorLoginData,
} from './interfaces'

const login = async (form: LoginData): GetOneResponse<TokenResponse> => {
  const { data } = await axios.request<TokenResponse>({
    method: 'POST',
    ...authEndpoints.login(),
    data: {
      email: form.email.toLowerCase(),
      password: form.password,
    },
    tracing: {
      stream: Streams.Modules,
      module: 'auth',
      methodName: 'login',
    },
  })
  return data
}

const resetPassword = async (form: ForgotData): Promise<void> => {
  await axios.request({
    method: 'POST',
    ...authEndpoints.resetPassword(),
    data: {
      email: form.email.toLowerCase(),
    },
    tracing: {
      stream: Streams.Modules,
      module: 'auth',
      methodName: 'resetPassword',
    },
  })
}

const logoutInform = async (params: LogoutInformParams): Promise<void> => {
  await axios.request({
    method: 'POST',
    data: params,
    ...authEndpoints.logoutInform(),
    tracing: {
      stream: Streams.Modules,
      module: 'auth',
      methodName: 'logoutInform',
    },
  })
}

const resendTwoFactorCode = async (form: ResendTwoFactorCodeData): Promise<void> => {
  await axios.request({
    method: 'POST',
    ...authEndpoints.resendTwoFactorCode(),
    data: {
      email: form.email.toLowerCase(),
    },
    tracing: {
      stream: Streams.Modules,
      module: 'auth',
      methodName: 'resendTwoFactorCode',
    },
  })
}

const twoFactorLogin = async (form: TwoFactorLoginData): Promise<TokenResponse> => {
  const { data } = await axios.request({
    method: 'POST',
    ...authEndpoints.twoFactorLogin(),
    data: {
      email: form.email.toLowerCase(),
      code: form.code,
    },
    tracing: {
      stream: Streams.Modules,
      module: 'auth',
      methodName: 'twoFactorLogin',
    },
  })
  return data
}

const checkTwoFactorCode = async (): Promise<void> => {
  const { data } = await axios.request({
    method: 'GET',
    ...authEndpoints.checkTwoFactorCode(),
    tracing: {
      stream: Streams.Modules,
      module: 'auth',
      methodName: 'checkTwoFactorCode',
    },
  })
  return data
}

const getAuthSsoTokens = async (code: string): Promise<AuthTokens> => {
  const { data } = await axios.request<AuthTokens>({
    method: 'POST',
    ...authEndpoints.getAuthSsoTokens(),
    data: {
      code,
    },
    tracing: {
      stream: Streams.Modules,
      module: 'auth',
      methodName: 'getAuthSsoTokens',
    },
  })
  return data
}

export const authApi = {
  login,
  resetPassword,
  logoutInform,
  resendTwoFactorCode,
  twoFactorLogin,
  checkTwoFactorCode,
  getAuthSsoTokens,
}
