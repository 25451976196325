import { i18n } from 'config/i18n'
import { WidgetTypes } from 'jume/editMode/interfaces'

export const parseWidgetTitle = (type?: WidgetTypes): string => {
  if (type === WidgetTypes.Kpi) {
    return i18n.get('editMode.kpi')
  }
  if (type === WidgetTypes.Recommendations) {
    return i18n.get('editMode.recommendations')
  }
  if (type === WidgetTypes.Main) {
    return i18n.get('editMode.mainCharacteristics')
  }
  if (type === WidgetTypes.DemandDrivers) {
    return i18n.get('editMode.demandDrivers')
  }
  return ''
}
