import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/tetris-optimizer/tetris-scenario/$scenarioId')({
  component: createPage({
    lazyImport: {
      fn: () => import('opti/tetrisScenario/components/TetrisScenarioBlock'),
      name: 'TetrisScenarioBlock',
    },
  }),
})
