import { ChangeEvent, forwardRef, MouseEvent, ReactNode, useEffect, useState } from 'react'

import CheckIcon from 'assets/images/check.svg?react'
import cx from 'clsx'
import { Loader, LoaderColors, LoaderTypes } from 'ui/Loader'

import classes from './Checkbox.module.scss'

export enum CheckboxSizes {
  Medium = 'medium',
  Small = 'small',
}

export enum CheckboxColors {
  Primary = 'primary',
  Default = 'default',
}

interface CheckboxProps {
  children?: ReactNode
  className?: string
  classNameCheckbox?: string
  classNameLabel?: string
  disabled?: boolean
  noEditable?: boolean
  value?: boolean
  onChange?: (value: boolean, event: MouseEvent | ChangeEvent) => void
  size?: CheckboxSizes
  loading?: boolean
  isPartially?: boolean
  color?: CheckboxColors
  animated?: boolean
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      classNameCheckbox,
      classNameLabel,
      value,
      disabled,
      noEditable,
      onChange,
      children,
      size = CheckboxSizes.Medium,
      loading,
      isPartially,
      color = CheckboxColors.Default,
      animated,
    },
    ref,
  ) => {
    const [valueInternal, setValueInternal] = useState(!!value)
    const [clicked, setClicked] = useState(false)
    const noLabel = !children && children !== 0
    const valueCombine = value === undefined ? valueInternal : value

    const onChangeInternal = (event: MouseEvent | ChangeEvent) => {
      setClicked(true)
      if (value !== undefined) {
        onChange?.(!value, event)
      } else {
        setValueInternal((prev) => {
          requestAnimationFrame(() => onChange?.(!prev, event))
          return !prev
        })
      }
    }

    useEffect(() => {
      setValueInternal(!!value)
    }, [value])

    return (
      <div
        className={cx(classes.wrap, className, classes[size], classes[color], {
          [classes.checked]: valueCombine,
          [classes.clicked]: clicked,
          [classes.disabled]: disabled,
          [classes.noStretch]: noLabel,
          [classes.partially]: isPartially,
          [classes.noEditable]: noEditable,
          [classes.animated]: animated,
        })}
        onClick={onChangeInternal}
      >
        <input checked={valueCombine} className={classes.input} onChange={onChangeInternal} ref={ref} type="checkbox" />
        <div className={classes.checkboxCont}>
          {loading ? (
            <Loader color={LoaderColors.Gray} type={LoaderTypes.SpinnerMini} />
          ) : (
            <div className={cx(classes.checkbox, classNameCheckbox)}>
              <CheckIcon className={classes.icon} />
            </div>
          )}
        </div>
        {!noLabel && <div className={cx(classes.label, classNameLabel)}>{children}</div>}
      </div>
    )
  },
)
