import { useTranslate } from '@ayub-begimkulov/i18n'
import { useMutation } from '@tanstack/react-query'
import { feedbackApi } from 'modules/feedback/api'
import { Feedback, FeedbackForm } from 'modules/feedback/api/interfaces'
import { useCurrentStream } from 'modules/streams/store'
import { useProfile } from 'modules/user/queries'
import { getError } from 'packages/react-query'

export const useSendFeedback = () => {
  const { profile } = useProfile()
  const translate = useTranslate()
  const currentStream = useCurrentStream((state) => state.currentStream)

  const { mutate, isPending, reset, error, isSuccess } = useMutation({
    mutationFn: (data: Feedback) => feedbackApi.sendFeedback(data),
    meta: {
      error: {
        message: translate('feedback.sendError'),
        showToast: false,
      },
    },
  })

  const sendFeedback = (data: FeedbackForm) => {
    const feedback: Feedback = {
      ...data,
      url: window.location.href,
      user: profile?.id ?? 0,
      name: currentStream?.id ?? 0,
    }
    mutate(feedback)
  }

  return {
    sendFeedback,
    resetError: () => {
      reset()
    },
    isPending,
    error: getError(error),
    isSuccess,
  }
}
