import { Path } from 'interfaces/router.interfaces'

export const forecastRoutes: Path[] = [
  '/jume',
  '/jume/project/$projectId/forecast',
  '/jume/status',
  '/jume/innovation-management',
  '/jume/master-data',
  '/jume/master-data/$hierarchyId',
  '/jume/data',
  '/jume/promo-optimizer-demo',
  '/jume/approvals',
]

export const replenOptimizerRoutes: Path[] = [
  '/opti/replen-optimizer',
  '/opti/create-replen-scenario',
  '/opti/replen-optimizer/replen-scenarios/$scenarioId',
  '/opti/replen-optimizer/replen-scenarios/not-found',
  '/opti/replen-optimizer/replen-warehouses',
  '/opti/replen-optimizer/replen-scenarios',
  '/opti/replen-optimizer/replen-overview',
]

export const promoOptimizerRoutes: Path[] = [
  '/opti/promo-optimizer',
  '/opti/create-promo-scenario',
  '/opti/promo-optimizer/promo-scenario/$scenarioId',
  '/opti/promo-optimizer/promo-scenario/not-found',
]

export const rtmOptimizerRoutes: Path[] = [
  '/opti/create-rtm-scenario',
  '/opti/rtm-optimizer/rtm-scenario/$scenarioId',
  '/opti/rtm-optimizer',
  '/opti/rtm-optimizer/rtm-scenario/not-found',
]

export const tetrisOptimizerRoutes: Path[] = [
  '/opti/create-tetris-scenario',
  '/opti/tetris-optimizer/tetris-scenario/$scenarioId',
  '/opti/tetris-optimizer',
  '/opti/tetris-optimizer/tetris-scenario/not-found',
]

export const cfrOptimizerRoutes: Path[] = [
  '/opti/create-cfr-scenario',
  '/opti/cfr-optimizer/cfr-scenario/$scenarioId',
  '/opti/cfr-optimizer',
  '/opti/cfr-optimizer/cfr-scenario/not-found',
]

export const jobViewRoute = '/jobs/$jobId/view'
export const deployJobViewRoute = '/deploy/jobs/$jobId/view'
export const prgmJobViewRoute = '/prgm/jobs/$jobId/view'

export const jobViewRoutes = [jobViewRoute, deployJobViewRoute, prgmJobViewRoute]

export const jobsRoutes: Path[] = [
  '/jobs',
  '/jobs/new',
  '/jobs-status',
  '/jobs-detail/$jobId',
  '/jobs/$jobId/edit',
  jobViewRoute,
]

export const deployJobsRoutes: Path[] = [
  '/deploy/jobs',
  '/deploy/jobs/new',
  '/deploy/jobs-status',
  '/deploy/jobs-detail/$jobId',
  '/deploy/jobs/$jobId/edit',
  deployJobViewRoute,
]

export const prgmJobsRoutes: Path[] = [
  '/prgm/jobs',
  '/prgm/jobs/new',
  '/prgm/jobs-status',
  '/prgm/jobs-detail/$jobId',
  '/prgm/jobs/$jobId/edit',
  prgmJobViewRoute,
]

export const jumeRoutes: Path[] = [...forecastRoutes, ...replenOptimizerRoutes, ...jobsRoutes]

export const optiRoutes: Path[] = [
  ...promoOptimizerRoutes,
  ...rtmOptimizerRoutes,
  ...tetrisOptimizerRoutes,
  ...cfrOptimizerRoutes,
  ...jobsRoutes,
]

export const demandRoutes: Path[] = [
  '/demand/selections',
  '/demand/selections/$selectionId',
  '/demand/selections/new',
  '/demand/selections/$selectionId/forecast',
  '/demand/master-data-upload',
  '/demand/forecast-data-upload',
  '/demand/data-handling',
  '/demand/realignment',
  ...jobsRoutes,
]

export const prgmRoutes: Path[] = [
  '/prgm/create',
  '/prgm/search',
  '/prgm/pulse',
  '/prgm/approve',
  '/prgm/data-hub',
  '/prgm/create/$promoId',
  '/prgm/reports',
  ...prgmJobsRoutes,
]

export const deployRoutes: Path[] = [
  '/deploy/data-hub',
  '/deploy/data-hub/$cardType',
  '/deploy/scenario-data-hub',
  '/deploy/scenario-data-hub/$scenarioId',
  '/deploy/scenario-data-hub/$scenarioId/$cardType',
  '/deploy/scenario-settings',
  '/deploy/scenario-settings/$scenarioId',
  '/deploy/scenario-settings/create',
  '/deploy/scenarios-history',
  '/deploy/stock-allocation',
  '/deploy/stock-allocation/$scenarioId',
  '/deploy/transport-optimization',
  '/deploy/transport-optimization/$scenarioId',
  '/deploy/pped',
  '/deploy/executive',
  ...deployJobsRoutes,
]

export const ppdsRoutes: Path[] = ['/ppds/materials', '/ppds/resource-families', '/ppds/materials-family']

export const authRoutes: Path[] = ['/login', '/reset-password']
