import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/replen-optimizer/replen-scenarios/$scenarioId')({
  component: createPage({
    titleKey: 'titles.replenScenario',
    lazyImport: {
      fn: () => import('jume/replenScenarios/components/ReplenScenarioBlock'),
      name: 'ReplenScenarioBlock',
    },
  }),
})
