import {
  ClipboardEvent,
  CSSProperties,
  FormEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  RefObject,
} from 'react'

import { WarningColors } from 'interfaces/common.interfaces'

export type ButtonHTMLTypes = 'submit' | 'reset' | 'button' | undefined
export type DateFormat = Date | number | string | null

export interface InputProps {
  className?: string
  classNameAfter?: string
  classNameContainer?: string
  classNameFocus?: string
  classNameTooltip?: string
  style?: CSSProperties
  name?: string
  value?: string | number | null
  initialValue?: string | number | null
  onChange?: (value: string) => void
  onFocus?: (event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void
  onCtrlEnter?: (event: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void
  onPaste?: (
    event: ClipboardEvent<HTMLInputElement> | ClipboardEvent<HTMLTextAreaElement>,
    setValue: (value: string) => void,
  ) => void
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  blurOnArrowClick?: boolean
  blurOnEnter?: boolean
  autoComplete?: 'on' | 'off'
  placeholder?: string
  mask?: string
  htmlType?: HTMLInputTypeAttribute
  size?: TextFieldSizes
  color?: TextFieldColors
  disabled?: boolean
  loading?: boolean
  maxLength?: number
  multiline?: boolean
  autoResize?: boolean
  autoFocus?: boolean
  maxRows?: number
  noWrap?: boolean
  afterContent?: ReactNode
  onClickAfter?: () => void
  afterIsClickable?: boolean
  isCell?: boolean
  isError?: boolean
  noEditable?: boolean
  isChanged?: boolean
  isCleanable?: boolean
  showCleanOnHover?: boolean
  showSearchIcon?: boolean
  tooltip?: ReactNode
  showErrorIcon?: boolean
  showWarningIcon?: boolean
  warningColor?: WarningColors
  positiveNumber?: boolean
  integerNumber?: boolean
  spellCheck?: boolean
  noRemoveLeadingZero?: boolean
  disableAutoScrollInChrome?: boolean
  refWrap?: RefObject<HTMLDivElement>
  maxNumber?: number
  toLocalString?: boolean
}

export enum TextFieldColors {
  Default = 'default',
  Primary = 'primary',
  Danger = 'danger',
}

export enum TextFieldSizes {
  Medium = 'medium',
  Small = 'small',
}

export interface TabObject {
  id?: number
  title: ReactNode
  active?: boolean
  code?: string
  disabled?: boolean
  error?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
}

export interface OptionItem<Meta = unknown> {
  label: string
  value?: string | number | null
  meta?: Meta
}

export interface OptionTreeItem<Meta = unknown> {
  label: ReactNode
  value?: string | number | null
  key?: string | number
  items?: OptionTreeItem[]
  code?: string
  childrenCount?: number
  getNextPage?: () => void
  hasNextPage?: boolean
  meta?: Meta
  parentValue?: string | number | null
  hierarchyId?: number
  parentIds?: number[]
  highlighted?: boolean
  underlined?: boolean
  className?: string
  truncate?: boolean
  truncateLines?: number
  truncateDisabledTooltip?: boolean
  description?: ReactNode
  isBold?: boolean
  disabled?: boolean
  labelInList?: ReactNode
}

export interface FilterItem {
  label: string
  name: string
  options?: OptionTreeItem[]
  isTree?: boolean
  multiple?: boolean
  showSearch?: boolean
  defaultValue?: string | number | (string | number)[]
  defaultIsReset?: boolean
  id?: number
}

export enum DataSourceUploadTypes {
  ManualFile = 'manualFile',
  Scenario = 'scenario',
}

export enum DataSourceUploadExtendedTypes {
  ManualFile = 'manualFile',
  Scenario = 'scenario',
  NotDownload = 'notDownload',
}

export enum DataSourceUploadingStatuses {
  DataSuccessfullyUploaded = 'Data successfully uploaded',
  SeeLogFileForDetails = 'File format error. See log file for details',
}

export interface TablePagination {
  current: number
  total: number
  pageSize?: number
  onChange?: (current: number) => void
}
