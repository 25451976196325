import { devtools } from 'config/devtools'
import { create } from 'zustand'

interface UseAuth {
  isLogoutConfirm: boolean
  isLogout: boolean
  setIsLogout: (isLogout: boolean) => void
  setIsLogoutConfirm: (isLogoutConfirm: boolean) => void
}

export const useAuth = create<UseAuth>()(
  devtools(
    (set) => ({
      isLogoutConfirm: false,
      isLogout: false,
      setIsLogout: (isLogout) => set({ isLogout }),
      setIsLogoutConfirm: (isLogoutConfirm) => set({ isLogoutConfirm }),
    }),
    { store: 'authentication' },
  ),
)
