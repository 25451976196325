import { FC, useRef } from 'react'
import { useDrop } from 'react-dnd'

import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { EditModeWidgets } from 'jume/editMode/components/EditModeWidgets'
import { DragAreaName } from 'jume/editMode/interfaces'
import { Block } from 'ui/Block'

import classes from './EditModeContent.module.scss'

interface EditModeContentProps {
  className?: string
}

export const EditModeContent: FC<EditModeContentProps> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null)
  const translate = useTranslate()

  const [{}, drop] = useDrop(() => ({
    accept: DragAreaName.Forecast,
    hover: (_, monitor) => {
      const top = monitor.getClientOffset()?.y
      if (!ref.current || !top) {
        return
      }

      if (top < 100 && !!ref.current.scrollTop) {
        ref.current?.scrollBy(0, -10)
      }

      if (window.innerHeight - top < 100 && window.innerHeight - top > 0) {
        ref.current.scrollBy(0, 10)
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }))

  return (
    <div className={cx(classes.wrap, className)} ref={ref}>
      <Block className={classes.filters}>{translate('editMode.filters')}</Block>
      <div ref={drop}>
        <EditModeWidgets />
      </div>
    </div>
  )
}
