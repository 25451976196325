import { FC } from 'react'

import { useTranslate } from 'config/i18n'
import { ButtonEasy, ButtonEasyIcons } from 'ui/ButtonEasy'

interface PropsType {
  onClick: VoidFunction
}

export const UnavailableBlock: FC<PropsType> = ({ onClick }) => {
  const translate = useTranslate()

  return (
    <ButtonEasy icon={ButtonEasyIcons.Next} label={translate('return')} onClick={onClick}>
      {translate('unavailable')}
    </ButtonEasy>
  )
}
