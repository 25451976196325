import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseForecastLoading } from './types'

export const useForecastLoading = create<UseForecastLoading>()(
  devtools(
    (set) => ({
      forecastLoading: false,
      setForecastLoading: (forecastLoading) => set({ forecastLoading }),
    }),
    {
      store: 'forecastLoading',
    },
  ),
)
