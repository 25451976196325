import { FC } from 'react'

import classes from './EditModeRecWidget.module.scss'

interface EditModeRecWidgetProps {
  className?: string
}

export const EditModeRecWidget: FC<EditModeRecWidgetProps> = ({ className }) => (
  <div className={className}>
    <div className={classes.lineTitle} />
    <div className={classes.lines}>
      {[...Array(12)].map((_, index) => (
        <div className={classes.line} key={index} />
      ))}
    </div>
  </div>
)
