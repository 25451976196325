import { devtools } from 'config/devtools'
import { PromoTableRow, PromoUpdateElementRow } from 'jume/promoTable/api'
import { HierarchyExistElement } from 'jume/statusDashboard/api'
import { isEqual } from 'lodash'
import { deleteEmpty, deleteNullableInArray } from 'packages/helper'
import { removeByIndex } from 'packages/helper/src/removeByIndex'
import { uid } from 'uid'
import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'

import { UseUpdatePromoTable } from './types'

const filterFn = (item: PromoUpdateElementRow, initialRow?: PromoTableRow) =>
  !isEqual(
    {
      id: item.id,
      isArchived: item.isArchived ?? initialRow?.isArchived,
      statusId: item.statusId ?? initialRow?.manualStatus?.id,
      realElement: item.realElement ?? initialRow?.realElement,
      startDate: item.startDate ?? initialRow?.startDate,
      endDate: item.endDate ?? initialRow?.endDate,
      hierarchy: item.hierarchy
        ? item.hierarchy.map((level) => (level as HierarchyExistElement).elementId ?? null)
        : initialRow?.hierarchy?.map((level) => level.element.id),
    },
    {
      id: initialRow?.id,
      isArchived: initialRow?.isArchived,
      statusId: initialRow?.manualStatus?.id,
      realElement: initialRow?.realElement,
      startDate: initialRow?.startDate,
      endDate: initialRow?.endDate,
      hierarchy: initialRow?.hierarchy?.map((level) => level.element.id),
    },
  )

const filterEquals = (updated: UseUpdatePromoTable['updated'], initialRow?: PromoTableRow) =>
  initialRow ? updated.filter((item) => filterFn(item, initialRow)) : updated

const filterEqualsArray = (updated: UseUpdatePromoTable['updated'], initialRows?: PromoTableRow[]) =>
  initialRows?.length
    ? updated.filter((item) => {
        const initialRow = initialRows.find((row) => item.id === row.id)
        return filterFn(item, initialRow)
      })
    : updated

export const useUpdatePromoTable = create<UseUpdatePromoTable>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set) => ({
          updated: [],
          created: [],
          lastSavedErrorData: {
            updated: [],
            created: [],
          },
          createRow: () =>
            set(({ created }) => ({
              created: [
                ...created,
                {
                  idCreated: uid(),
                  dummyCode: '',
                  dummyTitle: '',
                  startDate: '',
                  endDate: '',
                  hierarchy: [
                    {
                      elementTitle: '',
                    },
                  ],
                },
              ],
            })),
          setLastSavedErrorData: (lastSavedErrorData) => set(() => ({ lastSavedErrorData })),
          updateName: (name, id, type) => {
            if (type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        dummyTitle: name,
                      }
                    : item,
                ),
              }))
            }
          },
          updateCode: (code, id, type) => {
            if (type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        dummyCode: code,
                      }
                    : item,
                ),
              }))
            }
          },
          updateHierarchy: (hierarchy, id, type) => {
            if (!hierarchy.length && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].hierarchy
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.hierarchy = hierarchy
                } else {
                  updated.push({ id: id as number, hierarchy })
                }
                return { updated }
              })
            }
            if (type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        hierarchy,
                      }
                    : item,
                ),
              }))
            }
          },
          updateStartDate: (date, id, type) => {
            if (!date && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].startDate
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (!date && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  created[rowIndex].startDate = ''
                  if (!Object.keys(deleteEmpty({ ...created[rowIndex], id: null, idCreated: null })).length) {
                    created.splice(rowIndex, 1)
                  }
                }
                return { created }
              })
              return
            }
            if (date && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.startDate = date
                } else {
                  updated.push({ id: id as number, startDate: date })
                }
                return { updated }
              })
            }
            if (date && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        startDate: date,
                      }
                    : item,
                ),
              }))
            }
          },
          updateEndDate: (date, id, type) => {
            if (!date && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].endDate
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (!date && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  created[rowIndex].endDate = ''
                  if (!Object.keys(deleteEmpty({ ...created[rowIndex], id: null, idCreated: null })).length) {
                    created.splice(rowIndex, 1)
                  }
                }
                return { created }
              })
              return
            }
            if (date && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.endDate = date
                } else {
                  updated.push({ id: id as number, endDate: date })
                }
                return { updated }
              })
            }
            if (date && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        endDate: date,
                      }
                    : item,
                ),
              }))
            }
          },
          updateStatus: (statusId, id, type) => {
            if (!statusId && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].statusId
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (statusId && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.statusId = statusId
                } else {
                  updated.push({ id: id as number, statusId })
                }
                return { updated }
              })
            }
          },
          updateStatuses: (statusId, rows) => {
            const updatedRows = rows.filter((item) => item.type === 'updated').map((item) => item.id)
            if (!statusId) {
              set(({ updated }) => ({
                updated: deleteNullableInArray(
                  updated.map((item) => {
                    if (updatedRows.includes(item.id)) {
                      delete item.statusId
                      if (!Object.keys(deleteEmpty({ ...item, id: null })).length) {
                        return null
                      }
                    }
                    return item
                  }),
                ),
              }))
            } else {
              set(({ updated }) => {
                const newUpdatedIds = updatedRows.filter(
                  (updatedId) => !updated.map((item) => item.id).includes(updatedId as number),
                )
                return {
                  updated: [
                    ...updated.map((item) => {
                      if (updatedRows.includes(item.id)) {
                        item.statusId = statusId
                      }
                      return item
                    }),
                    ...newUpdatedIds.map((updatedId) => ({ id: updatedId as number, statusId })),
                  ],
                }
              })
            }
          },
          updateRealElement: (realElement, id, type) => {
            if (!realElement && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].realElement
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (!realElement && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  delete created[rowIndex].realElement
                }
                return { created }
              })
              return
            }
            if (realElement && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.realElement = realElement
                } else {
                  updated.push({ id: id as number, realElement })
                }
                return { updated }
              })
            }
            if (realElement && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        realElement,
                      }
                    : item,
                ),
              }))
            }
          },
          removeNew: (index) => set(({ created }) => ({ created: removeByIndex(created, index) })),
          clearArchive: () =>
            set(({ updated }) => ({
              updated: updated
                .map((item) => {
                  delete item.isArchived
                  return item
                })
                .filter((item) => Object.keys(item).filter((key) => key !== 'id').length),
            })),
          clearReturnedFromArchive: () =>
            set(({ updated }) => ({ updated: updated.filter((item) => item.isArchived) })),
          clear: () => set({ updated: [], created: [], lastSavedErrorData: { updated: [], created: [] } }),
          setArchive: (id, isArchived, initialRow, isAdmin?: boolean) =>
            set(({ updated }) => {
              const row = updated.find((item) => item.id === id)
              if (isAdmin) {
                if (row) {
                  row.isArchived = isArchived
                  row.isArchivationRequestAccepted = isArchived
                } else {
                  updated.push({ id, isArchived, isArchivationRequestAccepted: isArchived })
                }
                if (typeof row?.isArchived !== 'undefined' && row?.isArchived === initialRow?.isArchived) {
                  delete row.isArchived
                  delete row.isArchivationRequestAccepted
                }
              } else {
                if (row) {
                  row.isArchived = isArchived
                } else {
                  updated.push({ id, isArchived })
                }
                if (typeof row?.isArchived !== 'undefined' && row?.isArchived === initialRow?.isArchived) {
                  delete row.isArchived
                }
              }
              return { updated: filterEquals(updated, initialRow) }
            }),
          setArchiveIds: (ids, initialRows, isAdmin?: boolean) =>
            set(({ updated }) => {
              ids.forEach((id) => {
                const row = updated.find((item) => item.id === id)
                if (isAdmin) {
                  if (row) {
                    row.isArchived = true
                    row.isArchivationRequestAccepted = true
                  } else {
                    updated.push({ id, isArchived: true, isArchivationRequestAccepted: true })
                  }
                } else {
                  if (row) {
                    row.isArchived = true
                  } else {
                    updated.push({ id, isArchived: true })
                  }
                }
              })
              return { updated: filterEqualsArray(updated, initialRows) }
            }),
          setReturnedFromArchiveIds: (ids, initialRows) =>
            set(({ updated }) => {
              ids.forEach((id) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.isArchived = false
                } else {
                  updated.push({ id, isArchived: false })
                }
              })
              return { updated: filterEqualsArray(updated, initialRows) }
            }),
          lastSavedError: null,
          setLastSavedError: (lastSavedError) => set(() => ({ lastSavedError })),
        }),
        {
          name: 'promoTable',
        },
      ),
    ),
    {
      store: 'promoTable',
    },
  ),
)
