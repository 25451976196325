import { RefObject } from 'react'

import { getBorders } from 'ui/Table/helpers/getBorders'
import { SelectionData } from 'ui/Table/interfaces'

interface SelectionArgs {
  index: number
  tableRef: RefObject<HTMLTableElement>
  selection?: SelectionData
  selectable?: boolean
  copyPaste?: boolean
}

export const getFooterSelection = ({ index, tableRef, selection, selectable, copyPaste }: SelectionArgs) => {
  if (!tableRef.current || !selection || !copyPaste) {
    return false
  }
  const { frame, active, inProgress } = selection
  const { maxRow, minColumn, maxColumn, height } = getBorders(frame)
  const showHeaderFrame = maxRow === tableRef.current.rows.length - 1 && active
  const columnIdx = selectable ? index + 1 : index
  const isSelected = showHeaderFrame && columnIdx <= maxColumn && columnIdx >= minColumn
  const isStart = columnIdx === minColumn && !inProgress
  const isEnd = columnIdx === maxColumn && !inProgress
  const haveEdgeBorder = height === 1
  return { isSelected, isStart, isEnd, inProgress, haveEdgeBorder }
}
