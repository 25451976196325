import { ProfileStream } from 'modules/user/api'

export const streamJume = 'Jume'
export const streamOpti = 'Opti'
export const streamDemand = 'Demand'
export const streamPrgm = 'PRGM'
export const streamDeploy = 'Deploy'
export const streamPpds = 'PPDS'

export const streamsWithProject = [streamOpti, streamJume]

export const isJume = (stream: ProfileStream | null) => stream?.title === streamJume
export const isOpti = (stream: ProfileStream | null) => stream?.title === streamOpti
export const isDemand = (stream: ProfileStream | null) => stream?.title === streamDemand
export const isPrgm = (stream: ProfileStream | null) => stream?.title === streamPrgm
export const isDeploy = (stream: ProfileStream | null) => stream?.title === streamDeploy
export const isPpds = (stream: ProfileStream | null) => stream?.title === streamPpds
