import { XYCoord } from 'react-dnd'

export const getDraggableItemStyle = (currentOffset: XYCoord | null, itemWidth: number | null) => {
  if (!currentOffset || !itemWidth) {
    return {
      display: 'none',
    }
  }

  const x = currentOffset.x
  const y = currentOffset.y
  const transform = `translate(${x}px, ${y}px) rotate(1deg)`
  const width = `${itemWidth}px`

  return { transform, WebkitTransform: transform, width }
}
