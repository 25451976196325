import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/two-factor-expired')({
  component: createPage({
    titleKey: 'titles.login',
    lazyImport: {
      fn: () => import('modules/auth/components/TwoFactorExpiredBlock'),
      name: 'TwoFactorExpiredBlock',
    },
  }),
})
