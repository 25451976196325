import { devtools } from 'config/devtools'
import { demandForecastPerPage } from 'constants/pagination'
import {
  useChangedCells as getStateChangedCells,
  useSelectedCells as getStateSelectedCells,
} from 'demand/forecast/store'
import { CellFields, ChangedCell } from 'interfaces/excelTable.interfaces'
import { clone, difference, isEqual } from 'lodash'
import { mergeMaps } from 'packages/helper'
import { generateNumberArray } from 'utils/generateNumberArray'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import { UseForecastTable } from './types'

export const useForecastTable = create<UseForecastTable>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        showChart: false,
        isSaved: true,
        isSnapshotInProgress: false,
        updated: null,
        paginateAxis: null,
        data: null,
        countX: null,
        countY: null,
        perPage: demandForecastPerPage,
        widthColumns: {
          dependencies: null,
          dependenciesChange: null,
          notDependenceMinWidth: null,
          widths: null,
          minimalWidths: null,
        },
        currentPages: [],
        slicePages: [-1, -1],
        needLoadingPages: [],
        setShowChart: (showChart) => set({ showChart }),
        clear: () =>
          set({
            isSaved: true,
            updated: null,
            paginateAxis: null,
            countX: null,
            countY: null,
            data: null,
            widthColumns: {
              dependencies: null,
              dependenciesChange: null,
              notDependenceMinWidth: null,
              widths: null,
              minimalWidths: null,
            },
            currentPages: [],
            slicePages: [-1, -1],
            needLoadingPages: [],
          }),
        setForecastData: (page, data) =>
          set((prev) => {
            const changedCells = getStateChangedCells.getState().changedCells
            const toSaving = getStateChangedCells.getState().toNeedToSend
            toSaving(
              changedCells
                .filter((item) => item.location.page === page)
                .map((item) => {
                  const rows = data.rows[item.location.indexes[0]]
                  const columns = data.columns[item.location.indexes[1]]
                  const fields: CellFields = {}

                  rows?.forEach(({ code, value }) => (fields[code] = value))
                  columns?.forEach(({ code, value }) => (fields[code] = value))

                  return { ...item, fields }
                }),
            )
            data.cells.forEach((row, rowIndex) => {
              row.forEach((cell, cellIndex) => {
                if (data?.cells[rowIndex]?.[cellIndex]) {
                  data.cells[rowIndex][cellIndex] = new Proxy(cell, {
                    set(target, property, value) {
                      if (property === 'value') {
                        if (value !== target.value) {
                          target.value = value
                          ;(target as any).onChangeValue?.(value)
                        }
                      } else {
                        ;(target as any)[property] = value
                      }
                      return true
                    },
                  })
                }
              })
            })
            return {
              paginateAxis: data.paginateAxis,
              countX: data.paginateAxis === 'x' ? data.count : data.columns.length,
              countY: data.paginateAxis === 'y' ? data.count : data.rows.length,
              data: { ...prev.data, [page]: data },
              needLoadingPages: prev.needLoadingPages.filter((item) => item !== page),
            }
          }),
        updateForecastData: (data, loaderSlug) =>
          set((prev) => {
            const changedCells = getStateChangedCells.getState().changedCells
            const removeChangedCells = getStateChangedCells.getState().remove

            const removingChangedCells: ChangedCell[] = []

            Object.keys(data).forEach((key) => {
              const page = Number(key)
              data[page].cells.forEach((row, rowIndex) => {
                row.forEach((cell, cellIndex) => {
                  if (prev.data?.[page]?.cells[rowIndex]?.[cellIndex]) {
                    const prevCell = prev.data[page].cells[rowIndex][cellIndex]
                    const foundChangedCell = changedCells.find(
                      (changedCell) =>
                        (changedCell.status === 'need_to_send' || changedCell.status === 'sent') &&
                        isEqual(
                          {
                            indexes: [rowIndex, cellIndex],
                            page,
                          },
                          changedCell.location,
                        ),
                    )
                    if (
                      foundChangedCell?.status === 'sent' &&
                      foundChangedCell.loaderSlug &&
                      loaderSlug?.includes(foundChangedCell.loaderSlug)
                    ) {
                      removingChangedCells.push(foundChangedCell)
                    }
                    prevCell.lastUpdateDate = new Date()
                    if (
                      (foundChangedCell?.loaderSlug && loaderSlug?.includes(foundChangedCell.loaderSlug)) ||
                      !foundChangedCell?.loaderSlug
                        ? prevCell.value !== cell.value ||
                          prevCell.color !== cell.color ||
                          prevCell.fontColor !== cell.fontColor
                        : false
                    ) {
                      prev.data[page].cells[rowIndex][cellIndex] = {
                        ...prevCell,
                        ...cell,
                      }
                    }
                  }
                })
              })
            })

            removeChangedCells(removingChangedCells)

            const stateSelectedCells = getStateSelectedCells.getState()
            stateSelectedCells.setSelectedCells(stateSelectedCells.selectedCells, prev)
            return clone(prev)
          }),
        updateForecastCell: (value, page, indexRow, indexColumn) =>
          set((prev) => {
            if (!prev.data) {
              return prev
            }
            prev.data[page].cells[indexRow][indexColumn].lastUpdateDate = new Date()
            if (prev.data[page].cells[indexRow][indexColumn].value !== value) {
              prev.data[page].cells[indexRow][indexColumn].value = value
              prev.data[page].cells[indexRow][indexColumn] = clone(prev.data[page].cells[indexRow][indexColumn])
            }
            return clone(prev)
          }),
        setDependencies: (dependencies) =>
          set((prev) => {
            if (!prev.widthColumns.dependencies) {
              prev.widthColumns.dependencies = dependencies
            } else {
              mergeMaps(prev.widthColumns.dependencies, dependencies)
            }
            return prev
          }),
        setDependenciesChange: (dependenciesChange) =>
          set((prev) => {
            if (!prev.widthColumns.dependenciesChange) {
              prev.widthColumns.dependenciesChange = dependenciesChange
            } else {
              mergeMaps(prev.widthColumns.dependenciesChange, dependenciesChange)
            }
            return prev
          }),
        setDependenciesMinWidth: (notDependenceMinWidth) =>
          set((prev) => {
            if (!prev.widthColumns.notDependenceMinWidth) {
              prev.widthColumns.notDependenceMinWidth = notDependenceMinWidth
            } else {
              mergeMaps(prev.widthColumns.notDependenceMinWidth, notDependenceMinWidth)
            }
            return prev
          }),
        setWidthByKeys: (keys, width) =>
          set((prev) => {
            for (const key of keys) {
              if (!prev.widthColumns.widths) {
                prev.widthColumns.widths = {}
              }
              const diff = width - prev.widthColumns.widths[key]
              prev.widthColumns.widths[key] = width

              const dependenciesChange = Array.from(prev.widthColumns.dependenciesChange?.get(key) || [])
              const notDependenceMinWidth = Array.from(prev.widthColumns.notDependenceMinWidth?.get(key) || [])
              const listDependencies = difference(dependenciesChange, notDependenceMinWidth)
              if (dependenciesChange) {
                dependenciesChange.forEach((itemKey) => {
                  if (prev.widthColumns.widths) {
                    prev.widthColumns.widths[itemKey] = prev.widthColumns.widths[itemKey] + diff
                  }
                })
              }
              listDependencies.forEach((itemKey) => {
                if (prev.widthColumns.minimalWidths) {
                  prev.widthColumns.minimalWidths[itemKey] = prev.widthColumns.minimalWidths[itemKey] + diff
                }
              })
            }

            return clone(prev)
          }),
        setWidths: (widths) =>
          set((prev) => {
            prev.widthColumns.widths = prev.widthColumns.widths
              ? Object.assign(prev.widthColumns.widths, widths)
              : widths
            return prev
          }),
        setMinimalWidths: (minimalWidths) =>
          set((prev) => {
            prev.widthColumns.minimalWidths = prev.widthColumns.minimalWidths
              ? Object.assign(prev.widthColumns.minimalWidths, minimalWidths)
              : minimalWidths
            return prev
          }),
        addKeyToCells: (page, keys) =>
          set((prev) => {
            prev.data?.[page]?.cells.forEach((row) =>
              row.forEach((cell, index) => {
                cell.key = keys[index]
              }),
            )
            return prev
          }),
        setSlicePages: (slicePages) =>
          set((prev) => {
            if (isEqual(slicePages, prev.slicePages)) {
              return prev
            }
            prev.slicePages = slicePages
            prev.currentPages = generateNumberArray(slicePages[0] + 1, slicePages[1] + 1).filter((item) => item >= 1)
            return clone(prev)
          }),
        setNeedLoadingPages: (needLoadingPages) =>
          set((prev) => {
            if (isEqual(prev.needLoadingPages, needLoadingPages)) {
              return prev
            }
            return { needLoadingPages }
          }),
        setIsSaved: (isSaved) => set({ isSaved }),
        setUpdated: (updated) => set({ updated }),
        setIsSnapshotInProgress: (isSnapshotInProgress) => set({ isSnapshotInProgress }),
      }),
      {
        store: 'forecastTable',
      },
    ),
  ),
)
