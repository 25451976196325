import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import { UseResetPasswordTokens } from './types'

export const useResetPasswordTokens = create<UseResetPasswordTokens>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        access: '',
        refresh: '',
        setAccess: (access) => set({ access }),
        setRefresh: (refresh) => set({ refresh }),
        setRemember: (remember) => set({ remember }),
        clear: () => {
          set({
            access: '',
            refresh: '',
            remember: undefined,
          })
        },
      }),
      {
        name: 'resetPasswordTokens',
      },
    ),
  ),
)
