import { FC } from 'react'

import cx from 'clsx'

import classes from './EditModeKPIWidget.module.scss'

export const EditModeKPIWidgetColumn: FC = () => (
  <div className={classes.column}>
    <div className={cx(classes.line, classes.lineTitle)} />
    <div className={cx(classes.line, classes.lineLong)} />
    <div className={cx(classes.line, classes.lineShort)} />
    <div className={cx(classes.line, classes.lineLong)} />
  </div>
)
