import { forwardRef, ReactNode } from 'react'

import { Modal } from 'ui/Modal'
import { OverlayingModal } from 'ui/Modal/OverlayingModal'

interface TextModalProps {
  className?: string
  classNamePopup?: string
  isOpened?: boolean
  onClose?: () => void
  onUnmount?: () => void
  title?: ReactNode
  children?: ReactNode
}

export const TextModal = forwardRef<HTMLDivElement, TextModalProps>(
  ({ className, classNamePopup, isOpened, onClose, onUnmount, title, children }, ref) => (
    <OverlayingModal classNamePopup={classNamePopup} isOpened={isOpened} onClose={onClose} ref={ref}>
      <Modal className={className} onClose={onClose} onUnmount={onUnmount} title={title}>
        {children}
      </Modal>
    </OverlayingModal>
  ),
)
