import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseSelectionsParameters } from './types'

export const useSelectionsParameters = create<UseSelectionsParameters>()(
  devtools(
    (set) => ({
      currentTab: null,
      search: null,
      setTab: (currentTab) => set({ currentTab, search: null }),
      setSearch: (search) => set({ search }),
      clear: () => set({ currentTab: null, search: null }),
    }),
    {
      store: 'selectionsParameters',
    },
  ),
)
