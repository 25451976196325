import { redirect } from '@tanstack/react-router'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { RouterHistory } from 'interfaces/router.interfaces'
import { authApi } from 'modules/auth/api'
import { useResetPasswordTokens } from 'modules/resetPassword/store'

export const twoFactorLoader = async (history?: RouterHistory) => {
  const search = history ? history.location.search : window.location.search
  const params = new URLSearchParams(search)
  const access = params.get('access')
  const refresh = params.get('refresh')
  const isTwoFactor = params.get('two_factor')
  const store = useResetPasswordTokens.getState()

  if (isTwoFactor) {
    if (access && refresh) {
      try {
        setAxiosAuthorizationToken(access)
        await authApi.checkTwoFactorCode()
        storage.setToken(access, store.remember)
        storage.setRefresh(refresh, store.remember)
      } catch (e) {
        store.setAccess(access)
        store.setRefresh(refresh)
        setAxiosAuthorizationToken(access)
        if (history) {
          history.push('/two-factor-expired')
        } else {
          redirect({ to: '/two-factor-expired' })
        }
      }
    } else {
      if (history) {
        history.push('/login')
      } else {
        redirect({ to: '/login' })
      }
    }
  }
}
