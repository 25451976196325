import { devtools } from 'config/devtools'
import { clone, cloneDeep, isEqual } from 'lodash'
import { deleteNullable, deleteUndefined } from 'packages/helper'
import { create } from 'zustand'

import { UseFilterState } from './types'

export const useFilterState = create<UseFilterState>()(
  devtools(
    (set, get) => ({
      filter: null,
      filterItems: null,
      clear: () =>
        set(
          {
            filter: null,
            filterItems: null,
          },
          undefined,
          'clear',
        ),
      setFilter: (filter) => set({ filter }),
      setCurrentState: (code, state, isApply) =>
        set(
          (prev) => {
            if (!prev.filterItems) {
              prev.filterItems = {}
            }
            if (!prev.filterItems[code]) {
              prev.filterItems[code] = { common: null, current: {} }
            }
            prev.filterItems[code].current = state
            if (isApply) {
              prev.filter = cloneDeep(prev.filterItems[code].common) || {}
              prev.filter[code] = deleteUndefined(prev.filterItems[code].current)
              if (isEqual(prev.filter[code], {})) {
                delete prev.filter[code]
              }
              prev.filter = deleteUndefined(prev.filter)
              if (isEqual(prev.filter, {})) {
                prev.filter = null
              }
              prev.filterItems[code].common = prev.filter
            }
            return clone(prev)
          },
          undefined,
          'setCurrentState',
        ),
      commonToCurrentState: (code) =>
        set(
          (prev) => {
            if (!prev.filterItems) {
              prev.filterItems = {}
            }
            if (!prev.filterItems[code]) {
              prev.filterItems[code] = { common: null, current: {} }
            }
            prev.filterItems[code].common = cloneDeep(prev.filter)
            return { filterItems: prev.filterItems }
          },
          undefined,
          'commonToCurrentState',
        ),
      setCommonState: (code) =>
        set(
          (prev) => {
            if (!prev.filterItems?.[code]) {
              return clone(prev)
            }
            prev.filter = cloneDeep(prev.filterItems[code].common) || {}
            prev.filter[code] = deleteUndefined(prev.filterItems[code].current)
            if (isEqual(prev.filter[code], {})) {
              delete prev.filter[code]
            }
            prev.filter = deleteUndefined(prev.filter)
            if (isEqual(prev.filter, {})) {
              prev.filter = null
            }
            return clone(prev)
          },
          undefined,
          'setCommonState',
        ),
      getSavedState: (code) => get().filter?.[code] || {},
      getIsFilledFilter: () => !!get().filter && !isEqual(get().filter, {}),
      getIsFilledFilterByCode: (code) =>
        !!get().filterItems?.[code]?.common && !isEqual(get().filterItems?.[code]?.common, {}),
      resetItem: (code) =>
        set(
          (prev) => {
            if (!prev.filterItems) {
              prev.filterItems = {}
            }
            if (!prev.filterItems[code]) {
              prev.filterItems[code] = { common: null, current: {} }
            }
            if (prev.filterItems[code].common) {
              prev.filterItems[code].common = deleteUndefined({ ...prev.filterItems[code].common, [code]: undefined })
            }
            if (isEqual(deleteNullable(prev.filterItems[code].common), {})) {
              prev.filterItems[code].common = null
            }
            return clone(prev)
          },
          undefined,
          'resetItem',
        ),
    }),
    {
      store: 'demandForecastFilters',
    },
  ),
)
