import { KeyboardEventHandler, useEffect, useMemo, useState } from 'react'

import { CellContext } from '@tanstack/table-core'
import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { TextFieldColors } from 'interfaces/components.interfaces'
import { TRow } from 'interfaces/table.interfaces'
import { toBr } from 'packages/helper'
import { Button } from 'ui/Button'
import { TextField } from 'ui/TextField'

import classes from './Table.module.scss'

export const TableCell = <TData extends TRow>({
  getValue,
  getCellProps,
  getRowData,
  row: { index },
  column: { id },
  table,
}: CellContext<TData, string | number | null>) => {
  const cellProps = useMemo(getCellProps, [getCellProps])
  const originalProps = (getRowData() as any)[id]

  const [value, setValue] = useState(String(getValue() ?? ''))
  const [initialValue, setInitialValue] = useState(String(getValue() ?? ''))
  const [type, setType] = useState(cellProps.getType?.(value) ?? TextFieldColors.Default)

  const isSaveEditByButton = table.options.data[index]?.props?.isSaveEditByButton
  const inputHasChanged = table.options.meta?.inputHasChanged
  const loadingUpdateButton = table.options.meta?.loadingUpdateButton

  const translate = useTranslate()

  const onChange = (newValue: string) => {
    setValue(newValue)
    table.options.meta?.onChangeInput?.(newValue, originalProps.key ?? id, index)
    if (isSaveEditByButton) {
      table.options.meta?.setData?.(newValue ?? 0, id, index)
    }
    if (cellProps.getType) {
      setType(cellProps.getType(newValue))
    }
  }

  const onFocus = () => {
    setInitialValue(value)
  }

  const onBlur = () => {
    if (initialValue !== value && !isSaveEditByButton) {
      table.options.meta?.updateData?.(value, id, index)
    }
  }

  const onEnter: KeyboardEventHandler = (event) => {
    const target = event.target as HTMLInputElement
    const start = target.selectionStart ?? value.length
    const end = target.selectionEnd ?? value.length

    setValue((prev) => {
      const newValue = prev.substring(0, start) + '\n' + prev.substring(end)
      table.options.meta?.onChangeInput?.(newValue, id, index)
      return newValue
    })
    setTimeout(() => target.setSelectionRange(start + 1, start + 1), 10)
  }

  const onApply = () => {
    table.options.meta?.applyEditData?.()
  }

  useEffect(() => {
    const updatedValue = table.options.meta?.newRowData?.[id]
    if (cellProps.getType) {
      setType(cellProps.getType(String(updatedValue)))
    }
    setValue(updatedValue ? String(updatedValue) : String(getValue() ?? ''))
  }, [getValue()])

  useEffect(() => {
    if (table.options.meta?.isDataApplied) {
      setValue('0')
      setType(TextFieldColors.Default)
      table.options.meta?.setIsDataApplied?.(false)
    }
  }, [table.options.meta?.isDataApplied])

  return (
    <div>
      {id === 'name' && isSaveEditByButton && inputHasChanged ? (
        <Button loading={loadingUpdateButton} onClick={onApply}>
          {translate('apply')}
        </Button>
      ) : (
        <>
          <TextField
            className={cx(classes.input, {
              [classes.disabled]: cellProps.disabled,
              [classes.hasTop]: cellProps.editableMatrix.hasTop,
              [classes.hasBottom]: cellProps.editableMatrix.hasBottom,
              [classes.hasLeft]: cellProps.editableMatrix.hasLeft,
              [classes.hasRight]: cellProps.editableMatrix.hasRight,
            })}
            classNameContainer={classes.inputCont}
            {...cellProps.inputProps}
            color={type}
            disabled={cellProps.disabled || loadingUpdateButton}
            multiline
            onBlur={onBlur}
            onChange={onChange}
            onCtrlEnter={onEnter}
            onFocus={onFocus}
            value={value}
          />
          <span className={classes.fake}>{value ? toBr(value) : <>&nbsp;</>}</span>
        </>
      )}
    </div>
  )
}
