import { useTitle as useTitleBase } from 'ahooks'
import { useTranslate } from 'config/i18n'
import { APP_NAME } from 'constants/env'

export const useTitle = (
  key: Parameters<ReturnType<typeof useTranslate>>[0],
  params?: Record<string, string | number>,
) => {
  const translate = useTranslate()
  useTitleBase(translate(key, { appName: APP_NAME, ...params }))
}
