import cx from 'clsx'
import { LOGO } from 'constants/env'

import classes from './Logo.module.scss'

interface LogoProps {
  className?: string
}

export function Logo({ className }: LogoProps) {
  return <img alt="Logo" className={cx(className, classes.wrap)} src={LOGO} />
}
