import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/auth'

interface Endpoints {
  login: NoParamEndpointConstructor
  refresh: NoParamEndpointConstructor
  resetPassword: NoParamEndpointConstructor
  logoutInform: NoParamEndpointConstructor
  resendTwoFactorCode: NoParamEndpointConstructor
  twoFactorLogin: NoParamEndpointConstructor
  checkTwoFactorCode: NoParamEndpointConstructor
  getAuthSsoTokens: NoParamEndpointConstructor
}

export const authEndpoints: Endpoints = {
  login: () => ({
    url: `${baseUrl}/login`,
    apiRoute: `${baseUrl}/login`,
  }),
  refresh: () => ({
    url: `${baseUrl}/refresh`,
    apiRoute: `${baseUrl}/refresh`,
  }),
  resetPassword: () => ({
    url: '/account/reset-password',
    apiRoute: '/account/reset-password',
  }),
  logoutInform: () => ({
    url: '/account/termination-session',
    apiRoute: '/account/termination-session',
  }),
  resendTwoFactorCode: () => ({
    url: `${baseUrl}/resending-two-factor-authentication-code`,
    apiRoute: `${baseUrl}/resending-two-factor-authentication-code`,
  }),
  twoFactorLogin: () => ({
    url: `${baseUrl}/two-factor-authentication`,
    apiRoute: `${baseUrl}/two-factor-authentication`,
  }),
  checkTwoFactorCode: () => ({
    url: `${baseUrl}/check-two-factor-authentication-code`,
    apiRoute: `${baseUrl}/check-two-factor-authentication-code`,
  }),
  getAuthSsoTokens: () => ({
    url: `${baseUrl}/sso-callback`,
    apiRoute: `${baseUrl}/sso-callback`,
  }),
}
