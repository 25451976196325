import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { ApiErrorsCodes } from 'config/api'
import { useLang, useTranslate } from 'config/i18n'
import { getLang } from 'config/lang'
import { IS_PRGM_ENV } from 'constants/env'
import { Project, projectsApi } from 'modules/projects/api'
import { useSetLangByProject } from 'modules/streams/handlers'
import { useProfile } from 'modules/user/queries'
import { noRetryOnError } from 'packages/react-query'

import { ProjectsQueries } from './types'

export const useProject = () => {
  const { profile } = useProfile()
  const setLang = useLang((state) => state.setLang)
  const translate = useTranslate()
  const params = useParams({ strict: false })
  const projectId = params.projectId ? Number(params.projectId) : profile?.activeProjectId
  const enabled = !!projectId && !IS_PRGM_ENV

  const { data, isPending, error } = useQuery({
    queryKey: [ProjectsQueries.Project, projectId],
    queryFn: () => {
      if (projectId) {
        return projectsApi.getProject(projectId)
      }
      return null
    },
    enabled,
    ...noRetryOnError,
    meta: {
      error: (apiError) => ({
        message:
          apiError?.code === ApiErrorsCodes.NotFound
            ? translate('projects.notFound', { projectId: String(projectId) })
            : translate('errorsNoData.project'),
        showToast: false,
      }),
      onSuccess: (project: Project) => {
        setLang(project?.language.code ?? getLang())
      },
    },
  })

  useSetLangByProject(data?.language.code)

  return {
    project: data,
    isLoadingProject: isPending && enabled,
    error,
    isPoc: !!data?.pocDemand,
  }
}
