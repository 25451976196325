import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/replen-optimizer/replen-scenarios/')({
  component: createPage({
    titleKey: 'titles.replenOptimizer',
    lazyImport: {
      fn: () => import('jume/replenScenarios/components/ReplenScenariosList'),
      name: 'ReplenScenariosList',
    },
  }),
})
