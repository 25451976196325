import { useCallback } from 'react'

import { useNavigate, useRouterState } from '@tanstack/react-router'
import { IS_JUME } from 'constants/env'
import { useLastSession } from 'modules/session/store'

export const useNavigateToLastPage = () => {
  const lastPage = useLastSession((state) => state.page)
  const {
    location: { pathname },
  } = useRouterState()
  const navigate = useNavigate()
  const setIsNavigated = useLastSession((state) => state.setIsNavigated)

  const navigateToLastPage = useCallback(() => {
    if (lastPage && (pathname === '/jume' || pathname === '/login') && IS_JUME) {
      navigate({ to: lastPage })
    } else {
      navigate({ to: '/' })
    }
    setTimeout(() => setIsNavigated(true), 300)
  }, [pathname, lastPage])

  return { navigateToLastPage }
}
