import { MutableRefObject, ReactNode } from 'react'

import { Table } from '@tanstack/table-core'
import cx from 'clsx'
import { TRow } from 'interfaces/table.interfaces'
import { SelectionData } from 'ui/Table/interfaces'
import { SelectTh } from 'ui/Table/SelectTh'
import classes from 'ui/Table/Table.module.scss'
import { ThCell } from 'ui/Table/ThCell'
import { CollapsingStrategy } from 'ui/Table/types'

interface GetRenderedHeaderProps<TData extends TRow> {
  table: Table<TData>
  isCollapsing?: boolean
  rowClassName?: string
  headerRowClassName?: string
  selectPosition?: 'left' | 'right'
  columnResizable?: boolean
  data?: TData[] | null
  deletable?: boolean
  colExtra?: number
  createdRows?: TData[] | null
  trackViewport?: boolean
  truncateHeaders?: boolean
  refFirstColumn?: MutableRefObject<HTMLTableCellElement | null>
  refColumns?: MutableRefObject<(HTMLTableCellElement | null)[]>
  onViewPort?: (columnId: string, inViewport: boolean) => void
  onResize?: (columnId: number | string, size: number, isAutoWidthLastColumn: boolean) => void
  columnDraggable?: boolean
  renderSelectAll?: () => ReactNode
  copyPaste?: boolean
  selection?: SelectionData
  showBorderCell?: boolean
  collapsingStrategy?: CollapsingStrategy
  isDraggable?: boolean
  widthFullAlways?: boolean
}

export const getRenderedHeader = <TData extends TRow>({
  table,
  isCollapsing,
  rowClassName,
  headerRowClassName,
  selectPosition,
  columnResizable,
  data,
  deletable,
  colExtra,
  createdRows,
  trackViewport,
  truncateHeaders,
  refFirstColumn,
  refColumns,
  onViewPort,
  onResize,
  columnDraggable,
  renderSelectAll,
  copyPaste,
  selection,
  showBorderCell,
  collapsingStrategy,
  isDraggable,
  widthFullAlways,
}: GetRenderedHeaderProps<TData>) =>
  table
    .getHeaderGroups()
    .filter(
      (headerGroup) =>
        !!headerGroup.headers.find(
          (header) =>
            (typeof header.column.columnDef.header !== 'function' && !!header.column.columnDef.header) ||
            (typeof header.column.columnDef.header === 'function' &&
              !!header.column.columnDef.header(header.getContext())),
        ),
    )
    .map((headerGroup) => (
      <tr className={cx(classes.row, rowClassName, headerRowClassName)} key={headerGroup.id}>
        {selectPosition === 'left' && renderSelectAll?.()}
        {!!data?.length && isDraggable && <th className={classes.draggableTd}>&nbsp;</th>}
        {!!data?.length && isCollapsing && (
          <th
            className={cx(classes.arrowTd, {
              [classes.eyeTd]: collapsingStrategy === 'toggle',
            })}
          >
            &nbsp;
          </th>
        )}

        {headerGroup.headers.map((header, index) => (
          <ThCell
            colExtra={colExtra}
            columnDraggable={columnDraggable}
            columnResizable={columnResizable}
            copyPaste={copyPaste}
            header={header}
            headerGroup={headerGroup}
            index={index}
            isLast={index === headerGroup.headers.length - 1}
            key={header.id}
            onResize={onResize}
            onViewPort={onViewPort}
            refColumns={refColumns}
            refFirstColumn={refFirstColumn}
            selection={selection}
            showBorderCell={showBorderCell}
            table={table}
            trackViewport={trackViewport}
            truncateHeaders={truncateHeaders}
            widthFullAlways={widthFullAlways}
          />
        ))}
        {selectPosition === 'right' && renderSelectAll?.()}
        {!!data?.length && deletable && <SelectTh>&nbsp;</SelectTh>}
        {!!colExtra && !!createdRows?.length && <SelectTh>&nbsp;</SelectTh>}
      </tr>
    ))
