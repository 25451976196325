import { devtools } from 'config/devtools'
import { MasterDataTabsCodes } from 'jume/masterData/interfaces/common'
import { create } from 'zustand'

import { UseCurrentMasterDataTab } from './types'

export const useCurrentMasterDataTab = create<UseCurrentMasterDataTab>()(
  devtools(
    (set) => ({
      currentTab: MasterDataTabsCodes.All,
      setCurrentTab: (currentTab) => set({ currentTab }),
    }),
    {
      store: 'currentMasterDataTab',
    },
  ),
)
