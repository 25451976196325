import storage from 'config/storage'
import { WS_URL } from 'constants/env'
import { paramsSerializerToSnakeCase } from 'packages/api'

export const createWSUrl = (url: string, params?: unknown) => {
  const token = storage.getToken()
  const serializedParams = paramsSerializerToSnakeCase(token ? { token, ...(params ?? {}) } : { ...(params ?? {}) })

  return `${WS_URL}${url}?${serializedParams}`
}
