import { createEndpoint } from 'config/api'
import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/feedback'

interface Endpoints {
  sendFeedback: NoParamEndpointConstructor
}

export const feedbackEndpoints: Endpoints = {
  sendFeedback: () => createEndpoint(`${baseUrl}`),
}
