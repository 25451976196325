import { useEffect, useState } from 'react'

import { useMatchRoute, useNavigate, useRouter, useRouterState } from '@tanstack/react-router'
import { router } from 'App'
import {
  cfrOptimizerRoutes,
  forecastRoutes,
  promoOptimizerRoutes,
  replenOptimizerRoutes,
  rtmOptimizerRoutes,
  tetrisOptimizerRoutes,
} from 'config/router/routes'
import storage from 'config/storage'
import { IS_DANON, IS_JUME } from 'constants/env'
import { projectTypeMap, ProjectTypes } from 'constants/projects'
import { useProject } from 'modules/projects/queries'
import { useLastSession } from 'modules/session/store'
import { isOpti } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { removeByValue } from 'packages/helper'

export const useProjectType = () => {
  const [isUnavailable, setIsUnavailable] = useState(false)
  const { history } = useRouter()
  const {
    location: { pathname },
  } = useRouterState()
  const matchRoute = useMatchRoute()

  const navigate = useNavigate()
  const { project, isPoc } = useProject()
  const currentStream = useCurrentStream((state) => state.currentStream)

  const storageProjectType = storage.getProjectType()

  const typeCode = project?.type.code

  const forecastLocation = projectTypeMap[ProjectTypes.Forecast]
  const forecastPocLocation = projectTypeMap[ProjectTypes.ForecastPoc]
  const promoLocation = projectTypeMap[ProjectTypes.PromoOptimizer]
  const replenLocation = projectTypeMap[ProjectTypes.ReplenOptimizer]
  const rtmLocation = projectTypeMap[ProjectTypes.RtmOptimizer]
  const tetrisLocation = projectTypeMap[ProjectTypes.TetrisOptimizer]
  const cfrLocation = projectTypeMap[ProjectTypes.CfrOptimizer]

  const locations = [forecastLocation, promoLocation, replenLocation, rtmLocation, tetrisLocation, cfrLocation]
  const lastPage = useLastSession((state) => state.page)
  const isNavigatedToLastPage = useLastSession((state) => state.isNavigated)
  const isShouldRedirectPoc = typeCode === ProjectTypes.Forecast && isPoc && pathname === '/jume'

  const handleReturnBack = () => {
    setIsUnavailable(false)
    history.back()
  }

  useEffect(() => {
    if (isShouldRedirectPoc) {
      navigate({ to: forecastPocLocation, params: {} })
      return
    }

    if ((!isNavigatedToLastPage && lastPage && IS_JUME) || !typeCode) {
      return
    }

    if (storageProjectType === typeCode) {
      if (typeCode === ProjectTypes.PromoOptimizer && removeByValue(locations, promoLocation).includes(pathname)) {
        navigate({ to: promoLocation, params: {} })
        return
      }
      if (typeCode === ProjectTypes.Forecast && removeByValue(locations, forecastLocation).includes(pathname)) {
        navigate({ to: forecastLocation, params: {} })
        return
      }
      if (typeCode === ProjectTypes.ReplenOptimizer && removeByValue(locations, replenLocation).includes(pathname)) {
        navigate({ to: replenLocation, params: {} })
        return
      }
      if (typeCode === ProjectTypes.RtmOptimizer && removeByValue(locations, rtmLocation).includes(pathname)) {
        navigate({ to: rtmLocation, params: {} })
        return
      }
      if (typeCode === ProjectTypes.TetrisOptimizer && removeByValue(locations, tetrisLocation).includes(pathname)) {
        navigate({ to: tetrisLocation, params: {} })
        return
      }
      if (typeCode === ProjectTypes.CfrOptimizer && removeByValue(locations, cfrLocation).includes(pathname)) {
        navigate({ to: cfrLocation, params: {} })
        return
      }
    } else {
      storage.setProjectType(typeCode)
      if (IS_DANON) {
        navigate({
          to: pathname === '/' || locations.includes(pathname) ? projectTypeMap[typeCode] : location.pathname,
        })
        return
      } else {
        navigate({ to: projectTypeMap[typeCode], params: {} })
        return
      }
    }
  }, [typeCode, isShouldRedirectPoc])

  useEffect(() => {
    if (!Object.keys(router.routesByPath).includes(pathname) && !isOpti(currentStream)) {
      return
    }
    if (
      (forecastRoutes.find((path) => matchRoute({ to: path, pending: true })) && typeCode !== ProjectTypes.Forecast) ||
      (promoOptimizerRoutes.find((path) => matchRoute({ to: path, pending: true })) &&
        typeCode !== ProjectTypes.PromoOptimizer) ||
      (replenOptimizerRoutes.find((path) => matchRoute({ to: path, pending: true })) &&
        typeCode !== ProjectTypes.ReplenOptimizer) ||
      (rtmOptimizerRoutes.find((path) => matchRoute({ to: path, pending: true })) &&
        typeCode !== ProjectTypes.RtmOptimizer) ||
      (tetrisOptimizerRoutes.find((path) => matchRoute({ to: path, pending: true })) &&
        typeCode !== ProjectTypes.TetrisOptimizer) ||
      (cfrOptimizerRoutes.find((path) => matchRoute({ to: path, pending: true })) &&
        typeCode !== ProjectTypes.CfrOptimizer)
    ) {
      if (typeCode) {
        setIsUnavailable(true)
      }
    } else {
      setIsUnavailable(false)
    }
  }, [typeCode, pathname])

  return {
    isUnavailable,
    handleReturnBack,
    isRedirecting: isShouldRedirectPoc,
  }
}
