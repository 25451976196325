import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/rtm-optimizer/')({
  component: createPage({
    titleKey: 'titles.rtmOptimizer',
    lazyImport: {
      fn: () => import('opti/rtmScenario/components/RtmScenariosList'),
      name: 'RtmScenariosList',
    },
  }),
})
