import { calculateMovingData } from 'components/common/Draggable/helpers/calculateMovingData'
import { LevelsStrategy } from 'components/common/Draggable/types'

export const equalLevelsStrategy: LevelsStrategy = (params) => {
  const canInsert: ReturnType<LevelsStrategy>['canInsert'] = () => {
    if (
      params.refInitialIndex.current === null ||
      params.refMetaItems.current[params.refInitialIndex.current]?.level === undefined ||
      params.refRealDraggableIndex.current === null
    ) {
      return false
    }
    let draggableIndex = params.refRealDraggableIndex.current
    if (draggableIndex === params.refMetaItems.current.length) {
      draggableIndex--
    }
    if (draggableIndex < 0) {
      return false
    }
    return params.refIsOtherList.current && params.maxLevel
      ? [params.maxLevel - 1, params.maxLevel].includes(params.refMetaItems.current[draggableIndex].level)
      : [
          params.refMetaItems.current[draggableIndex].level + 1,
          params.refMetaItems.current[draggableIndex].level,
        ].includes(params.refMetaItems.current[params.refInitialIndex.current].level)
  }

  return {
    canInsert,
    getLevelPositions: () => {
      if (
        !canInsert() ||
        !params.refPositions.current ||
        !params.refList.current ||
        params.refRealDraggableIndex.current === null ||
        params.refInitialIndex.current === null
      ) {
        return null
      }

      const { positions, items } = calculateMovingData(params)
      const currentLevel = params.refIsOtherList.current
        ? params.maxLevel
        : params.refMetaItems.current[params.refInitialIndex.current]?.level

      let topIndex = params.refRealDraggableIndex.current
      let bottomIndex = params.refRealDraggableIndex.current
      if (topIndex === params.refMetaItems.current.length) {
        topIndex--
      }
      if (bottomIndex === params.refMetaItems.current.length) {
        bottomIndex--
      }

      for (let i = topIndex; i >= 0; i--) {
        topIndex = i
        if (params.refMetaItems.current[i]?.level !== currentLevel) {
          break
        }
      }

      const initialBottomIndex = bottomIndex
      for (let i = initialBottomIndex; i <= items.length; i++) {
        if (
          params.maxLevel === undefined
            ? params.refMetaItems.current[i]?.level !== currentLevel
            : i > initialBottomIndex && params.refMetaItems.current[i]?.level < params.maxLevel
        ) {
          break
        }
        bottomIndex = i
      }

      const top = positions[topIndex]
      const height = positions[bottomIndex + 1] - top - 1

      return { top, height }
    },
  }
}
