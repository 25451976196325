import { FC, Fragment, HTMLAttributes, MouseEvent, ReactNode } from 'react'

import { ColumnDefTemplate, flexRender, Getter, NoInfer } from '@tanstack/react-table'
import { VirtualItem } from '@tanstack/react-virtual'
import { CellContext, ColumnMeta, Row } from '@tanstack/table-core'
import ArrowBackIcon from 'assets/images/arrow-back.svg?react'
import ArrowRightIcon from 'assets/images/arrow-right.svg?react'
import CloseIcon from 'assets/images/close-16.svg?react'
import DeleteIcon from 'assets/images/delete-mini.svg?react'
import MoveElementIcon from 'assets/images/move-element.svg?react'
import cx from 'clsx'
import { SELECT_ALL } from 'constants/components'
import { TextFieldColors } from 'interfaces/components.interfaces'
import { RowProps, TRow } from 'interfaces/table.interfaces'
import { isEqual } from 'lodash'
import { deleteUndefined } from 'packages/helper'
import { SlideToggle } from 'ui/SlideToggle'
import { EyeToggler } from 'ui/Table/components/EyeToggler'
import { StatusCell } from 'ui/Table/components/StatusCell'
import { useMatrix } from 'ui/Table/hooks/useMatrixEditable'
import { SelectableCellParams } from 'ui/Table/hooks/useSelectableCell'
import { InitContextMenu, OnRowClick, OnRowContextMenu } from 'ui/Table/interfaces'
import { SelectTd } from 'ui/Table/SelectTd'
import classes from 'ui/Table/Table.module.scss'
import { TableCell } from 'ui/Table/TableCell'
import { Td } from 'ui/Table/Td'
import { Tr } from 'ui/Table/Tr'
import { CollapsingStrategy, DraggableStrategy, RowId } from 'ui/Table/types'
import { Tooltip } from 'ui/Tooltip'
import { mergeObjects } from 'utils/mergeObjects'

import { getBorderRadius } from './getBorderRadius'
import { getCellProps } from './getCellProps'
import { getClassesForId } from './getClassesForId'
import { getColumnProps } from './getColumnProps'
import { getRowId } from './getRowId'
import { getValueCell } from './getValueCell'

interface GetRenderedRowsProps<TData extends TRow> {
  rows: Row<TData>[]
  mayReturnIds?: number[]
  archivedIds?: number[]
  deleteActiveIds?: any[]
  inputHasChanged?: boolean
  isCollapsing?: boolean
  collapsingComponent?: FC<{ row?: TData }>
  isCollapsedRowInternal: (row: TData) => boolean
  expandedRowsInternal: RowId[]
  onRow?: (row: TData) => HTMLAttributes<HTMLTableRowElement>
  rowClassName?: string
  rowSelectedClassName?: string
  canReturnFromArchive?: boolean
  showCountRowsInfo?: boolean
  forceDeleteTooltip?: boolean
  classNameExpanded?: string
  classesRows?: Record<string, number[]>
  toggleCollapse: (rowId: RowId, expanded?: boolean) => void
  selectPosition?: 'left' | 'right'
  renderSelect: (row: any) => ReactNode
  showFooterInternal?: boolean
  onRowClick?: OnRowClick<TData>
  onRowContextMenu?: OnRowContextMenu<TData>
  initContextMenu?: InitContextMenu
  columnResizable?: boolean
  dashIfEmpty?: boolean
  matrix: ReturnType<typeof useMatrix>
  getType?: (value: string) => TextFieldColors
  data?: TData[] | null
  deletable?: boolean
  onDelete?: (id: number) => void
  deleteInactiveTooltip?: ReactNode
  selectable?: boolean
  onReturn?: (id: number) => void
  deleteIconClassName?: string
  onRemoveNew?: (index: number) => void
  createdRows?: TData[] | null
  CollapsingComponent?: FC<{ row?: TData }>
  classNameCollapsedRow?: string
  colSpan: number
  getMeta?: () => any
  selectedIds?: any[]
  errorsIds?: RowId[]
  disabledIds?: RowId[]
  collapsingTreeKeys?: string[]
  collapsingStrategy?: CollapsingStrategy
  isCollapsed?: (rowId: RowId) => boolean
  isParent?: (rowId: RowId) => boolean
  isMouseOverParent?: (rowId: RowId) => void
  isMouseOutParent?: () => void
  hoverParent?: RowId
  isDraggable?: boolean
  draggableStrategy?: DraggableStrategy
  virtualRows?: VirtualItem[]
  showStatus?: boolean
  rowRef?: (node: any) => void
  sizeLastColumn?: number | null
  selectableCellParams?: SelectableCellParams
}

export const getRenderedRows = <TData extends TRow>({
  rows,
  mayReturnIds,
  archivedIds,
  deleteActiveIds,
  inputHasChanged,
  isCollapsing,
  collapsingComponent,
  isCollapsedRowInternal,
  expandedRowsInternal,
  onRow,
  rowClassName,
  rowSelectedClassName,
  canReturnFromArchive,
  showCountRowsInfo,
  forceDeleteTooltip,
  classNameExpanded,
  classesRows,
  toggleCollapse,
  selectPosition,
  renderSelect,
  showFooterInternal,
  onRowClick,
  onRowContextMenu,
  initContextMenu,
  columnResizable,
  dashIfEmpty = false,
  matrix,
  getType,
  data,
  deletable,
  onDelete,
  deleteInactiveTooltip,
  selectable,
  onReturn,
  deleteIconClassName,
  onRemoveNew,
  createdRows,
  CollapsingComponent,
  classNameCollapsedRow,
  colSpan,
  getMeta,
  selectedIds,
  errorsIds,
  disabledIds,
  collapsingTreeKeys,
  collapsingStrategy,
  isCollapsed,
  isParent,
  isMouseOverParent,
  isMouseOutParent,
  hoverParent,
  isDraggable,
  draggableStrategy,
  virtualRows,
  showStatus,
  rowRef,
  sizeLastColumn,
  selectableCellParams,
}: GetRenderedRowsProps<TData>) => {
  const resultRows = virtualRows
    ? !!virtualRows?.length
      ? virtualRows.map((virtualRow) => {
          const row = rows[virtualRow.index]
          return renderRow(row, virtualRow.index, virtualRow)
        })
      : null
    : !!rows?.length
      ? rows.map((row, y) => renderRow(row, y))
      : null

  function renderRow(row: Row<TData>, y: number, virtualRow?: VirtualItem) {
    const rowId = getRowId(row)
    const rowAttribute = (row?.original as any)?.attribute

    const rowData = row.original.data ?? row.original

    if (rowData.isFooter) {
      return null
    }

    const isMayReturn = mayReturnIds?.includes(rowId)
    const isArchived = archivedIds?.includes(rowId)
    const isDeleteActive = deleteActiveIds?.includes(rowId)
    const isSaveEditByButton = row.original.props?.isSaveEditByButton && inputHasChanged
    const isCollapsingMapper: Record<CollapsingStrategy, boolean> = {
      customComponent: Boolean(isCollapsing && collapsingComponent && isCollapsedRowInternal(rowData)),
      toggle: Boolean(isCollapsing && isCollapsed?.(rowId)),
      tree: Boolean(isCollapsing && isCollapsed?.(rowId)),
    }
    const maxLevel = collapsingTreeKeys?.length || 0
    const isMaxLevel = Boolean(isCollapsing && collapsingStrategy === 'tree' && rowData._level === maxLevel)

    const showDraggableElement =
      isDraggable &&
      (draggableStrategy === 'treeBottomLevel' || draggableStrategy === 'treeBottomLevelNotOrder' ? isMaxLevel : true)

    const isActiveCollapse = collapsingStrategy && isCollapsingMapper[collapsingStrategy]
    const isExpanded = expandedRowsInternal.includes(rowId)
    const isSelected = selectedIds?.includes(rowId) || selectedIds?.includes(SELECT_ALL)

    const meta = {
      level: rowData._level,
      current: deleteUndefined({
        ...rowData,
        meta: undefined,
      }),
      ...rowData.meta,
    }

    const cells = row.getVisibleCells()

    return (
      <Fragment key={row.original.props?.index || `${row.id}-${rowId}`}>
        <Tr
          index={y}
          isDraggable={isDraggable}
          meta={meta}
          style={{
            ...row.original.props?.style,
            ...(virtualRow
              ? {
                  transform: `translateY(${virtualRow.start}px)`,
                  minHeight: `${virtualRow.size}px`,
                }
              : {}),
          }}
          {...onRow?.(row.original)}
          className={cx(
            classes.row,
            rowClassName,
            row.original.props?.rowClassName,
            {
              [classes.isMayReturn]: isMayReturn,
              [classes.isArchived]: isArchived && !canReturnFromArchive,
              [classes.isActiveCollapse]: isActiveCollapse && collapsingStrategy === 'customComponent',
              [classes.isDisableCollapseEye]: !isActiveCollapse && isParent?.(rowAttribute),
              [classes.showLastBorderRow]: showCountRowsInfo,
              [classes.rowError]: errorsIds?.includes(rowId),
              [classes.rowDisabled]: disabledIds?.includes(rowId),
              [classes.rowVirtual]: virtualRow,
            },
            classNameExpanded && { [classNameExpanded]: isExpanded },
            rowSelectedClassName && { [rowSelectedClassName]: isSelected },
            getClassesForId(classesRows, rowId),
          )}
          data-index={y}
          onClick={() => {
            if (isCollapsing && collapsingStrategy === 'customComponent') {
              toggleCollapse(rowId)
            }
          }}
          ref={rowRef}
        >
          {showDraggableElement && (
            <Td className={classes.draggableTd} isDraggable>
              <MoveElementIcon />
            </Td>
          )}
          {selectPosition === 'left' && renderSelect(row)}
          {showStatus && <StatusCell status={rowData._status} />}

          {isCollapsing && collapsingStrategy !== 'toggle' && (
            <td className={classes.arrowTd}>
              <span
                className={cx(classes.arrowCollapsedWrap, { [classes.isLastLevel]: isMaxLevel })}
                data-collapsed-arrow={isMaxLevel ? undefined : true}
                onClick={() => {
                  if (isCollapsing && collapsingStrategy === 'tree') {
                    toggleCollapse(rowId)
                  }
                }}
                ref={(el: any) => {
                  if (el) {
                    el.expand = () => toggleCollapse(rowId, true)
                    el.collapse = () => toggleCollapse(rowId, false)
                  }
                }}
                style={{ marginLeft: 8 * rowData._level }}
              >
                <ArrowRightIcon
                  className={cx(classes.arrowCollapsed, {
                    [classes.disabled]: !isActiveCollapse,
                    [classes.arrowOpened]: isExpanded,
                  })}
                />
              </span>
            </td>
          )}
          {isCollapsing && collapsingStrategy === 'toggle' && (
            <EyeToggler
              hoverParent={hoverParent}
              isActiveCollapse={isActiveCollapse}
              isMouseOutParent={isMouseOutParent}
              isMouseOverParent={isMouseOverParent}
              isParent={isParent}
              nextRow={rows[y + 1]}
              prevRow={rows[y - 1]}
              row={row}
              toggleCollapse={toggleCollapse}
            />
          )}

          {cells.map((cell, x) => {
            const { columnProps, tdProps } = getColumnProps(cell.column)
            const props = mergeObjects<RowProps>(
              deleteUndefined({ ...(row.original.props ?? {}), style: undefined }),
              tdProps,
              getCellProps(row.original, cell.column.id),
            )
            let borderBottomLeftRadius
            let borderBottomRightRadius
            if (!showFooterInternal && y === rows.length - 1) {
              const radius = getBorderRadius(columnProps.style?.borderRadius)
              borderBottomLeftRadius = radius.borderBottomLeftRadius
              borderBottomRightRadius = radius.borderBottomRightRadius
            }

            const isSaveButton = cell.column.id === 'name' && isSaveEditByButton

            const content = flexRender(
              (props.isEditable || isSaveButton ? TableCell : cell.column.columnDef.cell) as ColumnDefTemplate<
                CellContext<TData, unknown>
              >,
              {
                ...cell.getContext(),
                getValue: (() =>
                  getValueCell(row.original, cell.column.accessorFn, row.index, cell.column.id)) as Getter<unknown>,
                getRowData: () => rowData,
                getCellProps: () =>
                  ({
                    ...props,
                    dashIfEmpty: !!dashIfEmpty,
                    editableMatrix: {
                      hasTop: matrix.getTop(x, y),
                      hasBottom: matrix.getBottom(x, y),
                      hasLeft: matrix.getLeft(x, y),
                      hasRight: matrix.getRight(x, y),
                    },
                    getType,
                  }) as NoInfer<any>,
                getMeta: () => getMeta?.(),
              },
            )

            const onClick = (event: MouseEvent<HTMLTableCellElement>, fn?: (...args: any[]) => any) => {
              if (!tdProps.disabledRowClick) {
                const target = event.target as any
                const td = (
                  target.tagName.toLowerCase() === 'td' ? event.target : target.closest('td')
                ) as HTMLTableCellElement | null
                const tr = (
                  target.tagName.toLowerCase() === 'tr' ? event.target : target.closest('tr')
                ) as HTMLTableRowElement | null
                return fn?.(getRowId(row), cell.column.id, row.original, { tr, td })
              }
            }

            return (
              <td
                className={cx(props.className, {
                  [classes.smallPadding]: isSaveButton,
                  [classes.divider]: row.original.props?.divider,
                  [classes.clickable]: !!onRowClick,
                })}
                data-disabled={props.disabled}
                data-editable={props.isEditable}
                data-selected={isEqual(selectableCellParams?.get?.(), { x, y }) ? 'true' : undefined}
                data-type={props.type}
                key={row.original.props?.index ? `${row.original.props.index}-${cell.column.id}` : cell.id}
                onClick={(event) => {
                  onClick(event, onRowClick)
                  if ((cell.column.columnDef.meta as ColumnMeta)?.isSelectable) {
                    const thisTd = (event.target as HTMLElement)?.closest('td')
                    if (thisTd?.getAttribute('data-selected') !== 'true') {
                      thisTd?.setAttribute('data-selected', 'true')
                      selectableCellParams?.set?.(x, y)
                    }
                  } else {
                    selectableCellParams?.clear()
                  }
                }}
                onContextMenu={(event) => {
                  if (!onRowContextMenu) {
                    return
                  }
                  const result = onClick(event, onRowContextMenu)
                  if (result) {
                    const node = result.component ?? result
                    const wrapper = result.wrapper
                    event.preventDefault()
                    initContextMenu?.(node, event.pageX, event.pageY, wrapper)
                  }
                }}
                style={{
                  ...props.style,
                  borderBottomLeftRadius,
                  borderBottomRightRadius,
                  textAlign: props.isNumberCellAlignRight ? 'right' : props.style?.textAlign,
                  whiteSpace: props.isNumberCellAlignRight ? 'nowrap' : props.style?.whiteSpace,
                  width: columnResizable
                    ? x === cells.length - 1 && sizeLastColumn
                      ? sizeLastColumn
                      : cell.column.getSize() -
                        (isCollapsing && collapsingStrategy === 'tree' && x === 0 ? 8 * rowData._level : 0) +
                        (isDraggable && !showDraggableElement && x === 0 ? 28 : 0)
                    : props.style?.width,
                }}
              >
                {isParent?.(rowAttribute) && !isActiveCollapse ? null : content}
              </td>
            )
          })}

          {selectPosition === 'right' && renderSelect(row)}

          {!!data?.length &&
            deletable &&
            (!isArchived || (isArchived && canReturnFromArchive)) &&
            !isMayReturn &&
            !row.original.props?.isNew && (
              <SelectTd key="delete">
                <Tooltip
                  className={cx(classes.delete, { [classes.active]: isDeleteActive })}
                  classNameContainer={classes.deleteCont}
                  disabled={isDeleteActive && !forceDeleteTooltip}
                  isHovered
                  onClick={() => isDeleteActive && onDelete?.(rowId)}
                  tooltip={deleteInactiveTooltip}
                >
                  <DeleteIcon />
                </Tooltip>
              </SelectTd>
            )}

          {!!data?.length &&
            (selectable || deletable) &&
            isArchived &&
            !canReturnFromArchive &&
            !row.original.props?.isNew && (
              <SelectTd key="archived">
                <div className={classes.archivedCont}>
                  <div className={classes.return} />
                </div>
              </SelectTd>
            )}
          {!!data?.length && (selectable || deletable) && isMayReturn && !row.original.props?.isNew && (
            <SelectTd key="return">
              <div className={classes.archivedCont}>
                <div className={cx(classes.return, classes.show)} onClick={() => rowId && onReturn?.(rowId)}>
                  <ArrowBackIcon />
                </div>
              </div>
            </SelectTd>
          )}
          {row.original.props?.isNew && (
            <SelectTd key="deleteNew">
              <div
                className={cx(classes.deleteNew, deleteIconClassName)}
                onClick={() => onRemoveNew?.(row.original.props?.index)}
              >
                <CloseIcon />
              </div>
            </SelectTd>
          )}
          {!deletable &&
            !selectable &&
            !!createdRows?.length &&
            !row.original.props?.isNew &&
            (isMayReturn || (isArchived && !canReturnFromArchive)) && (
              <SelectTd key="empty">
                <div className={classes.archivedCont}>&nbsp;</div>
              </SelectTd>
            )}
          {!deletable &&
            !selectable &&
            !!createdRows?.length &&
            !row.original.props?.isNew &&
            !isMayReturn &&
            !isArchived && <SelectTd key="empty">&nbsp;</SelectTd>}
        </Tr>

        {isActiveCollapse && !!CollapsingComponent && (
          <tr
            className={cx(classes.collapsedRow, classNameCollapsedRow, getClassesForId(classesRows, rowId), {
              [classes.collapsedRowOpened]: isExpanded,
            })}
          >
            <td className={classes.arrowTd} />
            <td colSpan={colSpan - 1}>
              <SlideToggle open={isExpanded}>
                <div className={classes.slide}>
                  <CollapsingComponent row={rowData} />
                </div>
              </SlideToggle>
            </td>
          </tr>
        )}
      </Fragment>
    )
  }

  return resultRows
}
