import { queryClient } from 'config/queryClient'
import { Path } from 'interfaces/router.interfaces'
import { PermissionsData } from 'modules/user/api'
import { UserQueries } from 'modules/user/queries/types'

export const getStartDemandPage = (): Path => {
  const permissions = (queryClient.getQueryData([UserQueries.Permissions]) ?? {}) as PermissionsData
  if (permissions.viewSelectionsDashboard) {
    return '/demand/selections'
  }
  if (permissions.viewForecastUploadDashboard) {
    return '/demand/forecast-data-upload'
  }
  if (permissions.viewMasterdataUploadDashboard) {
    return '/demand/master-data-upload'
  }
  if (permissions.viewMasterdataEditDashboard) {
    return '/demand/data-handling'
  }
  if (permissions.viewRealignmentDashboard) {
    return '/demand/realignment'
  }
  if (permissions.readJobsDbDemand) {
    return '/jobs'
  }
  return '/demand/selections'
}
