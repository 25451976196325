import { devtools } from 'config/devtools'
import { ExcelStateSelectedColumns } from 'interfaces/excelTable.interfaces'
import { isEqual } from 'lodash'
import { defaultSelectedColumns } from 'ui/ExcelTable/constants/selectedColumns'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export const useSelectedColumns = create<ExcelStateSelectedColumns>()(
  subscribeWithSelector(
    devtools(
      (set) => ({
        selectedColumns: defaultSelectedColumns,
        clearSelectedColumns: () =>
          set({
            selectedColumns: defaultSelectedColumns,
          }),
        setSelectedColumns: (selectedColumns) =>
          set(
            (prev) => {
              if (isEqual(prev.selectedColumns, selectedColumns)) {
                return prev
              }
              return { selectedColumns }
            },
            undefined,
            'setSelectedColumns',
          ),
      }),
      {
        store: 'forecastTableSelectedColumns',
      },
    ),
  ),
)
