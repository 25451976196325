import { FC, JSX, ReactElement, useEffect } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useAuthenticated, useBroadcastAuth } from 'modules/auth/handlers'

/**
 * Redirects on home page if user is logged in
 * @param Component Component
 * @returns Component
 */
export function withoutAuth<T = any>(Component: FC<T>) {
  return (props: JSX.IntrinsicAttributes & T): ReactElement => {
    const navigate = useNavigate()

    const { authenticated } = useAuthenticated()
    const { loading } = useBroadcastAuth()

    useEffect(() => {
      if (authenticated) {
        navigate({
          to: '/',
        })
      }
    }, [authenticated])

    return <Component {...props} loading={loading} />
  }
}
