import { FC, useEffect, useState } from 'react'

import cx from 'clsx'
import { useTranslate } from 'config/i18n'
import { updateSystemMinutes } from 'constants/app'
import { useUpdatingSystemHandler } from 'modules/systemUpdating/handlers'
import { Button, ButtonSizes } from 'ui/Button'
import { Modal } from 'ui/Modal'
import { OverlayingModal } from 'ui/Modal/OverlayingModal'

import classes from './UpdatingSystem.module.scss'

export const UpdatingSystem: FC = () => {
  const [isOpened, setIsOpened] = useState<boolean | null>(false)

  const translate = useTranslate()

  const { isFutureUpdating, isUpdating } = useUpdatingSystemHandler()

  const onClose = () => {
    if (isFutureUpdating) {
      setIsOpened(null)
    }
  }

  useEffect(() => {
    if (isOpened !== null) {
      setIsOpened(isFutureUpdating)
    }
  }, [isFutureUpdating])

  useEffect(() => {
    setIsOpened(isUpdating)
  }, [isUpdating])

  return (
    <OverlayingModal disabledScroll isOpened={!!isOpened} onClose={onClose}>
      <Modal
        className={cx(classes.popup, { [classes.small]: !isFutureUpdating })}
        classNameClose={cx(classes.close, { [classes.closable]: isFutureUpdating })}
        classNameTitle={classes.title}
        onClose={onClose}
        title={translate(isFutureUpdating ? 'systemWillBeUpdate' : 'systemUpdating', { count: updateSystemMinutes })}
      >
        {isFutureUpdating && (
          <Button className={classes.okButton} onClick={onClose} size={ButtonSizes.Medium}>
            {translate('ok')}
          </Button>
        )}
      </Modal>
    </OverlayingModal>
  )
}
