import { createEndpoint } from 'config/api'
import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/account'
const personalInfoUrl = '/personal-info'

interface Endpoints {
  getInfo: NoParamEndpointConstructor
  getPermissions: NoParamEndpointConstructor
  updateInfo: NoParamEndpointConstructor
}

export const userEndpoints: Endpoints = {
  getInfo: () => createEndpoint(`${baseUrl}${personalInfoUrl}`),
  getPermissions: () => createEndpoint(`${baseUrl}/permissions`),
  updateInfo: () => createEndpoint(`${baseUrl}${personalInfoUrl}`),
}
