import { FC } from 'react'

import { useTranslate } from 'config/i18n'
import { EditModeMenuWidgetBlock } from 'jume/editMode/components/EditModeMenuWidgetBlock/EditModeMenuWidgetBlock'
import { useUpdateWidgetsLocal } from 'jume/editMode/handlers'
import { useWidgetsFiltered } from 'jume/editMode/handlers/useWidgetsFiltered'

import classes from './EditModeMenuWidgets.module.scss'

interface EditModeMenuWidgetsProps {
  className?: string
}

export const EditModeMenuWidgets: FC<EditModeMenuWidgetsProps> = ({ className }) => {
  const { widgetsFiltered } = useWidgetsFiltered()
  const { activeWidgetsCount } = useUpdateWidgetsLocal()

  const translate = useTranslate()

  return (
    <div className={className}>
      <div className={classes.title}>
        {translate(
          widgetsFiltered.length === activeWidgetsCount
            ? 'editMode.menu.noWidgetsToDragMessage'
            : 'editMode.menu.dragHelperMessage',
        )}
      </div>

      <div className={classes.widgets}>
        {widgetsFiltered
          .filter((item) => !item.order)
          .map((item) => (
            <EditModeMenuWidgetBlock item={item} key={item.id} />
          ))}
      </div>
    </div>
  )
}
