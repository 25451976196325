import { useQuery } from '@tanstack/react-query'
import { useTranslate } from 'config/i18n'
import { Permission, PermissionsData } from 'modules/user/api/interfaces'
import { userApi } from 'modules/user/api/methods'
import { Permissions } from 'modules/user/constants/permissions'
import { checkPermission } from 'modules/user/helpers/checkPermission'
import { useProfile } from 'modules/user/queries/useProfile'

import { UserQueries } from './types'

export const usePermissions = () => {
  const { profile } = useProfile()
  const translate = useTranslate()

  const enabled = !!profile?.id

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: [UserQueries.Permissions],
    queryFn: userApi.getPermissions,
    enabled,
    meta: {
      error: translate('errorsNoData.permissions'),
    },
    structuralSharing: (_, newData) => {
      const permissions = Object.keys(Permissions).reduce(
        (acc, key) => ({
          ...acc,
          [key]: checkPermission(newData as Permission[], Permissions[key as keyof typeof Permissions]),
        }),
        {} as PermissionsData,
      )
      permissions.accessAddDataSource =
        permissions.addDataSourceVersion &&
        permissions.addActualShipment &&
        permissions.addPrice &&
        permissions.addTarget
      return permissions
    },
  })

  return {
    permissions: (data ?? {}) as PermissionsData,
    isLoadingPermissions: isLoading,
    isSuccessPermissions: isSuccess,
  }
}
