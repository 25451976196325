import { useMutation } from '@tanstack/react-query'
import { useLastSession } from 'modules/session/store'
import { ProfileUpdateData, userApi } from 'modules/user/api'
import { useClearAppCash, useProfile } from 'modules/user/queries'

export const useSwitchProject = () => {
  const { profile } = useProfile()
  const { clearAppCash } = useClearAppCash()
  const setActiveProjectId = useLastSession((state) => state.setActiveProjectId)

  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: async (newProjectId: number) => {
      if (profile) {
        const data: ProfileUpdateData = {
          activeProjectId: newProjectId,
        }
        return userApi.updateInfo(data)
      }
    },
    onSuccess: (_, newProjectId) => {
      clearAppCash(false, newProjectId)
      setActiveProjectId(newProjectId)
    },
  })

  return {
    switchProject: (newProjectId: number) => mutate(newProjectId),
    isLoadingSwitchingProject: isPending,
    isErrorSwitch: isError,
    switchError: error,
    clearErrorSwitch: reset,
  }
}
