import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseDisableNavigation } from './types'

export const useDisableNavigation = create<UseDisableNavigation>()(
  devtools(
    (set) => ({
      navigationDisabled: false,
      setNavigationDisabled: (value) => set({ navigationDisabled: value }),
      clearNavigationDisabled: () => set({ navigationDisabled: false }),
    }),
    {
      store: 'optiNavigationDisabled',
    },
  ),
)
