import { useEffect, useRef } from 'react'

import { Subscription } from 'centrifuge'
import { getCentrifuge } from 'config/centrifuge'
import { sendWsTracing } from 'config/tracing'
import { Streams } from 'constants/streams'
import { filesize } from 'filesize'
import { useProfile } from 'modules/user/queries'
import { toCamelCase } from 'packages/helper'

export interface UseWsOptions {
  enabled?: boolean
  onPublish?: Record<string | 'default', (data: any, response?: any) => void>
  tracing?: Record<string, string | number | null>
  stream?: Streams
  module?: string
}

export const useWs = (channel: string, options?: UseWsOptions) => {
  const { profile } = useProfile()
  const refSubscription = useRef<Subscription | null>(null)
  const enabled = !!profile && (options?.enabled ?? true)

  useEffect(() => {
    const centrifuge = getCentrifuge()
    if (!centrifuge) {
      return
    }
    if (!enabled) {
      return () => {
        if (refSubscription.current) {
          refSubscription.current.unsubscribe()
          centrifuge.removeSubscription(refSubscription.current)
        }
      }
    }
    const sub = centrifuge.newSubscription(channel)
    refSubscription.current = sub
    sub.subscribe()

    sub.on('publication', function (ctx) {
      if (ctx.data === undefined) {
        return
      }
      options?.onPublish?.[ctx.data?.type || 'default']?.(ctx.data?.data ?? ctx.data?.value, toCamelCase(ctx.data))
      const data = toCamelCase(ctx.data)
      sendWsTracing({
        data,
        channel,
        tracing: options?.tracing,
        userId: profile?.id,
        stream: options?.stream,
        module: options?.module,
        size: filesize(new Blob([JSON.stringify(data)]).size),
      })
    })

    if (centrifuge.state !== 'connected') {
      centrifuge.connect()
    }

    return () => {
      sub.unsubscribe()
      centrifuge.removeSubscription(sub)
    }
  }, [enabled])
}
