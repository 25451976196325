import { FC, useState } from 'react'

import { useMatchRoute } from '@tanstack/react-router'
import EditModeIcon from 'assets/images/edit-mode.svg?react'
import LogoutIcon from 'assets/images/logout.svg?react'
import ProfileIcon from 'assets/images/user.svg?react'
import cx from 'clsx'
import { ConfirmModal } from 'components/modals/ConfirmModal'
import { useTranslate } from 'config/i18n'
import { IS_JUME, IS_LOWCODE_ENABLED } from 'constants/env'
import { useLogout } from 'hooks/useLogout'
import { useModal } from 'hooks/useModal'
import { useUnsavedChangesState } from 'hooks/useUnsavedChangesState'
import { CognitiveChat } from 'jume/cognitiveChat/components/CognitiveChat'
import { WidgetCategoryTypes } from 'jume/editMode/interfaces'
import { useWidgets } from 'jume/editMode/queries'
import { useOpenEditMode } from 'jume/editMode/store'
import { useAuth } from 'modules/auth/store'
import { useProject } from 'modules/projects/queries'
import { useDisableNavigation } from 'opti/scenarioShared/store'

import classes from './ProfileLinks.module.scss'

interface ProfileLinkProps {
  className?: string
  extended?: boolean
}

export const ProfileLinks: FC<ProfileLinkProps> = ({ className, extended }) => {
  const [loading, setLoading] = useState(false)
  const translate = useTranslate()
  const matchRoute = useMatchRoute()

  const [openConfirm, onOpenConfirm, onCloseConfirm] = useModal()
  const { logout } = useLogout()
  const openEditMode = useOpenEditMode((state) => state.open)
  const toggleEditMode = useOpenEditMode((state) => state.toggle)

  const { navigationDisabled } = useDisableNavigation()

  const { widgets } = useWidgets(WidgetCategoryTypes.Forecast)
  const { project } = useProject()

  const isForecastPage = !!matchRoute({ to: '/jume/project/$projectId/forecast' })

  const { setIsLogoutConfirm, setIsLogout } = useAuth()
  const unsavedChanges = useUnsavedChangesState((state) => state.unsavedChanges)
  const unsavedItem = unsavedChanges.find((item) => item.isDirty)
  const isDirty = !!unsavedItem
  const onClickLogout = () => {
    if (isDirty) {
      const isConfirm = window.confirm(translate('unsavedChangesWarning'))
      setIsLogoutConfirm(isConfirm)
      setIsLogout(true)
      if (isConfirm) {
        setLoading(true)
        logout()
      }
    } else {
      setLoading(true)
      logout()
    }
  }

  return (
    <div className={cx(classes.wrap, className, { [classes.extended]: extended, [classes.topIndex]: openEditMode })}>
      {extended ? (
        <span className={classes.exit} onClick={onOpenConfirm}>
          <LogoutIcon className={cx(classes.icon, { [classes.disable]: navigationDisabled })} />
          {translate('logout')}
        </span>
      ) : (
        <>
          {isForecastPage && !!widgets?.length && IS_LOWCODE_ENABLED && (
            <EditModeIcon className={cx(classes.icon, { [classes.active]: openEditMode })} onClick={toggleEditMode} />
          )}
          {IS_JUME && project?.cognitiveChatEnabled && <CognitiveChat />}
          <ProfileIcon className={cx(classes.icon, classes.disable)} />
          <LogoutIcon className={cx(classes.icon, { [classes.disable]: navigationDisabled })} onClick={onOpenConfirm} />
        </>
      )}

      <ConfirmModal
        closeAfterSuccess
        danger
        isOpened={openConfirm}
        loading={loading}
        okText={translate('logout')}
        onClose={onCloseConfirm}
        onOk={onClickLogout}
        title={translate('sureLogout')}
      />
    </div>
  )
}
