import { Centrifuge, UnauthorizedError } from 'centrifuge'
import { refreshTokens } from 'config/auth/refreshTokens'
import storage from 'config/storage'
import { WS_URL } from 'constants/env'
import { ApiError } from 'packages/api'

let centrifuge: Centrifuge

export const getCentrifuge = () => {
  if (centrifuge) {
    return centrifuge
  }
  if (storage.getToken()) {
    centrifuge = new Centrifuge(WS_URL, {
      token: storage.getToken(),
      getToken: () =>
        refreshTokens()
          .then(({ tokens }) => tokens.access)
          .catch((error) => {
            if ((error as ApiError)?.code === 'refreshError') {
              storage.clearToken()
            }
            throw new UnauthorizedError('incorrect token')
          }),
    })
    return centrifuge
  } else {
    return null
  }
}
