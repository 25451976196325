import { useCallback } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { useUnsavedChangesState } from 'hooks/useUnsavedChangesState'
import { useLastSession } from 'modules/session/store'
import { useClearAppCash } from 'modules/user/queries'

export const useLogoutClear = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const clearUnsavedChanges = useUnsavedChangesState((state) => state.clear)
  const setIsNavigatedToLastPage = useLastSession((state) => state.setIsNavigated)
  const { clearAppCash } = useClearAppCash()

  const logoutClear = useCallback(() => {
    storage.clearToken()
    storage.clearAfterLogout()
    setAxiosAuthorizationToken(null)
    navigate({ to: '/login' })
    queryClient.removeQueries()
    clearUnsavedChanges()
    clearAppCash(true)
    setIsNavigatedToLastPage(false)
  }, [queryClient])

  return { logoutClear }
}
