import { FC } from 'react'

import { createRouter, RouterProvider } from '@tanstack/react-router'
import { compose } from 'hocs'
import { withProviders } from 'hocs/withProviders'

import { routeTree } from './routeTree.gen'

export const router = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const App: FC = () => <RouterProvider router={router} />

export default compose(App, withProviders)
