import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/jume/')({
  component: createPage({
    titleKey: 'titles.main',
    lazyImport: {
      fn: () => import('jume/pulse/components/PulseBlock'),
      name: 'PulseBlock',
    },
  }),
})
