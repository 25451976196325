import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/replen-optimizer/replen-warehouses')({
  component: createPage({
    titleKey: 'titles.replenWarehouses',
    lazyImport: {
      fn: () => import('jume/replenScenarios/components/ReplenWarehouses'),
      name: 'ReplenWarehouses',
    },
  }),
})
