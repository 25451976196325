import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { storageAuthName } from 'constants/storage'
import { useTwoFactorAuth } from 'modules/auth/store'
import { useLocalStorage, useSessionStorage } from 'usehooks-ts'

export const useAuthenticated = () => {
  const [authLS] = useLocalStorage<{ value: boolean } | null>(storageAuthName, { value: false })
  const [authSession] = useSessionStorage<{ value: boolean } | null>(storageAuthName, { value: false })
  const authenticated = authLS?.value || authSession?.value
  if (authenticated) {
    setAxiosAuthorizationToken(storage.getToken())
    useTwoFactorAuth.persist.clearStorage()
  }

  return { authenticated }
}
