import { createEndpoint } from 'config/api'
import { NoParamEndpointConstructor } from 'interfaces/api.interfaces'

interface Endpoints {
  getJobLockStatus: NoParamEndpointConstructor
}

export const jobLockEndpoints: Endpoints = {
  getJobLockStatus: () => createEndpoint('/demand/job-lock-status'),
}
