import storage from 'config/storage'

export const getTokens = () => {
  const access = storage.getToken()
  const refresh = storage.getRefresh()
  return access && refresh
    ? {
        access,
        refresh,
      }
    : undefined
}
