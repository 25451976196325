import { devtools } from 'config/devtools'
import { CfrScenarioTabsCodes } from 'opti/cfrDataSources/interfaces/common'
import { create } from 'zustand'

import { UseCfrScenarioTab } from './types'

export const useCfrScenarioTab = create<UseCfrScenarioTab>()(
  devtools(
    (set) => ({
      currentTab: CfrScenarioTabsCodes.Input,
      setCurrentTab: (currentTab) => set({ currentTab }),
    }),
    {
      store: 'currentCfrScenarioTab',
    },
  ),
)
