import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/prgm/jobs-status')({
  component: createPage({
    titleKey: 'titles.jobsStatus',
    lazyImport: {
      fn: () => import('modules/jobs/components/JobsStatusBlock'),
      name: 'JobsStatusBlock',
    },
  }),
})
