import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { UseLastWorkflowDate } from './types'

export const useLastWorkflowDate = create<UseLastWorkflowDate>()(
  persist(
    (set) => ({
      date: null,
      setDate: (date) => set({ date: date.toISOString() }),
    }),
    {
      name: 'lastWorkflowDate',
    },
  ),
)
