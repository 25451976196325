import { FC, JSX, ReactElement } from 'react'

import { UpdatingSystem } from 'modules/systemUpdating/components/UpdatingSystem'

export function withUpdatingSystem<T = any>(Component: FC<T>) {
  return (props: JSX.IntrinsicAttributes & T): ReactElement => (
    <>
      <Component {...props} />
      <UpdatingSystem />
    </>
  )
}
