import { devtools } from 'config/devtools'
import { IS_DANON, IS_JUME } from 'constants/env'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export interface UseLastSession {
  page?: string
  profileId?: number
  activeProjectId?: number
  isNavigated: boolean
  setIsNavigated: (a: boolean) => void
  setPage: (a: string) => void
  setProfileId: (a?: number) => void
  setActiveProjectId: (a?: number) => void
}

export const useLastSession = create<UseLastSession>()(
  devtools(
    persist(
      (set) => ({
        page: '',
        isNavigated: false,
        setIsNavigated: (isNavigated) => {
          if (IS_DANON) {
            return
          }
          set({ isNavigated })
        },
        setPage: (page) => {
          if (!IS_JUME) {
            return
          }
          set({ page })
        },
        setProfileId: (profileId) => {
          if (!IS_JUME) {
            return
          }
          set({ profileId })
        },
        setActiveProjectId: (activeProjectId) => {
          if (!IS_JUME) {
            return
          }
          set({ activeProjectId })
        },
      }),
      {
        name: 'lastSession',
      },
    ),
    {
      store: 'lastSession',
    },
  ),
)
