import { FC, PropsWithChildren, ReactNode } from 'react'

import cx from 'clsx'

import classes from './NavTitle.module.scss'

interface NavTitleProps {
  isActive?: boolean
  className?: string
  icon?: ReactNode
  onClick?: () => void
}

export const NavTitle: FC<PropsWithChildren<NavTitleProps>> = ({ icon, className, isActive, onClick, children }) => {
  if (!children && !icon) {
    return null
  }

  return (
    <div className={cx(classes.wrap, className, { [classes.active]: isActive })} onClick={onClick}>
      {!!icon && <div className={classes.icon}>{icon}</div>}
      <span>{children}</span>
    </div>
  )
}
