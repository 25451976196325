import { useEffect } from 'react'

import { useLang } from 'config/i18n'
import { Languages } from 'interfaces/translations.interfaces'
import { isOpti } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'

export const useSetLangByProject = (languageCode?: Languages) => {
  const currentStream = useCurrentStream((state) => state.currentStream)
  const setLang = useLang((state) => state.setLang)

  useEffect(() => {
    if (languageCode && currentStream && isOpti(currentStream)) {
      setLang(languageCode)
    }
  }, [currentStream, languageCode])
}
