import { FC } from 'react'

import InfoIcon from 'assets/images/large-info.svg?react'
import { TextModal } from 'components/modals/TextModal'
import { useModal } from 'hooks/useModal'

import classes from './CognitiveChat.module.scss'

export const CognitiveChat: FC = () => {
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useModal()

  return (
    <>
      <InfoIcon className={classes.icon} onClick={onOpenConfirm} />
      <TextModal className={classes.modal} isOpened={openConfirm} onClose={onCloseConfirm}>
        <iframe allow="microphone" className={classes.iframe} src="https://144aea227d9eead0f5.gradio.live" />
      </TextModal>
    </>
  )
}
