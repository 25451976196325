import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { createBlockAGranularitySliceSlice } from './slices/blockAGranularitySlice'
import { createBlockBGranularitySliceSlice } from './slices/blockBGranularitySlice'
import { UseBlockAGranularity } from './types'

export const useCurrentGranularity = create<UseBlockAGranularity>()(
  devtools(
    (...a) => ({
      ...createBlockAGranularitySliceSlice(...a),
      ...createBlockBGranularitySliceSlice(...a),
    }),
    {
      store: 'currentGranularity',
    },
  ),
)
