import { FC } from 'react'

import cx from 'clsx'
import { NavMenuDemand } from 'demand/navMenu/NavMenuDemand'
import { NavMenuDeploy } from 'deploy/navMenu/NavMenuDeploy'
import { NavMenuJume } from 'jume/navMenu/NavMenuJume'
import { isDemand, isDeploy, isJume, isOpti, isPpds, isPrgm } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { NavMenuOpti } from 'opti/navMenu/NavMenuOpti'
import { NavMenuPpds } from 'ppds/navMenu/NavMenuPpds'
import { NavMenuPrgm } from 'prgm/navMenu/NavMenuPrgm'

import classes from './NavMenu.module.scss'

interface NavMenuProps {
  className?: string
}

export const NavMenu: FC<NavMenuProps> = ({ className }) => {
  const currentStream = useCurrentStream((state) => state.currentStream)

  return (
    <nav className={cx(classes.wrap, className)}>
      {isJume(currentStream) && <NavMenuJume />}
      {isOpti(currentStream) && <NavMenuOpti />}
      {isDemand(currentStream) && <NavMenuDemand />}
      {isPrgm(currentStream) && <NavMenuPrgm />}
      {isDeploy(currentStream) && <NavMenuDeploy />}
      {isPpds(currentStream) && <NavMenuPpds />}
    </nav>
  )
}
