import { devtools } from 'config/devtools'
import { SelectionFields, SelectionTime } from 'demand/selections/api'
import { SelectionTabsKeys } from 'demand/selections/interfaces/common'
import { generateErrorsState, UseErrors, ValidationError } from 'modules/errorsState'
import { AttributeFilterData, AttributeFilterItem } from 'modules/filters/attribute/api'
import { ValueFilterData } from 'modules/filters/value/api'
import { create } from 'zustand'

export type SelectionError = ValidationError<
  SelectionTabsKeys | 'all',
  | 'tab'
  | 'uom'
  | 'attributeFilter'
  | 'csl_level_highlight'
  | keyof SelectionTime
  | keyof SelectionFields
  | keyof AttributeFilterData
  | keyof ValueFilterData
  | keyof AttributeFilterItem
>
export type UseSelectionErrors = UseErrors<SelectionError>

export const useSelectionErrors = create<UseSelectionErrors>()(
  devtools((set, get) => generateErrorsState<SelectionError>(set, get), {
    store: 'selectionErrors',
  }),
)
