import { axios } from 'config/axios'
import { Streams } from 'constants/streams'
import { GetOneResponse } from 'interfaces/api.interfaces'
import { WidgetCategoryTypes } from 'jume/editMode/interfaces'

import { editModeEndpoints } from './endpoints'
import { WidgetsList, WidgetsListForm } from './interfaces'

const getEditModeSettings = async (category?: WidgetCategoryTypes): GetOneResponse<WidgetsList> => {
  const { data } = await axios.request<WidgetsList>({
    method: 'GET',
    ...editModeEndpoints.getEditModeSettings(),
    params: {
      categoryWidget: category,
    },
    tracing: {
      stream: Streams.Jume,
      module: 'editMode',
      methodName: 'getEditModeSettings',
    },
  })
  return data
}

const updateEditModeSettings = async (form: WidgetsListForm): Promise<void> => {
  await axios.request({
    method: 'PATCH',
    ...editModeEndpoints.updateEditModeSettings(),
    data: form,
    tracing: {
      stream: Streams.Jume,
      module: 'editMode',
      methodName: 'updateEditModeSettings',
    },
  })
}

export const editModeApi = {
  getEditModeSettings,
  updateEditModeSettings,
}
