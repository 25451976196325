import { devtools } from 'config/devtools'
import { FiltersForm } from 'prgm/shared/interfaces'
import { create } from 'zustand'

interface UseSearchFilters {
  filters: FiltersForm
  searchEnabled: boolean
  setFilters: (filters: FiltersForm) => void
  resetFilters: () => void
  clear: () => void
}

export const useSearchFilters = create<UseSearchFilters>()(
  devtools(
    (set) => ({
      filters: {},
      searchEnabled: false,
      setFilters: (filters: FiltersForm) =>
        set(() => {
          const newFilters = { ...filters }
          if (!newFilters.storeStartDate) {
            delete newFilters.storeStartDate
          }
          if (!newFilters.storeEndDate) {
            delete newFilters.storeEndDate
          }

          return {
            filters: { ...newFilters },
            searchEnabled: true,
          }
        }),
      resetFilters: () => set({ filters: {} }),
      clear: () => set({ filters: {}, searchEnabled: false }),
    }),
    {
      name: 'prgmSearchFilters',
    },
  ),
)

export const useTestSearchFilters = () =>
  useSearchFilters((state) => {
    const { filters, setFilters, clear, searchEnabled, resetFilters } = state
    return {
      filters,
      setFilters,
      clear,
      searchEnabled,
      resetFilters,
    }
  })
