import { FC, lazy, Suspense, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

import { I18NProvider } from '@ayub-begimkulov/i18n'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Outlet, useLocation, useNavigate } from '@tanstack/react-router'
import { isLoginPage } from 'config/auth/isLoginPage'
import { i18n, useReadyLang } from 'config/i18n'
import { queryClient } from 'config/queryClient'
import { IS_DEV_MODE } from 'constants/env'
import { toastsProps } from 'constants/toasts'
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { useAuthenticated, useBroadcastAuth } from 'modules/auth/handlers'
import { useLastSession } from 'modules/session/store'
import { Loader, LoaderTypes } from 'ui/Loader'

const TanStackRouterDevtools = IS_DEV_MODE
  ? lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )
  : () => null

export const RootLayout: FC = () => {
  const { readyI18n } = useReadyLang()
  const { authenticated } = useAuthenticated()
  const Layout = isLoginPage(location) || !authenticated ? AuthLayout : MainLayout
  const navigate = useNavigate()
  const { ready } = useBroadcastAuth()
  const pathname = useLocation({
    select: (location) => location.pathname,
  })
  const setLastPage = useLastSession((state) => state.setPage)

  useEffect(() => {
    if (ready && !authenticated) {
      setLastPage(pathname)
      navigate({
        to: '/login',
      })
    }
  }, [ready, authenticated])

  return (
    <I18NProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          {!readyI18n ? <Loader type={LoaderTypes.SpinnerCenter} /> : <Outlet />}
          <ToastContainer {...toastsProps} />
          <ReactQueryDevtools initialIsOpen={false} position="bottom" />
          <Suspense>
            <TanStackRouterDevtools />
          </Suspense>
        </Layout>
      </QueryClientProvider>
    </I18NProvider>
  )
}
