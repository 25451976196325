import { FC, memo, ReactNode, useEffect, useRef, useState } from 'react'

import { useSafeState, useThrottleEffect } from 'ahooks'
import cx from 'clsx'

import classes from './SlideToggle.module.scss'

export enum SlideToggleTypes {
  Default = 'default',
  NoTransform = 'noTransform',
}

interface SlideToggleProps {
  className?: string
  classNameWrap?: string
  type?: SlideToggleTypes
  open?: boolean
  duration?: number
  children?: ReactNode
}

export const SlideToggle: FC<SlideToggleProps> = memo(
  ({ className, children, classNameWrap, type = SlideToggleTypes.Default, open = true, duration = 200 }) => {
    const [openInternal, setOpenInternal] = useSafeState(true)
    const [height, setHeight] = useSafeState<number | 'auto'>('auto')
    const delay = 20
    let ready = false

    const ref = useRef<HTMLDivElement>(null)
    const [isFirst, setIsFirst] = useState(true)

    useThrottleEffect(
      () => {
        let s1: NodeJS.Timeout
        let s2: NodeJS.Timeout
        if (!isFirst && ref.current && (height === 'auto' || !ready)) {
          if (!open) {
            setHeight(ref.current.offsetHeight)
            window.requestAnimationFrame(() => setOpenInternal(false))
            window.requestAnimationFrame(() => {
              if (duration) {
                s1 = setTimeout(() => {
                  setHeight('auto')
                  ready = true
                }, duration)
              } else {
                setHeight('auto')
                ready = true
              }
            })
          } else if (ref.current.classList.contains(classes.hide)) {
            ref.current.style.height = 'auto'
            ref.current.classList.remove(classes.hide)
            setHeight(ref.current.offsetHeight)
            ref.current.classList.add(classes.hide)
            window.requestAnimationFrame(() => setOpenInternal(true))
            window.requestAnimationFrame(() => {
              if (duration) {
                s2 = setTimeout(() => {
                  setHeight('auto')
                  ready = true
                }, duration)
              } else {
                setHeight('auto')
                ready = true
              }
            })
          }
        }

        return () => {
          clearTimeout(s1)
          clearTimeout(s2)
        }
      },
      [open, ref.current, isFirst],
      { wait: duration + delay },
    )

    useEffect(() => {
      if (isFirst && !open) {
        setOpenInternal(false)
      }
    }, [isFirst, open])

    useEffect(() => {
      if (ref.current) {
        setIsFirst(false)
      }
    }, [!!ref.current])

    return (
      <div
        className={cx(className, classes[type], { [classes.hide]: !openInternal || (isFirst && !open) })}
        ref={ref}
        style={{ height, transition: `all ${duration}ms ease` }}
      >
        <div className={cx(classes.wrap, classNameWrap)} style={{ transition: `all ${duration}ms ease` }}>
          {children}
        </div>
      </div>
    )
  },
)
