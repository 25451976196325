import { devtools } from 'config/devtools'
import { ValueFilterPartial } from 'modules/filters/value/api'
import { deleteEmptyWithObjects, deleteUndefined } from 'packages/helper'
import { create } from 'zustand'

import { UseValueFiltersParameters } from './types'

export const generateStoreValueFilter = (nameStore: string) =>
  create<UseValueFiltersParameters>()(
    devtools(
      (set) => ({
        searchQueryValueFilter: null,
        setSearchQueryValueFilter: (searchQueryValueFilter) => set({ searchQueryValueFilter }),
        currentValueFilter: null,
        setCurrentValueFilter: (currentValueFilter) => set({ currentValueFilter }),
        unmatchedKF: [],
        setUnmatchedKF: (unmatchedKF) => set({ unmatchedKF }),
        removeUnmatchedKF: (unmatchedKFId) =>
          set((prev) => ({ unmatchedKF: prev.unmatchedKF.filter((item) => item.id !== unmatchedKFId) })),
        clear: () =>
          set({
            searchQueryValueFilter: null,
            currentValueFilter: null,
            unmatchedKF: [],
          }),
        updateTimeGranularity: (granularity) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                time: deleteUndefined({
                  ...(prev.currentValueFilter.time || {}),
                  granularity: granularity || undefined,
                  start: undefined,
                  end: undefined,
                }),
              }) as ValueFilterPartial,
            }
          }),
        updateTimeType: (type) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                time: deleteUndefined({
                  ...(prev.currentValueFilter.time || {}),
                  type: type ?? undefined,
                }),
              }) as ValueFilterPartial,
            }
          }),
        updateTimeFrom: (start) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                time: {
                  ...(prev.currentValueFilter.time || {}),
                  start,
                },
              }) as ValueFilterPartial,
            }
          }),
        updateTimeTo: (end) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                time: {
                  ...(prev.currentValueFilter.time || {}),
                  end,
                },
              }) as ValueFilterPartial,
            }
          }),
        updateKF: (keyFigures) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            const newKeyFigures = keyFigures.map((item) => deleteUndefined({ ...item, order: undefined }))
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                keyFigures: newKeyFigures.length ? newKeyFigures : undefined,
              }) as ValueFilterPartial,
            }
          }),
        updateOperator: (operator) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                operator: operator ?? undefined,
              }) as ValueFilterPartial,
            }
          }),
        updateValue: (value) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                value: value ?? undefined,
              }) as ValueFilterPartial,
            }
          }),
        updatePeriodCriteria: (periodCriteria) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                periodCriteria: periodCriteria ?? undefined,
              }) as ValueFilterPartial,
            }
          }),
        removeKF: (kfId) =>
          set((prev) => {
            if (!prev.currentValueFilter) {
              prev.currentValueFilter = { type: 'value_based' }
            }
            const newKF = prev.currentValueFilter?.keyFigures?.filter((item) => item.id !== kfId)
            return {
              currentValueFilter: deleteEmptyWithObjects({
                ...prev.currentValueFilter,
                keyFigures: newKF?.length ? newKF : undefined,
              }) as ValueFilterPartial,
            }
          }),
        errorCreateValueBasedFilter: null,
        setErrorCreateValueBasedFilter: (errorCreateValueBasedFilter) => set({ errorCreateValueBasedFilter }),
        errorRemoveValueBasedFilter: null,
        setErrorRemoveValueBasedFilter: (errorRemoveValueBasedFilter) => set({ errorRemoveValueBasedFilter }),
      }),
      {
        store: nameStore,
      },
    ),
  )
