import { useQuery } from '@tanstack/react-query'
import { useTranslate } from 'config/i18n'
import { useAuthenticated } from 'modules/auth/handlers'
import { useCurrentStream as getStateCurrentStream } from 'modules/streams/store'
import { Profile } from 'modules/user/api'
import { userApi } from 'modules/user/api/methods'
import { isServerError } from 'packages/api'
import { getError, noRetryOnError } from 'packages/react-query'

import { UserQueries } from './types'

export const useProfile = () => {
  const { authenticated } = useAuthenticated()
  const translate = useTranslate()

  const { data, isPending, error, isFetched, isFetching } = useQuery({
    queryKey: [UserQueries.Profile],
    queryFn: userApi.getProfile,
    enabled: authenticated,
    ...noRetryOnError,
    meta: {
      error: {
        message: translate('errorsNoData.profile'),
        showToast: false,
      },
      onSuccess: (profile: Profile) => {
        const { currentStream, set } = getStateCurrentStream.getState()
        if (!currentStream || !profile.streams?.find((item) => item.id === currentStream.id)) {
          set(profile.streams?.[0] || null)
        }
      },
    },
    refetchInterval: (query) => (isServerError(query.state.error) ? 3000 : undefined),
  })

  return {
    authenticated,
    profileError: getError(error),
    error,
    isLoadingProfile: isPending,
    profile: data,
    scenarioId: data?.scenarioId,
    isFetchedProfile: isFetched,
    isRefetching: isFetching && isFetched,
  }
}
