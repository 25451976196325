export enum Streams {
  Modules = 'modules',
  Utils = 'utils',
  Jume = 'jume',
  Opti = 'opti',
  Demand = 'demand',
  Deploy = 'deploy',
  Ppds = 'ppds',
  Prgm = 'prgm',
}
