import { FC, useState } from 'react'

import { useMatchRoute } from '@tanstack/react-router'
import ArrowIcon from 'assets/images/arrow-bottom.svg?react'
import cx from 'clsx'
import { NavLink } from 'components/template/NavLink'
import { NavTitle } from 'components/template/NavTitle'
import { useTranslate } from 'config/i18n'
import { IS_DEV } from 'constants/env'
import { RecommendationsSwitch } from 'demand/recommendations/components'
import { NavMenuJobs } from 'modules/jobs/components/NavMenuJobs'
import { useJobsPermissionsByStream } from 'modules/jobs/handlers'
import { usePermissions } from 'modules/user/queries'
import { SlideToggle, SlideToggleTypes } from 'ui/SlideToggle'

import classes from './NavMenuDemand.module.scss'

export const NavMenuDemand: FC = () => {
  const matchRoute = useMatchRoute()
  const isSelectionRoute = !!matchRoute({ to: '/demand/selections/$selectionId/forecast' })
  const [isLoadDataOpen, setIsLoadDataOpen] = useState<boolean>(false)
  const translate = useTranslate()
  const { canReadJobsDb, canEditJobsDb } = useJobsPermissionsByStream()
  const { permissions } = usePermissions()

  const toggleMenuOpen = () => setIsLoadDataOpen((prevState) => !prevState)

  return (
    <>
      {permissions.viewSelectionsDashboard && (
        <NavLink className="menuItem" path="/demand/selections">
          {translate('menu.selections')}
        </NavLink>
      )}

      {permissions.viewForecastUploadDashboard && (
        <NavLink className="menuItem" path="/demand/forecast-data-upload">
          {translate('menu.forecastLoad')}
        </NavLink>
      )}

      {(permissions.viewMasterdataUploadDashboard || permissions.viewMasterdataEditDashboard) && (
        <>
          <NavTitle
            className={cx('menuItem', classes.disabled)}
            icon={<ArrowIcon />}
            isActive={isLoadDataOpen}
            onClick={toggleMenuOpen}
          >
            {translate('menu.masterDataDB')}
          </NavTitle>

          <SlideToggle classNameWrap={classes.subMenu} open={isLoadDataOpen} type={SlideToggleTypes.NoTransform}>
            {permissions.viewMasterdataUploadDashboard && (
              <NavLink className="menuItem" path="/demand/master-data-upload">
                {translate('menu.sourceLoading')}
              </NavLink>
            )}
            {permissions.viewMasterdataEditDashboard && (
              <NavLink className="menuItem" path="/demand/data-handling">
                {translate('menu.dataHandling')}
              </NavLink>
            )}
          </SlideToggle>
        </>
      )}

      {permissions.viewRealignmentDashboard && (
        <NavLink className="menuItem" path="/demand/realignment">
          {translate('menu.realignment')}
        </NavLink>
      )}

      {(canReadJobsDb || canEditJobsDb) && <NavMenuJobs />}
      {IS_DEV && isSelectionRoute && <RecommendationsSwitch />}
    </>
  )
}
