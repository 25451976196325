import { devtools } from 'config/devtools'
import { RealignmentTypes } from 'demand/realignment/api'
import { create } from 'zustand'

export interface UseRealignmentTabs {
  currentTab: RealignmentTypes
  setTab: (currentTab: RealignmentTypes) => void
  clear: () => void
}

export const useRealignmentTabs = create<UseRealignmentTabs>()(
  devtools(
    (set) => ({
      currentTab: RealignmentTypes.Editing,
      setTab: (currentTab) => set({ currentTab }),
      clear: () => set({ currentTab: RealignmentTypes.Editing }),
    }),
    {
      store: 'demand.realignment.tabs',
    },
  ),
)
