import { FC } from 'react'

import { useTranslate } from 'config/i18n'
import RcPagination from 'rc-pagination'

interface PaginationProps {
  current: number
  total: number
  pageSize?: number
  onChange?: (current: number) => void
}

export const Pagination: FC<PaginationProps> = (props) => {
  const translate = useTranslate()

  return (
    <RcPagination
      {...props}
      hideOnSinglePage
      jumpNextIcon="..."
      jumpPrevIcon="..."
      nextIcon={translate('next')}
      prevIcon={translate('prev')}
      showSizeChanger={false}
      showTitle={false}
    />
  )
}
