import { devtools } from 'config/devtools'
import { deleteEmpty } from 'packages/helper'
import { uid } from 'uid'
import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'

import { UseUpdateICTable } from './types'

export const useUpdateICTable = create<UseUpdateICTable>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set) => ({
          updated: [],
          created: [],
          lastSavedErrorData: {
            updated: [],
            created: [],
          },
          createRow: () =>
            set(({ created }) => ({
              created: [
                ...created,
                {
                  idCreated: uid(),
                },
              ],
            })),
          clearCash: () => {
            set({
              updated: [],
              created: [],
              lastSavedErrorData: {
                updated: [],
                created: [],
              },
            })
          },
          setLastSavedErrorData: (lastSavedErrorData) => set(() => ({ lastSavedErrorData })),
          removeNewRows: (ids) =>
            set(({ created }) => ({ created: created.filter((item) => !ids.includes(item.idCreated)) })),
          clear: (selectedUpdated, selectedCreated) =>
            set(({ updated, created }) => ({
              updated: updated.filter((item) => !item.id || !selectedUpdated.includes(item.id)),
              created: created.filter((item) => !item.idCreated || !selectedCreated.includes(item.idCreated)),
              lastSavedErrorData: { updated: [], created: [] },
            })),
          lastSavedError: null,
          setLastSavedError: (lastSavedError) => set(() => ({ lastSavedError })),
          updateModelElement: (forecastLevelId, forecastLevelType, startDate, id, type) => {
            if (!forecastLevelId && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].forecastLevelId
                  delete updated[rowIndex].forecastLevelType
                  delete updated[rowIndex].startDate
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (!forecastLevelId && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  delete created[rowIndex].forecastLevelId
                  delete created[rowIndex].forecastLevelType
                  delete created[rowIndex].startDate
                }
                return { created }
              })
              return
            }
            if (forecastLevelId && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.forecastLevelId = forecastLevelId
                  row.forecastLevelType = forecastLevelType
                  row.startDate = startDate
                } else {
                  updated.push({ id: id as number, forecastLevelId, forecastLevelType, startDate })
                }
                return { updated }
              })
            }
            if (forecastLevelId && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        forecastLevelId,
                        forecastLevelType,
                        startDate,
                      }
                    : item,
                ),
              }))
            }
          },
          updateBenchmark: (hierarchyId, elementsIds, id, type) => {
            if (hierarchyId && elementsIds && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.benchmarks = { hierarchyId, elementsIds }
                } else {
                  updated.push({ id: id as number, benchmarks: { hierarchyId, elementsIds } })
                }
                return { updated }
              })
            }
            if (hierarchyId && elementsIds && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        benchmarks: { hierarchyId, elementsIds },
                      }
                    : item,
                ),
              }))
            }
          },
          updateBenchmarkPercent: (benchmarkPercents, id, type) => {
            if (typeof benchmarkPercents !== 'number' && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  delete created[rowIndex].benchmarkPercents
                }
                return { created }
              })
              return
            }
            if (type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.benchmarkPercents = benchmarkPercents
                } else {
                  updated.push({ id: id as number, benchmarkPercents })
                }
                return { updated }
              })
            }
            if (typeof benchmarkPercents === 'number' && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        benchmarkPercents,
                      }
                    : item,
                ),
              }))
            }
          },
          updatePipelinePercent: (pipelinePercents, id, type) => {
            if (typeof pipelinePercents !== 'number' && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  delete created[rowIndex].benchmarkPercents
                }
                return { created }
              })
              return
            }
            if (type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.pipelinePercents = pipelinePercents
                } else {
                  updated.push({ id: id as number, pipelinePercents })
                }
                return { updated }
              })
            }
            if (typeof pipelinePercents === 'number' && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        pipelinePercents,
                      }
                    : item,
                ),
              }))
            }
          },
          updateHierarchy: (hierarchyId, elementsIds, id, type) => {
            if (hierarchyId && elementsIds && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  if (!row.hierarchies) {
                    row.hierarchies = []
                  }
                  const hierarchy = row.hierarchies.find((item) => item.hierarchyId === hierarchyId)
                  if (hierarchy) {
                    hierarchy.elementsIds = elementsIds
                  } else {
                    row.hierarchies?.push({ hierarchyId, elementsIds })
                  }
                } else {
                  updated.push({ id: id as number, hierarchies: [{ hierarchyId, elementsIds }] })
                }
                return { updated }
              })
            }
            if (hierarchyId && elementsIds && type === 'created') {
              set(({ created }) => ({
                created: created.map((row) => {
                  if (!row.hierarchies) {
                    row.hierarchies = []
                  }
                  if (row.idCreated === id) {
                    const hierarchy = row.hierarchies.find((item) => item.hierarchyId === hierarchyId)
                    if (hierarchy) {
                      hierarchy.elementsIds = elementsIds
                    } else {
                      row.hierarchies?.push({ hierarchyId, elementsIds })
                    }
                  }
                  return row
                }),
              }))
            }
          },
          updateCannibalizationPh: (hierarchyId, elementsIds, id, type) => {
            if (hierarchyId && elementsIds && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.cannibalizationPh = { hierarchyId, elementsIds }
                } else {
                  updated.push({ id: id as number, cannibalizationPh: { hierarchyId, elementsIds } })
                }
                return { updated }
              })
            }
            if (hierarchyId && elementsIds && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        cannibalizationPh: { hierarchyId, elementsIds },
                      }
                    : item,
                ),
              }))
            }
          },
          updateCannibalizationPercent: (cannibalizationPercents, id, type) => {
            if (typeof cannibalizationPercents !== 'number' && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  delete created[rowIndex].cannibalizationPercents
                }
                return { created }
              })
              return
            }
            if (type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.cannibalizationPercents = cannibalizationPercents
                } else {
                  updated.push({ id: id as number, cannibalizationPercents })
                }
                return { updated }
              })
            }
            if (typeof cannibalizationPercents === 'number' && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        cannibalizationPercents,
                      }
                    : item,
                ),
              }))
            }
          },
          updateComment: (comment, id, type) => {
            if (!comment && type === 'updated') {
              set(({ updated }) => {
                const rowIndex = updated.findIndex((item) => item.id === id)
                if (rowIndex > -1) {
                  delete updated[rowIndex].comment
                  if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                    updated.splice(rowIndex, 1)
                  }
                }
                return { updated }
              })
              return
            }
            if (!comment && type === 'created') {
              set(({ created }) => {
                const rowIndex = created.findIndex((item) => item.idCreated === id)
                if (rowIndex > -1) {
                  delete created[rowIndex].comment
                }
                return { created }
              })
              return
            }
            if (comment && type === 'updated' && id) {
              set(({ updated }) => {
                const row = updated.find((item) => item.id === id)
                if (row) {
                  row.comment = comment
                } else {
                  updated.push({ id: id as number, comment })
                }
                return { updated }
              })
            }
            if (comment && type === 'created') {
              set(({ created }) => ({
                created: created.map((item) =>
                  item.idCreated === id
                    ? {
                        ...item,
                        comment,
                      }
                    : item,
                ),
              }))
            }
          },
        }),
        { name: 'ICTable' },
      ),
    ),
    {
      store: 'ICTable',
    },
  ),
)
