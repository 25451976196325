import { FC } from 'react'

import { NavLink } from 'components/template/NavLink'
import { useTranslate } from 'config/i18n'
import { NavMenuJobs } from 'modules/jobs/components/NavMenuJobs'
import { useJobsPermissionsByStream } from 'modules/jobs/handlers'
import { usePermissions } from 'modules/user/queries'

export const NavMenuPrgm: FC = () => {
  const translate = useTranslate()
  const { permissions } = usePermissions()
  const { canReadJobsDb, canEditJobsDb } = useJobsPermissionsByStream()

  return (
    <>
      {(permissions.readPulseDb || permissions.editPulseDb) && (
        <NavLink className="menuItem" end path="/prgm/pulse">
          {translate('prgm.navMenu.pulse')}
        </NavLink>
      )}
      {(permissions.readCreateDb || permissions.editCreateDb) && (
        <NavLink className="menuItem" path="/prgm/create">
          {translate('prgmCreate.create')}
        </NavLink>
      )}
      {(permissions.readSearchDb || permissions.editSearchDb) && (
        <NavLink className="menuItem" path="/prgm/search">
          {translate('prgm.navMenu.search')}
        </NavLink>
      )}
      {(permissions.readApproveDb || permissions.editApproveDb) && (
        <NavLink className="menuItem" path="/prgm/approve">
          {translate('prgm.navMenu.approve')}
        </NavLink>
      )}
      {(permissions.readDatahubDb || permissions.editDatahubDb) && (
        <NavLink className="menuItem" path="/prgm/data-hub">
          {translate('prgm.navMenu.dataHub')}
        </NavLink>
      )}
      {(permissions.readReportsDb || permissions.editReportsDb) && (
        <NavLink className="menuItem" path="/prgm/reports">
          {translate('prgm.navMenu.reports')}
        </NavLink>
      )}
      {(canReadJobsDb || canEditJobsDb) && <NavMenuJobs />}
    </>
  )
}
