import { createEndpoint } from 'config/api'
import { NoParamEndpointConstructor, TwoParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/scenarios'

interface Endpoints {
  updateWorkflow: TwoParamEndpointConstructor
  getActiveRequests: NoParamEndpointConstructor
  getFinishedRequests: NoParamEndpointConstructor
  getWorkflowIndicator: NoParamEndpointConstructor
}

export const workflowEndpoints: Endpoints = {
  updateWorkflow: (scenarioId, resultId) =>
    createEndpoint(`${baseUrl}/:scenarioId/results/:resultId/workflow`, { scenarioId, resultId }),
  getActiveRequests: () => createEndpoint('/workflow-processes/active'),
  getFinishedRequests: () => createEndpoint('/workflow-processes/finished'),
  getWorkflowIndicator: () => createEndpoint('/workflow-processes/active/indicator-check'),
}
