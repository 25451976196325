import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { UseCurrentStream } from './types'

export const useCurrentStream = create<UseCurrentStream>()(
  devtools(
    persist(
      (set) => ({
        currentStream: null,
        set: (currentStream) => set({ currentStream }),
        clear: () => set({ currentStream: null }),
      }),
      {
        name: 'currentStream',
      },
    ),
    {
      store: 'currentStream',
    },
  ),
)
