import { FC, JSX, ReactElement } from 'react'

import { useTranslate } from 'config/i18n'
import { useProjectType } from 'hooks/useProjectType'
import { useClearSearchParams } from 'modules/auth/handlers/useClearSearchParams'
import { useProject, useProjectInfo } from 'modules/projects/queries'
import { usePermissions, useProfile } from 'modules/user/queries'
import { isServerError } from 'packages/api'
import { ErrorStack } from 'ui/ErrorStack'
import { Loader, LoaderTypes } from 'ui/Loader'

export function withAuth<T = any>(Component: FC<T>) {
  return (props: JSX.IntrinsicAttributes & T): ReactElement => {
    const translate = useTranslate()
    const { isLoadingProfile, authenticated, profileError, error, isRefetching } = useProfile()
    const { isLoadingProject } = useProject()
    const { isLoadingProjectInfo } = useProjectInfo()
    const { isLoadingPermissions } = usePermissions()
    const isLoading =
      !authenticated ||
      isLoadingProfile ||
      isLoadingProject ||
      isLoadingProjectInfo ||
      isLoadingPermissions ||
      (props as any).loading

    const { isRedirecting } = useProjectType()

    useClearSearchParams()

    if (profileError || isRefetching) {
      return isServerError(error) || isRefetching ? (
        <Loader text={translate('reconnecting')} type={LoaderTypes.SpinnerCenter} />
      ) : (
        <ErrorStack classNameWrap="center" errors={profileError} />
      )
    }

    return <Component {...props} loading={isLoading || isRedirecting} />
  }
}
