import { MutableRefObject } from 'react'

import { DraggableParams } from 'components/common/Draggable/placeholderStrategies'

export const scrollControl = (
  params: DraggableParams,
  event: MouseEvent,
  refScrollTimeoutId: MutableRefObject<NodeJS.Timeout | undefined>,
) => {
  const scrollElement = params.refWrap?.current?.closest('[data-scroll-container=true]') as HTMLDivElement
  clearTimeout(refScrollTimeoutId.current)
  if (
    !scrollElement ||
    !params.refList.current ||
    params.refRealDraggableIndex.current === null ||
    !params.refDraggableItem.current
  ) {
    return
  }
  const scrollElTop = scrollElement.getBoundingClientRect().top
  const scrollTop = params.refList.current.getBoundingClientRect().top + scrollElement.scrollTop
  const clientHeight = scrollElement.offsetHeight - 11
  const scrollHeight = scrollElement.querySelector('div')?.offsetHeight ?? scrollElement.scrollHeight
  const scrollBottom = clientHeight - (scrollTop - scrollElTop)
  const maxScroll = scrollHeight - clientHeight
  const y = event.clientY - scrollTop

  const positionTop = 20
  const positionBottom = 30
  const step = 5

  const toTop = y < positionTop
  const toBottom = y > scrollBottom - positionBottom

  if (toTop || toBottom) {
    refScrollTimeoutId.current = setTimeout(() => {
      let diff = toTop ? -step : step
      let newScroll = scrollElement.scrollTop + diff
      if (newScroll < 0) {
        if (newScroll !== 0) {
          diff = step + newScroll
          newScroll = 0
        } else {
          return
        }
      }
      if (newScroll > maxScroll) {
        if (newScroll !== maxScroll) {
          diff = step - (newScroll - maxScroll)
          newScroll = maxScroll
        } else {
          return
        }
      }
      scrollElement.scrollTop = newScroll
      if (params.refDraggableItem.current) {
        const currentTransform = params.refDraggableItem.current.style.transform
        const match = /translate\((-?\d+px),\s*(-?\d+px)\)/.exec(currentTransform)
        const currentTranslateX = match ? parseFloat(match[1]) : 0
        const currentTranslateY = match ? parseFloat(match[2]) : 0
        params.refDraggableItem.current.style.transform = currentTransform.replace(
          /translate\((-?\d+px),\s*(-?\d+px)\)/,
          `translate(${currentTranslateX}px,${currentTranslateY + diff}px)`,
        )
      }
      scrollControl(params, event, refScrollTimeoutId)
    }, 16)
  }
}
