import { createEndpoint } from 'config/api'
import { NoParamEndpointConstructor, OneParamEndpointConstructor } from 'interfaces/api.interfaces'

const baseUrl = '/projects'

interface Endpoints {
  getProject: OneParamEndpointConstructor
  getProjects: NoParamEndpointConstructor
  getProjectInfo: OneParamEndpointConstructor
}

export const projectsEndpoints: Endpoints = {
  getProject: (projectId) => createEndpoint(`${baseUrl}/:projectId`, { projectId }),
  getProjects: () => createEndpoint(baseUrl),
  getProjectInfo: (projectId) => createEndpoint(`${baseUrl}/:projectId/info`, { projectId }),
}
