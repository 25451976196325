import { FC } from 'react'

import { NavLink } from 'components/template/NavLink'
import { useTranslate } from 'config/i18n'
import { ProjectTypes } from 'constants/projects'
import { useProject } from 'modules/projects/queries'

export const NavMenuOpti: FC = () => {
  const { project } = useProject()
  const translate = useTranslate()

  return (
    <>
      {project?.type.code === ProjectTypes.PromoOptimizer && (
        <NavLink path="/opti/promo-optimizer">{translate('menu.allScenarios')}</NavLink>
      )}
      {project?.type.code === ProjectTypes.RtmOptimizer && (
        <NavLink path="/opti/rtm-optimizer">{translate('menu.allScenarios')}</NavLink>
      )}
      {project?.type.code === ProjectTypes.TetrisOptimizer && (
        <NavLink path="/opti/tetris-optimizer">{translate('menu.allScenarios')}</NavLink>
      )}
      {project?.type.code === ProjectTypes.CfrOptimizer && (
        <NavLink path="/opti/cfr-optimizer">{translate('menu.allScenarios')}</NavLink>
      )}
    </>
  )
}
