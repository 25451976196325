import { context, Exception, Span, SpanKind, SpanOptions, SpanStatusCode, trace } from '@opentelemetry/api'
import { Resource } from '@opentelemetry/resources'
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions'
import { configureOpentelemetry } from '@uptrace/web'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { DSN, ENABLE_TRACING } from 'constants/env'
import { Streams } from 'constants/streams'
import { decode } from 'js-base64'
import { snakeCase } from 'lodash'
import { ApiError, paramsSerializerToSnakeCaseArrayBrackets } from 'packages/api'

if (ENABLE_TRACING) {
  configureOpentelemetry({
    dsn: DSN,
    resource: new Resource({
      [SEMRESATTRS_SERVICE_NAME]: 'api',
    }),
  })

  performance.setResourceTimingBufferSize(1000)
  performance.clearResourceTimings()
}

const tracer = trace.getTracer('@opentelemetry/api', '1.8.0')

const parseResponseData = (data: string | object) =>
  typeof data === 'string' ? (data.startsWith('<!DOCTYPE') ? '[HTML ERROR]' : data) : JSON.stringify(data)

const getUserIdFormJWT = (token: string) => {
  const jwtString = decode(token)

  const regex = /"user_id":(\d+)/
  const match = jwtString.match(regex)

  if (match && match[1]) {
    return parseInt(match[1])
  }
}

const limitStringLength = (str: string, maxLength = 1000) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...'
  } else {
    return str
  }
}

const addHttpData = (span: Span, request: AxiosRequestConfig) => {
  span.setAttribute('http_request_method', String(request.method?.toUpperCase()))
  span.setAttribute('http_route', request.apiRoute)
}

const defaultSpanOptions: SpanOptions = { kind: SpanKind.SERVER }

export const tracingRequestInterceptor = (request: AxiosRequestConfig) => {
  if (!ENABLE_TRACING) {
    return request
  }
  request.span = tracer.startSpan(`${request.method?.toUpperCase()} ${request.apiRoute}`, defaultSpanOptions)
  addHttpData(request.span, request)
  request.span.setAttribute('request_url', String(request.url))
  request.span.setAttribute('request_baseURL', String(request.baseURL))
  request.span.setAttribute('request_params', JSON.stringify(request.params))
  request.span.setAttribute('stream', request.tracing.stream)
  request.span.setAttribute('module', request.tracing.module)
  request.span.setAttribute('method_name', request.tracing.methodName)
  return request
}

export const tracingResponseInterceptor = (response: AxiosResponse | AxiosError, apiError?: ApiError) => {
  if (!ENABLE_TRACING) {
    return response ?? apiError
  }

  let userId = ''
  if (response.config.headers?.Authorization) {
    const token = String(response.config.headers.Authorization).replace('Bearer ', '')
    try {
      userId = String(getUserIdFormJWT(token))
    } catch (e) {}
  }

  const span = response.config.span
  if (span) {
    const resources = performance.getEntriesByType('resource') as unknown as PerformanceResourceTiming[]
    const queryParams = response.config.params
    const queryString = paramsSerializerToSnakeCaseArrayBrackets(queryParams)

    let baseURL = response.config.baseURL || ''
    if (baseURL?.endsWith('/')) {
      baseURL = baseURL.slice(0, -1)
    }

    const fullUrl = baseURL + response.config.url + (queryString ? '?' + queryString : '')
    const entry = resources.find((resource) => resource.name === fullUrl)

    performance.clearResourceTimings()

    if (entry) {
      const ctx = trace.setSpan(context.active(), span)

      const redirectSpan = tracer.startSpan('redirect', { startTime: entry.redirectStart, ...defaultSpanOptions }, ctx)
      addHttpData(redirectSpan, response.config)
      redirectSpan.end(entry.redirectEnd)

      const domainLookupSpan = tracer.startSpan(
        'domainLookup',
        { startTime: entry.domainLookupStart, ...defaultSpanOptions },
        ctx,
      )
      addHttpData(domainLookupSpan, response.config)
      domainLookupSpan.end(entry.domainLookupEnd)

      const connectSpan = tracer.startSpan(
        'connect',
        { startTime: entry.connectStart || entry.connectStart, ...defaultSpanOptions },
        ctx,
      )
      addHttpData(connectSpan, response.config)
      connectSpan.end(entry.connectEnd)

      const secureConnectionSpan = tracer.startSpan(
        'secureConnection',
        { startTime: entry.secureConnectionStart || entry.connectStart },
        ctx,
      )
      addHttpData(secureConnectionSpan, response.config)
      secureConnectionSpan.end(entry.connectEnd)

      const requestSpan = tracer.startSpan('request', { startTime: entry.requestStart, ...defaultSpanOptions }, ctx)
      addHttpData(requestSpan, response.config)
      requestSpan.end(entry.responseStart)

      const responseSpan = tracer.startSpan(
        'response',
        { startTime: entry.responseStart || entry.fetchStart, ...defaultSpanOptions },
        ctx,
      )
      addHttpData(responseSpan, response.config)
      responseSpan.setAttribute('response_status', String(response.status))
      responseSpan.setAttribute('transfer_size', String(entry.transferSize))
      responseSpan.end(entry.responseEnd)
    }

    span.setAttribute(
      'request_data',
      limitStringLength(
        typeof response.config.data === 'string'
          ? response.config.data
          : response.config.data instanceof FormData
            ? '[FormData]'
            : JSON.stringify(response.config.data),
      ),
    )
    span.setAttribute(
      'response_data',
      limitStringLength(
        parseResponseData((response as AxiosError)?.response?.data ?? (response as AxiosResponse).data),
      ),
    )
    span.setAttribute('user_id', userId)

    if (apiError) {
      span.recordException(apiError as Exception)
      span.setStatus({ code: SpanStatusCode.ERROR })
    } else {
      span.setStatus({ code: SpanStatusCode.OK })
    }
    span.end()
  }

  return response ?? apiError
}

export const sendWsTracing = (attributes: {
  data: any
  channel: string
  userId?: number
  tracing?: Record<string, string | number | null>
  stream?: Streams
  module?: string
  size?: string
}) => {
  if (!ENABLE_TRACING) {
    return
  }

  const span = tracer.startSpan('ws_publish', defaultSpanOptions)
  span.setAttribute('publish_data', limitStringLength(attributes.data))
  span.setAttribute('channel', attributes.channel)
  if (attributes.stream) {
    span.setAttribute('stream', attributes.stream)
  }
  if (attributes.module) {
    span.setAttribute('module', attributes.module)
  }
  if (attributes.size) {
    span.setAttribute('size', attributes.size)
  }
  if (attributes.userId) {
    span.setAttribute('user_id', attributes.userId)
  }
  if (attributes.tracing) {
    Object.entries(attributes.tracing).forEach(([key, value]) => {
      span.setAttribute(
        snakeCase(key),
        typeof value === 'string' ? limitStringLength(value) : value === null ? 'null' : value,
      )
    })
  }
  span.setStatus({ code: SpanStatusCode.OK })
  span.end()
}
