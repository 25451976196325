import { flexRender, Header } from '@tanstack/react-table'
import { TRow } from 'interfaces/table.interfaces'
import { Truncate } from 'ui/Truncate'
import { typedMemo } from 'utils/typedMemo'

import { TableHeaderCell } from './TableHeaderCell'

interface Props<TData extends TRow> {
  header: Header<TData, unknown>
  trackViewport?: boolean
  onViewPort?: (columnId: string, inViewport: boolean) => void
  truncateHeaders?: boolean
}

export const ThCellHeader = typedMemo(
  <TData extends TRow>({ header, truncateHeaders, trackViewport, onViewPort }: Props<TData>) => {
    const TruncateHeaders = ({ children }: { children: any }) =>
      truncateHeaders ? <Truncate>{children}</Truncate> : children
    return (
      <TruncateHeaders>
        {header.isPlaceholder
          ? null
          : trackViewport
            ? flexRender(TableHeaderCell, { ...header.column.columnDef, onViewPort })
            : flexRender(header.column.columnDef.header, header.getContext())}
      </TruncateHeaders>
    )
  },
)
