import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/jobs/')({
  component: createPage({
    titleKey: 'titles.jobs',
    lazyImport: {
      fn: () => import('modules/jobs/components/JobsBlock'),
      name: 'JobsBlock',
    },
  }),
})
