import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/replen-optimizer/replen-scenarios/not-found')({
  component: createPage({
    lazyImport: {
      fn: () => import('opti/scenarioShared/components/ScenarioNotFound'),
      name: 'ScenarioNotFound',
    },
  }),
})
