import { FC, useState } from 'react'

import ArrowIcon from 'assets/images/arrow-bottom.svg?react'
import cx from 'clsx'
import { NavLink } from 'components/template/NavLink'
import { NavTitle } from 'components/template/NavTitle'
import { useTranslate } from 'config/i18n'
import { IS_DEMO_OPTIMIZE_ENABLED, IS_WORKFLOW_ENABLED } from 'constants/env'
import { ProjectTypes } from 'constants/projects'
import { useWFIndicator } from 'jume/workflow/queries/useWFIndicator'
import { useProject } from 'modules/projects/queries'
import { useProfile } from 'modules/user/queries'
import { SlideToggle, SlideToggleTypes } from 'ui/SlideToggle'

import classes from './NavMenuJume.module.scss'

export const NavMenuJume: FC = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const { profile } = useProfile()
  const { project, isPoc } = useProject()
  const { data: isNewWFActions } = useWFIndicator()
  const translate = useTranslate()
  const toggleMenuOpen = () => setIsSubMenuOpen((prev) => !prev)

  return (
    <>
      {project?.type.code === ProjectTypes.Forecast && (
        <>
          {!isPoc && (
            <NavLink className="menuItem" path="/jume">
              {translate('menu.main')}
            </NavLink>
          )}

          <NavTitle
            className={cx('menuItem', classes.disabled)}
            icon={<ArrowIcon />}
            isActive={isSubMenuOpen}
            onClick={toggleMenuOpen}
          >
            {translate('menu.data')}
          </NavTitle>

          <SlideToggle classNameWrap={classes.subMenu} open={isSubMenuOpen} type={SlideToggleTypes.NoTransform}>
            <NavLink className="menuItem" path="/jume/data">
              {translate('menu.dataSources')}
            </NavLink>
            <NavLink className="menuItem" path="/jume/master-data">
              {translate('menu.masterData')}
            </NavLink>

            {!isPoc && (
              <>
                <NavLink className="menuItem" path="/jume/status">
                  {translate('menu.status')}
                </NavLink>
                <NavLink className="menuItem" path="/jume/innovation-management">
                  {translate('menu.innovationManagement')}
                </NavLink>
              </>
            )}
          </SlideToggle>

          {profile?.activeProjectId && (
            <NavLink
              className="menuItem"
              params={{ projectId: String(profile.activeProjectId) }}
              path="/jume/project/$projectId/forecast"
            >
              {translate('menu.forecast')}
            </NavLink>
          )}

          {IS_DEMO_OPTIMIZE_ENABLED && <NavLink path="/jume/promo-optimizer-demo">{translate('menu.promo')}</NavLink>}

          {profile?.activeProjectId && IS_WORKFLOW_ENABLED && (
            <NavLink className={classes.secondMenu} path="/jume/approvals">
              {translate('menu.approvals')}
              {isNewWFActions && <span className={classes.newActions} />}
            </NavLink>
          )}
        </>
      )}

      {project?.type.code === ProjectTypes.ReplenOptimizer && (
        <>
          <NavLink className="menuItem" path="/opti/replen-optimizer/replen-warehouses">
            {translate('menu.warehouses')}
          </NavLink>
          <NavLink className="menuItem" path="/opti/replen-optimizer/replen-scenarios">
            {translate('menu.scenarios')}
          </NavLink>
          <NavLink className="menuItem" path="/opti/replen-optimizer/replen-overview">
            {translate('menu.overview')}
          </NavLink>
        </>
      )}
    </>
  )
}
