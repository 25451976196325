import { FC, JSX, ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import { I18NProvider } from '@ayub-begimkulov/i18n'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DraggableAppProvider } from 'components/common/Draggable/providers/DraggableAppProvider'
import { i18n, useLang, useReadyLang } from 'config/i18n'
import { queryClient } from 'config/queryClient'
import { toastsProps } from 'constants/toasts'
import { Loader, LoaderTypes } from 'ui/Loader'

/**
 * Wraps Component into providers
 * @param Component Component
 * @returns Wrapped Component
 */
export function withProviders<T = any>(Component: FC<T>) {
  return (props: JSX.IntrinsicAttributes & T): ReactElement => {
    const lang = useLang((state) => state.lang)
    const { readyI18n } = useReadyLang()

    return (
      <I18NProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <DraggableAppProvider>
            <Helmet htmlAttributes={{ lang }} />
            {!readyI18n ? (
              <Loader type={LoaderTypes.SpinnerCenter} />
            ) : (
              <>
                <Component {...props} />
                <ToastContainer {...toastsProps} />
              </>
            )}
            <ReactQueryDevtools initialIsOpen={false} position="bottom" />
          </DraggableAppProvider>
        </QueryClientProvider>
      </I18NProvider>
    )
  }
}
