import { FC } from 'react'

import { useCurrentStream } from 'modules/streams/store'
import { useProfile } from 'modules/user/queries'
import { Select } from 'ui/Select'

interface StreamSelectProps {
  className?: string
}

export const StreamSelect: FC<StreamSelectProps> = ({ className }) => {
  const { currentStream, set } = useCurrentStream()
  const { profile, isLoadingProfile } = useProfile()

  if (isLoadingProfile) {
    return null
  }

  return (
    <Select
      className={className}
      onChange={(_, options) => options?.[0]?.meta && set(options[0].meta)}
      options={profile?.streams?.map((item) => ({
        value: item.id,
        label: item ? item.title : '',
        meta: item,
      }))}
      value={currentStream?.id}
    />
  )
}
