import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseSelectionSaving } from './types'

export const useSelectionSaving = create<UseSelectionSaving>()(
  devtools(
    (set) => ({
      isSaving: false,
      setIsSaving: (isSaving) => set({ isSaving }),
    }),
    {
      store: 'selectionSaving',
    },
  ),
)
