import { Path } from 'interfaces/router.interfaces'

export enum ProjectTypes {
  Forecast = 'forecast',
  ForecastPoc = 'forecastPoc',
  PromoOptimizer = 'promo_optimizer',
  ReplenOptimizer = 'replen_optimizer',
  RtmOptimizer = 'rtm_optimizer',
  TetrisOptimizer = 'tetris_optimizer',
  CfrOptimizer = 'cfr_optimizer',
}

export const projectTypeMap: Record<ProjectTypes, Path> = {
  [ProjectTypes.Forecast]: '/jume',
  [ProjectTypes.ForecastPoc]: '/jume/data',
  [ProjectTypes.PromoOptimizer]: '/opti/promo-optimizer',
  [ProjectTypes.ReplenOptimizer]: '/opti/replen-optimizer',
  [ProjectTypes.RtmOptimizer]: '/opti/rtm-optimizer',
  [ProjectTypes.TetrisOptimizer]: '/opti/tetris-optimizer',
  [ProjectTypes.CfrOptimizer]: '/opti/cfr-optimizer',
}
