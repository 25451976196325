import { axios } from 'config/axios'
import { Streams } from 'constants/streams'
import { GetOneResponse } from 'interfaces/api.interfaces'

import { systemUpdatingEndpoints } from './endpoints'
import { SystemUpdating } from './interfaces'

const getSystemUpdatingStatus = async (): GetOneResponse<SystemUpdating> => {
  const { data } = await axios.request<SystemUpdating>({
    method: 'GET',
    ...systemUpdatingEndpoints.getUpdatingStatus(),
    tracing: {
      stream: Streams.Modules,
      module: 'systemUpdating',
      methodName: 'getSystemUpdatingStatus',
    },
  })
  return data
}

export const systemUpdatingApi = {
  getSystemUpdatingStatus,
}
