import { PlaceholderStrategy } from 'components/common/Draggable/types'

import { equalLevelsStrategy } from './equalLevelsStrategy'
import { placeholderBetweenStrategy } from './placeholderBetweenStrategy'

export const placeholderTreeBottomLevelStrategy: PlaceholderStrategy = (params) =>
  placeholderBetweenStrategy({
    ...params,
    levelsStrategy: equalLevelsStrategy(params),
  })
