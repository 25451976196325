import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/deploy/scenario-settings/')({
  component: createPage({
    titleKey: 'titles.deployCreateScenario',
    lazyImport: {
      fn: () => import('deploy/pages/scenario/components/DeployScenarioSettings'),
      name: 'DeployScenarioSettings',
    },
  }),
})
