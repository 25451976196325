import { axios } from 'config/axios'
import { Streams } from 'constants/streams'
import { jobLockEndpoints } from 'demand/jobLock/api/endpoints'
import { GetOneResponse } from 'interfaces/api.interfaces'

import { JobLockStatus } from './interfaces'

const getJobLockStatus = async (): GetOneResponse<JobLockStatus> => {
  const { data } = await axios.request<JobLockStatus>({
    method: 'GET',
    ...jobLockEndpoints.getJobLockStatus(),
    tracing: {
      stream: Streams.Demand,
      module: 'jobLock',
      methodName: 'getJobLockStatus',
    },
  })
  return data
}

export const jobLockApi = {
  getJobLockStatus,
}
