import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseDelistingErrors } from './types'

export const useDelistingErrors = create<UseDelistingErrors>()(
  devtools(
    (set) => ({
      errors: [],
      clear: () => set({ errors: [] }),
      setErrors: (errors) => set({ errors }),
      removeError: (id, key) =>
        set(({ errors }) => ({ errors: errors.filter((item) => item.id !== id || item.key !== key) })),
      removeById: (id) => set(({ errors }) => ({ errors: errors.filter((item) => item.id !== id) })),
    }),
    {
      store: 'delistingErrors',
    },
  ),
)
