import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/ppds/materials-family')({
  component: createPage({
    titleKey: 'titles.ppdsMaterialsFamily',
    lazyImport: {
      fn: () => import('ppds/materialsFamily/components/MaterialsFamilyBlock'),
      name: 'MaterialsFamilyBlock',
    },
  }),
})
