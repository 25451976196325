export const defaultPerPage = 30
export const dataSourcesPerPage = 100
export const statusDashboardPerPage = 15
export const innovationDashboardPerPage = 15
export const promoScenariosList = 25
export const masterDataPerPage = 20
export const promoResultsLimit = 50
export const replenScenariosList = 20
export const replenScenariosOutputRowsLimit = 50
export const workflowDashboardPerPage = 5
export const rtmScenariosList = 25
export const tetrisScenariosList = 25
export const tetrisScenariosSearchList = 100
export const cfrScenariosList = 25
export const cfrResultsLimit = 50
export const rtmResultsLimit = 50
export const selectionsPerPage = 50
export const recommendationsPerPage = 10
export const attributesPerPage = 50
export const keyFiguresPerPage = 50
export const filtersPerPage = 50
export const attributeValuesPerPage = 150
export const demandForecastPerPage = 150
export const demandForecastFilterPerPage = 50
export const jobsList = 50
export const stepsList = 100
export const deployScenariosList = 20
export const deploySessionList = 27
export const jobsStatusList = 20
export const deployScenarioDataList = 27
export const materialsPerPage = 15
export const resourcesPerPage = 15
export const dataHandlingTablePerPage = 50
export const dataHandlingFilterPerPage = 50
export const dataUndeployedStockPerPage = 30
export const defaultFilterValuesPerPage = 50
export const defaultSkuPerPage = 20
export const promoChangeHistoryPerPage = 100
