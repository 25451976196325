import { FC } from 'react'

import cx from 'clsx'

import classes from './EditModeKPIWidget.module.scss'
import { EditModeKPIWidgetColumn } from './EditModeKPIWidgetColumn'

interface EditModeKPIWidgetProps {
  className?: string
}

export const EditModeKPIWidget: FC<EditModeKPIWidgetProps> = ({ className }) => (
  <div className={cx(classes.wrap, className)}>
    <EditModeKPIWidgetColumn />
    <EditModeKPIWidgetColumn />
    <div className={cx(classes.line, classes.lineVertical)} />
    <EditModeKPIWidgetColumn />
    <EditModeKPIWidgetColumn />
    <div className={cx(classes.line, classes.lineVertical)} />
    <EditModeKPIWidgetColumn />
    <div className={cx(classes.line, classes.lineVertical)} />
    <EditModeKPIWidgetColumn />
    <EditModeKPIWidgetColumn />
    <EditModeKPIWidgetColumn />
  </div>
)
