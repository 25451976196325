import { CSSProperties, forwardRef, HTMLAttributes, RefObject } from 'react'

import { Draggable } from 'components/common/Draggable'
import {
  placeholderBetweenStrategy,
  placeholderOnlyOtherListStrategy,
  placeholderReplaceStrategy,
  PlaceholderStrategy,
  placeholderTreeBottomLevelNotOrderStrategy,
  placeholderTreeBottomLevelStrategy,
} from 'components/common/Draggable/placeholderStrategies'

import { useDraggableElementModify } from './hooks/useDraggableElementModify'
import classes from './Table.module.scss'
import { DraggableStrategy } from './types'

export const TBody = forwardRef<
  HTMLTableSectionElement,
  Omit<HTMLAttributes<HTMLTableSectionElement>, 'onChange'> & {
    isDraggable?: boolean
    draggableStrategy: DraggableStrategy
    draggableOnChange?: (oldIndex: number, newIndex: number, item: any | null, into: any | null) => void
    draggableGetDescription?: (meta: any | null) => string
    draggableIsGlobal?: boolean
    collapsingTreeKeys?: string[]
    draggableOnPaste?: (newIndex: number, item: any, into: any) => void
    refScroll?: RefObject<HTMLDivElement>
    style?: CSSProperties | undefined
  }
>(
  (
    {
      isDraggable,
      draggableStrategy,
      children,
      draggableOnChange,
      draggableGetDescription,
      draggableIsGlobal,
      collapsingTreeKeys,
      draggableOnPaste,
      refScroll,
      ...props
    },
    ref,
  ) => {
    const placeholderStrategies: Record<DraggableStrategy, PlaceholderStrategy> = {
      rows: placeholderReplaceStrategy,
      rowsOnlyOtherList: placeholderOnlyOtherListStrategy,
      tree: placeholderBetweenStrategy,
      treeBottomLevel: placeholderTreeBottomLevelStrategy,
      treeBottomLevelNotOrder: placeholderTreeBottomLevelNotOrderStrategy,
    }

    const tbodyClass: Record<DraggableStrategy, string | undefined> = {
      rows: classes.placeholderReplaceStrategy,
      rowsOnlyOtherList: classes.placeholderOnlyOtherList,
      tree: classes.placeholderBetweenStrategy,
      treeBottomLevel: classes.placeholderBetweenStrategy,
      treeBottomLevelNotOrder: classes.placeholderBetweenStrategy,
    }

    const { onDragStart, onDragEnd } = useDraggableElementModify(draggableGetDescription)

    return isDraggable ? (
      <Draggable
        as="tbody"
        className={tbodyClass[draggableStrategy]}
        classNameDragging={classes.dragging}
        draggableIsGlobal={draggableIsGlobal}
        enabled
        onChange={draggableOnChange}
        placeholderElement="tr"
        placeholderStrategy={placeholderStrategies[draggableStrategy]}
        ref={ref}
        {...props}
        draggableOnPaste={draggableOnPaste}
        maxLevel={collapsingTreeKeys?.length || 0}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        refWrap={refScroll}
      >
        {children}
      </Draggable>
    ) : (
      <tbody {...props} ref={ref}>
        {children}
      </tbody>
    )
  },
)
