import { i18n } from 'config/i18n'
import { Path } from 'interfaces/router.interfaces'

interface ChildItemMenu {
  id: string
  label: string
  path?: Path
}

export interface ItemMenuDeploy {
  id: string
  label: string
  items?: ChildItemMenu[]
  path?: Path
}

export const getDeployMenu = (): ItemMenuDeploy[] => [
  {
    id: 'session',
    label: i18n.get('deploy.navMenu.session'),
    items: [{ id: 'management', label: i18n.get('deploy.navMenu.session.management'), path: '/deploy/data-hub' }],
  },
  {
    id: 'scenarios',
    label: i18n.get('deploy.navMenu.scenarios'),
    items: [
      {
        id: 'management',
        label: i18n.get('deploy.navMenu.scenarios.management'),
        path: '/deploy/scenario-data-hub',
      },
      { id: 'history', label: i18n.get('deploy.navMenu.scenarios.history'), path: '/deploy/scenarios-history' },
      {
        id: 'settings',
        label: i18n.get('deploy.navMenu.scenarios.settings'),
        path: '/deploy/scenario-settings',
      },
    ],
  },
  {
    id: 'deployment',
    label: i18n.get('deploy.navMenu.deployment'),
    items: [
      {
        id: 'management',
        label: i18n.get('deploy.navMenu.deployment.allocation'),
        path: '/deploy/stock-allocation',
      },
      {
        id: 'history',
        label: i18n.get('deploy.navMenu.deployment.optimization'),
        path: '/deploy/transport-optimization',
      },
    ],
  },
  {
    id: 'reporting',
    label: i18n.get('deploy.navMenu.reporting'),
    items: [
      {
        id: 'management',
        label: i18n.get('deploy.navMenu.reporting.pped'),
        path: '/deploy/pped',
      },
      {
        id: 'history',
        label: i18n.get('deploy.navMenu.reporting.executive'),
        path: '/deploy/executive',
      },
    ],
  },
]
