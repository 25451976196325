import { createElement, CSSProperties, ElementType, forwardRef, memo, MouseEvent, ReactNode, useContext } from 'react'

import { useDeepCompareEffect } from 'ahooks'

import { DraggableContext } from './providers/DraggableProvider'

interface DraggableItemProps {
  className?: string
  children?: ReactNode
  enabled?: boolean
  isDragElement?: boolean
  style?: CSSProperties
  ['data-index']?: number
  as?: ElementType
  meta?: any
  index: number
}

export const DraggableItem = memo(
  forwardRef<HTMLDivElement, DraggableItemProps>(
    ({ className, children, enabled = true, isDragElement = true, style, as = 'div', meta, index, ...data }, ref) => {
      const { refDraggableItem, refMetaItems } = useContext(DraggableContext)

      const onMouseDown = (event: MouseEvent) => {
        const target = event.target as HTMLElement
        const dragElement = target.dataset.draggable === 'true' ? target : target.closest('[data-drag-element=true]')
        if (!dragElement) {
          return
        }
        refDraggableItem.current =
          target.dataset['drag-element'] === 'true' ? target : target.closest('[data-draggable=true]')
      }

      useDeepCompareEffect(() => {
        if (index === undefined) {
          return
        }
        refMetaItems.current[index] = meta
      }, [meta, index, refMetaItems, data['data-index']])

      return createElement(
        as,
        {
          className,
          'data-drag-element': isDragElement,
          'data-draggable': enabled,
          'data-index': data['data-index'],
          'data-list-element': true,
          onMouseDown,
          ref,
          style,
        },
        children,
      )
    },
  ),
)
