import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/demand/selections/new')({
  component: createPage({
    titleKey: 'titles.deployScenario',
    lazyImport: {
      fn: () => import('demand/selections/components/detail/SelectionBlock'),
      name: 'SelectionBlock',
      props: { isNew: true },
    },
  }),
})
