import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/prgm/jobs/new')({
  component: createPage({
    titleKey: 'titles.jobCreate',
    lazyImport: {
      fn: () => import('modules/jobs/components/CreateJobBlock'),
      name: 'CreateJobBlock',
    },
  }),
})
