import { FC, MutableRefObject, ReactNode, useEffect, useState } from 'react'

import { ContextMenu } from 'ui/ContextMenu'
import { InitContextMenu } from 'ui/Table/interfaces'

interface Props {
  refContextMenuInitializer: MutableRefObject<undefined | InitContextMenu>
}

export const TableContextMenu: FC<Props> = ({ refContextMenuInitializer }) => {
  const [open, setOpen] = useState(false)
  const [children, setChildren] = useState<ReactNode>(null)
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  const onClose = () => {
    setOpen(false)
    setChildren(null)
    setTop(0)
    setLeft(0)
  }

  useEffect(() => {
    refContextMenuInitializer.current = (
      node: ReactNode,
      pageX: number,
      pageY: number,
      wrapper?: HTMLElement | null,
    ) => {
      setOpen(node !== null)
      setChildren(node)
      let newTop = pageY
      let newLeft = pageX
      if (wrapper) {
        const rect = wrapper.getBoundingClientRect()
        newTop = rect.top + rect.height
        newLeft = rect.left
      }
      setTop(newTop)
      setLeft(newLeft)
    }
  }, [])

  return (
    <ContextMenu left={left} onClose={onClose} open={open} top={top}>
      {children}
    </ContextMenu>
  )
}
