import { useMutation } from '@tanstack/react-query'
import { useTranslate } from 'config/i18n'
import { editModeApi, WidgetsList } from 'jume/editMode/api'
import { UpdateWidgetsListProps } from 'jume/editMode/interfaces'
import { useQueryClient } from 'packages/react-query'

import { WidgetsQueries } from './types'

export const useUpdateWidgets = () => {
  const queryClient = useQueryClient()
  const translate = useTranslate()

  const { mutate, isPending } = useMutation({
    mutationFn: ({ data }: UpdateWidgetsListProps) => editModeApi.updateEditModeSettings(data),
    meta: {
      error: translate('errorsNoData.widgetsUpdate'),
    },
    onSuccess: (_, { data, onSuccess }) => {
      queryClient.setQueryData<WidgetsList>([WidgetsQueries.Forecast], (state) => {
        if (state?.widgets) {
          state.widgets = [...state.widgets].map((item) => ({
            ...item,
            order: data.widgets?.find((elem) => elem.id === item.id)?.order ?? 0,
          }))
        }
      })
      onSuccess?.()
    },
  })

  return {
    updateWidgets: mutate,
    isLoadingUpdate: isPending,
  }
}
