import { useDrag, useDrop } from 'react-dnd'

import { Column, Header, Table } from '@tanstack/table-core'
import { TRow } from 'interfaces/table.interfaces'
import { reorderColumn } from 'ui/Table/helpers/reorderColumn'

export const useDragNDrop = <TData extends TRow>(table: Table<TData>, header: Header<TData, unknown>) => {
  const { getState, setColumnOrder } = table
  const { columnOrder } = getState()
  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<TData>) => {
      const newColumnOrder = reorderColumn(draggedColumn.id, header.column.id, columnOrder)
      setColumnOrder(newColumnOrder)
    },
  })
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => header.column,
    type: 'column',
  })

  return { dropRef, isDragging, dragRef, previewRef }
}
