import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/jume/master-data/')({
  component: createPage({
    titleKey: 'titles.masterData',
    lazyImport: {
      fn: () => import('jume/masterData/components/MasterDataHierarchiesList'),
      name: 'MasterDataHierarchiesList',
    },
  }),
})
