import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/jume/promo-optimizer-demo')({
  component: createPage({
    titleKey: 'titles.optimizerDemo',
    lazyImport: {
      fn: () => import('jume/optimizerDemo/components/OptimizerDemo'),
      name: 'RealignmentPage',
    },
  }),
})
