import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/prgm/create/$promoId')({
  component: createPage({
    titleKey: 'titles.prgmCreate',
    lazyImport: {
      fn: () => import('prgm/createPromo/components/CreatePromoBlock'),
      name: 'CreatePromoBlock',
    },
  }),
})
