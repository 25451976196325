import { devtools } from 'config/devtools'
import { create } from 'zustand'

import { UseEditModeWidgets } from './types'

export const useEditModeWidgets = create<UseEditModeWidgets>()(
  devtools(
    (set) => ({
      widgets: [],
      setWidgets: (state) => {
        set(({ widgets }) => {
          const updatedWidgets = typeof state === 'function' ? state(widgets) : state
          return { widgets: updatedWidgets }
        })
      },
    }),
    {
      store: 'editModeWidgets',
    },
  ),
)
