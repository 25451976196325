import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { ApiErrorsCodes } from 'config/api'
import { useAuthenticated } from 'modules/auth/handlers'
import { systemUpdatingApi } from 'modules/systemUpdating/api'
import { checkUpdateStatusInterval } from 'modules/systemUpdating/constants/checkUpdateStatusInterval'

import { SystemUpdatingQueries } from './types'

export const useSystemUpdating = () => {
  const [isRefetching, setIsRefetching] = useState(false)
  const { authenticated } = useAuthenticated()
  const enabled = !!authenticated

  const {
    data,
    error,
    refetch: refetchInternal,
  } = useQuery({
    queryKey: [SystemUpdatingQueries.SystemUpdating],
    queryFn: systemUpdatingApi.getSystemUpdatingStatus,
    enabled,
    meta: {
      error: {
        showToast: false,
      },
    },
    refetchInterval: isRefetching ? checkUpdateStatusInterval : undefined,
  })

  const refetch = () => {
    refetchInternal()
    setIsRefetching(true)
  }

  useEffect(() => {
    setIsRefetching(!!data?.status)
  }, [data])

  useEffect(() => {
    if (error?.code === ApiErrorsCodes.UnknownError) {
      setIsRefetching(true)
    }
  }, [error])

  return {
    data,
    refetch,
    error,
  }
}
