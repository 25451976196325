import { useMemo } from 'react'

import { TRow } from 'interfaces/table.interfaces'
import { cloneDeep } from 'lodash'
import { CollapsingStrategy } from 'ui/Table/types'

export const useFormatByCollapseStrategy = <TData extends TRow>({
  collapsingStrategy,
  data,
  isCollapsing,
  mappedGroups,
}: {
  mappedGroups?: Record<string, string[]>
  data?: TData[] | null
  isCollapsing?: boolean
  collapsingStrategy?: CollapsingStrategy
}) => {
  const formattedRows = useMemo(() => {
    if (!mappedGroups || !isCollapsing || collapsingStrategy !== 'toggle') {
      return data
    }

    const newRows = cloneDeep(data) as any[]
    const groupToParent: Record<string, number> = {}
    Object.keys(mappedGroups).forEach((group) => {
      const parentAttribute = newRows?.find((row) => row.attribute === mappedGroups[group][0])?.attribute
      if (parentAttribute) {
        groupToParent[group] = parentAttribute
      }
    })
    newRows?.forEach((row) => {
      const group = Object.keys(mappedGroups).find((key) => mappedGroups[key].includes(row.attribute))
      if (group && groupToParent[group] !== row.attribute) {
        row.parent = groupToParent[group]
      }
      if (group && groupToParent[group] === row.attribute) {
        row.isParent = true
      }
    })
    return newRows
  }, [data])

  return { formattedRows }
}
