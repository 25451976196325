import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useWebSocket } from 'ahooks'
import { useTranslate } from 'config/i18n'
import { queryClient } from 'config/queryClient'
import { createWSUrl } from 'config/ws'
import { autoCloseToastDelay } from 'constants/app'
import { useManualWSConnect } from 'hooks/useManualWSConnect'
import { toastIdPrefix } from 'opti/scenarioShared/constants/toastIdPrefix'
import { TetrisScenariosQueries } from 'opti/tetrisScenario/queries/types'
import { useTetrisScenarioCopyingList } from 'opti/tetrisScenario/store'
import { tetrisScenarioWebSocketEndPoint } from 'opti/tetrisScenario/ws/endpoints'
import { transformResponseToCamelCase } from 'packages/api'

export const useCopyTetrisScenarioWS = (projectId?: number, enabled?: boolean) => {
  const translate = useTranslate()
  const url = createWSUrl(tetrisScenarioWebSocketEndPoint.scenarios(projectId).url)
  const { list, clearList } = useTetrisScenarioCopyingList()

  const { connect, disconnect, readyState } = useWebSocket(url, {
    onMessage: (event) => {
      try {
        const { message } = transformResponseToCamelCase(event.data) ?? {}
        const toastId = message?.id ? `${toastIdPrefix.copyTetrisScenario}${message.id}` : null
        if (!!message.copyStatus?.length && message.copyStatus.indexOf('Ошибка') > -1 && toastId) {
          if (message.id && toast.isActive(toastId)) {
            toast.update(toastId, {
              render: message.copyStatus,
              isLoading: false,
              autoClose: autoCloseToastDelay,
              type: 'error',
            })
          } else {
            toast.error(translate('scenarioShared.creatingError'))
          }
        } else {
          if (message.id && toastId && toast.isActive(toastId)) {
            toast.update(toastId, {
              render: message.copyStatus,
              isLoading: false,
              autoClose: autoCloseToastDelay,
              type: 'success',
            })
          } else {
            toast.success(message.copyStatus)
          }
        }
        queryClient.removeQueries({ queryKey: [TetrisScenariosQueries.Scenarios], type: 'inactive' })
        queryClient.refetchQueries({ queryKey: [TetrisScenariosQueries.Scenarios], type: 'active' })
      } catch {
        toast.error(translate('wsErrors.messageRead'))
        list.forEach((item) => {
          toast.dismiss(item)
        })
        clearList()
      }
    },
    manual: true,
  })

  useEffect(() => {
    if (readyState === 3) {
      list.forEach((item) => {
        toast.dismiss(item)
      })
      clearList()
    }
  }, [readyState])

  useManualWSConnect(url, readyState, connect, disconnect, enabled && !!projectId)
}
