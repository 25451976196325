import { axios } from 'config/axios'
import { Streams } from 'constants/streams'
import { GetAllResponse, GetOneResponse, Pagination } from 'interfaces/api.interfaces'
import { GetAllData } from 'packages/api'
import { deleteEmpty } from 'packages/helper'
import { defaultPagination } from 'utils/pagination'

import { projectsEndpoints } from './endpoints'
import { Project, ProjectInfo } from './interfaces'

const getProject = async (projectId: number): GetOneResponse<Project> => {
  const { data } = await axios.request<Project>({
    method: 'GET',
    ...projectsEndpoints.getProject(projectId),
    tracing: {
      stream: Streams.Modules,
      module: 'projects',
      methodName: 'getProject',
    },
  })
  return data
}

const getProjectInfo = async (projectId: number): GetOneResponse<ProjectInfo> => {
  const { data } = await axios.request<ProjectInfo>({
    method: 'GET',
    ...projectsEndpoints.getProjectInfo(projectId),
    tracing: {
      stream: Streams.Modules,
      module: 'projects',
      methodName: 'getProjectInfo',
    },
  })
  return data
}

export const searchProject = async (search?: string, pagination?: Pagination): GetAllResponse<Project> => {
  const { data } = await axios.request<GetAllData<Project>>({
    method: 'GET',
    ...projectsEndpoints.getProjects(),
    params: deleteEmpty({
      ...defaultPagination(pagination),
      title: search,
    }),
    tracing: {
      stream: Streams.Modules,
      module: 'projects',
      methodName: 'searchProject',
    },
  })
  return data
}

export const projectsApi = {
  getProject,
  getProjectInfo,
  searchProject,
}
