import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { UseICActions } from './types'

export const useICActions = create<UseICActions>()(
  devtools(
    persist(
      (set) => ({
        current: null,
        setCurrentAction: (current) => set({ current }),
        clear: () => set({ current: null }),
      }),
      { name: 'currentICAction' },
    ),
    {
      store: 'currentICAction',
    },
  ),
)
