import { FC, ReactNode, useEffect } from 'react'

import cx from 'clsx'
import { Close } from 'ui/Close'

import classes from './Modal.module.scss'

interface ModalProps {
  className?: string
  classNameClose?: string
  classNameTitle?: string
  title?: ReactNode
  onClose?: () => void
  onUnmount?: () => void
  children?: ReactNode
  width?: number
}

export const Modal: FC<ModalProps> = ({
  className,
  classNameClose,
  classNameTitle,
  title,
  onClose,
  onUnmount,
  children,
  width,
}) => {
  useEffect(() => onUnmount, [])

  return (
    <div className={cx(classes.wrap, className)} data-scroll="" style={{ width, maxWidth: width }}>
      <Close className={classNameClose} onClick={onClose} />
      {title && <div className={cx(classes.title, classNameTitle)}>{title}</div>}
      {children}
    </div>
  )
}
