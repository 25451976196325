import { requestBroadcastTokens } from 'config/auth/requestBroadcastTokens'
import { setAxiosAuthorizationToken } from 'config/axios'
import storage from 'config/storage'
import { storageRefreshName } from 'constants/storage'
import { useResetPasswordTokens } from 'modules/resetPassword/store'

export const requestTokensLoader = async () => {
  const shouldSkipRequestTokens = storage.getItem('shouldSkipRequestTokens')
  if (shouldSkipRequestTokens) {
    return
  }
  try {
    const tokens = await requestBroadcastTokens()
    const resetPasswordTokens = useResetPasswordTokens.getState()
    if (resetPasswordTokens.access) {
      resetPasswordTokens.setAccess(tokens.access)
      resetPasswordTokens.setRefresh(tokens.refresh)
      setAxiosAuthorizationToken(tokens.access)
    } else {
      const tokenLS = window.localStorage.getItem(storageRefreshName)
      storage.setToken(tokens.access, !!tokenLS)
      storage.setRefresh(tokens.refresh, !!tokenLS)
      setAxiosAuthorizationToken(tokens.access)
    }
  } catch (e) {}
  storage.setItem('shouldSkipRequestTokens', true, false)
}
