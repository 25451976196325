import { useTranslate } from 'config/i18n'

import { useUnsavedChanges } from './useUnsavedChanges'
import { useUnsavedChangesState } from './useUnsavedChangesState'

export const useUnsavedChangesStateConfirm = () => {
  const translate = useTranslate()

  const unsavedChanges = useUnsavedChangesState((state) => state.unsavedChanges)
  const unsavedItem = unsavedChanges.find((item) => item.isDirty)
  const isDirty = !!unsavedItem
  const message = unsavedItem?.message || translate('unsavedChangesWarning')

  useUnsavedChanges(isDirty, message)
}
