const getCellTruncatedText = (cell: HTMLTableCellElement) => {
  const truncatedCell = cell.querySelector('div.truncate')
  return truncatedCell?.textContent
}

const getCellInputText = (cell: HTMLTableCellElement) => {
  const input = cell.querySelector('input[type="text"]') as HTMLInputElement
  if (input) {
    return input.value
  }
}

export const getCellText = (cell: HTMLTableCellElement) =>
  getCellInputText(cell) || getCellTruncatedText(cell) || cell.textContent
