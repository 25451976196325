import { devtools } from 'config/devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { UseCurrentRecommendations } from './types'

export const useCurrentRecommendations = create<UseCurrentRecommendations>()(
  devtools(
    persist(
      (set) => ({
        open: false,
        inProgress: false,
        previousExistingRecommendations: [],
        setOpen: (open) => set({ open }),
        setInProgress: (value) => set({ inProgress: value }),
        setPreviousExistingRecommendations: (value) => set({ previousExistingRecommendations: value }),
      }),
      {
        name: 'demand.recommendations',
        partialize: ({ open }) => ({ open }),
      },
    ),
    { store: 'demand.recommendations' },
  ),
)
