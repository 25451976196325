import { FC } from 'react'

import { useTitle } from 'hooks/useTitle'

import classes from './NotFoundBlock.module.scss'

export const NotFoundBlock: FC = () => {
  useTitle('titles.404')

  return (
    <div className={classes.wrap}>
      <h1>404</h1>
    </div>
  )
}
