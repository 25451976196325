import { FC } from 'react'

import cx from 'clsx'

import classes from './EditModeChartWidget.module.scss'

interface EditModeChartWidgetProps {
  className?: string
  isBarChart?: boolean
}

export const EditModeChartWidget: FC<EditModeChartWidgetProps> = ({ className, isBarChart }) => (
  <div className={className}>
    <div className={classes.top}>
      {[...Array(4)].map((_, index) => (
        <div className={classes.lineTitle} key={index} />
      ))}
    </div>
    <div className={cx(classes.chartTop, { [classes.barChart]: isBarChart })} />
    <div className={cx(classes.chartBottom, { [classes.barChart]: isBarChart })} />
    <div className={classes.lines}>
      {[...Array(45)].map((_, index) => (
        <div className={classes.line} key={index} />
      ))}
    </div>
  </div>
)
