import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/deploy/data-hub/$cardType')({
  component: createPage({
    titleKey: 'titles.deployDataHubTable',
    lazyImport: {
      fn: () => import('deploy/pages/dataHubTable/DataHubTable'),
      name: 'DataHubTable',
    },
  }),
})
