import { devtools } from 'config/devtools'
import { CustomerProp, MDCustomer } from 'prgm/createPromoForm/api'
import { create } from 'zustand'

interface UseMDCustomer {
  masterData: MDCustomer
  setMasterDataProp: (prop: CustomerProp, value?: number) => void
  setMasterData: (masterData: MDCustomer) => void
  clear: () => void
}

export const useMDCustomer = create<UseMDCustomer>()(
  devtools(
    (set) => ({
      masterData: {},
      setMasterDataProp: (prop, value) =>
        set(({ masterData }) => {
          masterData[prop] = value
          return { masterData }
        }),
      setMasterData: (masterData) => set({ masterData }),
      clear: () => set({ masterData: {} }),
    }),
    {
      name: 'MDCustomer',
    },
  ),
)
