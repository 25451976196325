import {
  createElement,
  CSSProperties,
  ElementType,
  forwardRef,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import cx from 'clsx'
import { scrollControl } from 'components/common/Draggable/helpers/scrollControl'
import { DraggableAppContext } from 'components/common/Draggable/providers/DraggableAppProvider'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { deleteNullableAndFalseInArray, toArray } from 'packages/helper'
import { uid } from 'uid'

import classes from './Draggable.module.scss'
import { initPositions } from './helpers/initPositions'
import { DraggableParams, placeholderReplaceStrategy, PlaceholderStrategy } from './placeholderStrategies'
import { DraggableProvider } from './providers/DraggableProvider'

interface DraggableProps {
  className?: string
  classNameDragging?: string
  classNamePlaceholder?: string
  children?: ReactNode
  enabled?: boolean
  onChange?: (oldIndex: number, newIndex: number, item: any | null, into: any | null) => void
  style?: CSSProperties
  role?: string
  as?: ElementType
  placeholderElement?: ElementType
  placeholderStrategy?: PlaceholderStrategy
  onDragStart?: (params: DraggableParams) => void
  onDragEnd?: (params: DraggableParams) => void
  draggableIsGlobal?: boolean
  maxLevel?: number
  draggableOnPaste?: (newIndex: number, item: any, into: any) => void
  refWrap?: RefObject<HTMLDivElement>
}

export const Draggable = forwardRef<HTMLDivElement, DraggableProps>(
  (
    {
      className,
      classNameDragging,
      classNamePlaceholder,
      children,
      enabled,
      onChange,
      style,
      role,
      as = 'div',
      placeholderElement = 'div',
      placeholderStrategy = placeholderReplaceStrategy,
      onDragStart,
      onDragEnd,
      draggableIsGlobal,
      maxLevel,
      draggableOnPaste,
      refWrap,
    },
    ref,
  ) => {
    const [draggableIndex, setDraggableIndex] = useState<number | null>(null)
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const refRealDraggableIndex = useRef<number | null>(null)
    const refInitialIndex = useRef<number | null>(null)
    const refElementPosition = useRef<{ elementX: number; elementY: number; startPosition: number } | null>(null)
    const refList = useRef<HTMLDivElement>(null)
    const { cbRef } = useCombinedRef<HTMLDivElement>(ref, refList)
    const refDraggableContainer = useRef<HTMLDivElement | null>(null)
    const refDraggableItem = useRef<HTMLElement | null>(null)
    const refPlaceholder = useRef<HTMLElement | null>(null)
    const refRectItem = useRef<{ width: number; height: number } | null>(null)
    const refPositions = useRef<number[] | null>(null)
    const refMetaItems = useRef<any[]>([])
    const refIsOtherList = useRef<boolean>(false)
    const refScrollTimeoutId = useRef<NodeJS.Timeout>()

    const refDraggableId = useRef(uid())
    const { draggableLists, refCommonDraggableItem } = useContext(DraggableAppContext)

    const params: DraggableParams = {
      childrenList: toArray(children, deleteNullableAndFalseInArray),
      placeholderElement,
      classNamePlaceholder,
      classNameDragging,
      draggableIndex,
      refInitialIndex,
      refRectItem,
      refPlaceholder,
      refDraggableItem,
      refCommonDraggableItem,
      refElementPosition,
      refPositions,
      refRealDraggableIndex,
      refList,
      refMetaItems,
      refDraggableContainer,
      draggableIsGlobal,
      draggableLists,
      refDraggableId,
      maxLevel,
      refIsOtherList,
      refWrap,
      isDragging,
    }
    const { childrenList, calculateIndex } = placeholderStrategy(params)

    const onMouseMove = useCallback((event: MouseEvent) => {
      if (
        !refElementPosition.current ||
        !refDraggableItem.current ||
        !refDraggableContainer.current ||
        !refRectItem.current ||
        !refPositions.current ||
        !refPlaceholder.current
      ) {
        return
      }
      const rectContainer = refDraggableContainer.current.getBoundingClientRect()

      const windowX = event.clientX
      const windowY = event.clientY
      const elementX = refElementPosition.current.elementX
      const elementY = refElementPosition.current.elementY
      const translateX = windowX - elementX - rectContainer.left
      const translateY = windowY - elementY - rectContainer.top

      refDraggableItem.current.style.top = `${translateY}px`
      refDraggableItem.current.style.left = `${translateX}px`
      refPlaceholder.current.dataset.draggableIndex = String(refRealDraggableIndex.current)
      setDraggableIndex(calculateIndex(event))
      scrollControl(params, event, refScrollTimeoutId)

      if (draggableIsGlobal) {
        Object.entries(draggableLists).forEach(([id, list]) => {
          if (id !== refDraggableId.current) {
            list.onMouseMove(event)
          }
        })
      }
    }, [])

    const onDragEndInternal = useCallback(() => {
      refDraggableContainer.current?.remove()
      refRectItem.current = null
      refPositions.current = null
      if (!refDraggableItem.current) {
        return
      }
      const currentItem = refInitialIndex.current !== null ? refMetaItems.current[refInitialIndex.current] : null
      if (refRealDraggableIndex.current !== null) {
        onChange?.(
          refInitialIndex.current || 0,
          refRealDraggableIndex.current,
          currentItem,
          refMetaItems.current[refRealDraggableIndex.current],
        )
      }
      setDraggableIndex(null)
      setIsDragging(false)
      refInitialIndex.current = null
      refDraggableItem.current.style.top = ''
      refDraggableItem.current.style.left = ''
      refDraggableItem.current.style.transform = ''
      refDraggableItem.current.style.width = ''
      refDraggableItem.current.style.height = ''
      refDraggableItem.current.style.borderTop = ''
      refDraggableItem.current.classList.remove(classes.dragging)
      if (classNameDragging) {
        refDraggableItem.current.classList.remove(classNameDragging)
      }
      refDraggableItem.current?.setAttribute('data-list-element', 'true')
      onDragEnd?.(params)

      if (draggableIsGlobal) {
        const currentList = draggableLists[params.refDraggableId.current]
        if (currentList) {
          currentList.originalDraggableItem.style.display = ''
          currentList.originalDraggableItem.style.pointerEvents = ''
          currentList.originalDraggableItem.setAttribute('data-list-element', 'true')
          refDraggableItem.current?.remove()
        }
        refCommonDraggableItem.current = refDraggableItem.current
        Object.entries(draggableLists).forEach(([id, list]) => {
          if (id !== refDraggableId.current) {
            list.onDragEnd(currentItem)
          }
        })
      }

      refDraggableItem.current = null
    }, [])

    const onDragStartInternal = useCallback((event: ReactMouseEvent) => {
      const items = Array.from(refList.current?.querySelectorAll<HTMLElement>('[data-list-element=true]') || [])

      if (!refList.current || !refDraggableItem.current) {
        return
      }

      refDraggableContainer.current = document.createElement('div')
      refDraggableContainer.current?.classList.add(classes.draggableContainer)

      if (draggableIsGlobal) {
        document.body.appendChild(refDraggableContainer.current)
      } else {
        refList.current?.parentNode?.insertBefore(refDraggableContainer.current, refList.current.nextSibling)
      }

      refDraggableContainer.current?.addEventListener('mouseup', onDragEndInternal)
      refDraggableContainer.current?.addEventListener('mousemove', onMouseMove)

      const index = Array.from(refList.current.children).indexOf(refDraggableItem.current)
      if (index < 0) {
        return
      }
      setDraggableIndex(index)
      setIsDragging(true)
      refRealDraggableIndex.current = index
      refInitialIndex.current = index
      initPositions(params, items, undefined, event)
      onDragStart?.(params)

      if (draggableIsGlobal) {
        refCommonDraggableItem.current = refDraggableItem.current
        Object.entries(draggableLists).forEach(([id, list]) => {
          if (id !== refDraggableId.current) {
            list.onDragStart()
          }
        })
      }
    }, [])

    const onMouseMoveOther = useCallback((event: MouseEvent) => {
      setDraggableIndex(calculateIndex(event))
      scrollControl(params, event, refScrollTimeoutId)
    }, [])

    const onDragStartOther = useCallback(() => {
      if (!refList.current) {
        return
      }
      const items = Array.from(refList.current.querySelectorAll<HTMLElement>('[data-list-element=true]') || [])
      const listTop = refList.current.getBoundingClientRect().top
      refPositions.current = items.map((item) => item.getBoundingClientRect().top - listTop)
      setDraggableIndex(null)
      setIsDragging(true)
      refInitialIndex.current = 0
      refDraggableItem.current = refCommonDraggableItem.current
      refIsOtherList.current = true

      if (refCommonDraggableItem.current) {
        const rectItem = refCommonDraggableItem.current.getBoundingClientRect()
        const width = rectItem.width
        const height = rectItem.height
        refRectItem.current = { width, height }
      }
    }, [])

    const onDragEndOther = useCallback((currentItem: any) => {
      if (refRealDraggableIndex.current !== null) {
        draggableOnPaste?.(
          refRealDraggableIndex.current,
          currentItem,
          refMetaItems.current[refRealDraggableIndex.current],
        )
      }
      refCommonDraggableItem.current = null
      refDraggableItem.current = null
      refIsOtherList.current = false
      refRectItem.current = null
      setDraggableIndex(null)
      setIsDragging(false)
    }, [])

    useEffect(() => {
      if (draggableIsGlobal) {
        draggableLists[refDraggableId.current] = {
          onMouseMove: onMouseMoveOther,
          onDragEnd: onDragEndOther,
          onDragStart: onDragStartOther,
        }
      }
      return () => {
        delete draggableLists[refDraggableId.current]
      }
    }, [draggableIsGlobal])

    return (
      <DraggableProvider refDraggableItem={refDraggableItem} refMetaItems={refMetaItems}>
        {createElement(
          as,
          {
            className: cx(classes.wrap, className),
            onMouseDown: enabled ? onDragStartInternal : undefined,
            ref: cbRef,
            role,
            style,
          },
          childrenList,
        )}
      </DraggableProvider>
    )
  },
)
