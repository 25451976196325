import { axios } from 'config/axios'
import { Streams } from 'constants/streams'
import { GetArrayResponse, GetOneResponse } from 'interfaces/api.interfaces'

import { userEndpoints } from './endpoints'
import { Permission, Profile, ProfileUpdateData } from './interfaces'

const getProfile = async (): GetOneResponse<Profile> => {
  const { data } = await axios.request<Profile>({
    method: 'GET',
    ...userEndpoints.getInfo(),
    tracing: {
      stream: Streams.Modules,
      module: 'user',
      methodName: 'getProfile',
    },
  })
  return data
}

const getPermissions = async (): GetArrayResponse<Permission> => {
  const { data } = await axios.request<Permission[]>({
    method: 'GET',
    ...userEndpoints.getPermissions(),
    tracing: {
      stream: Streams.Modules,
      module: 'user',
      methodName: 'getPermissions',
    },
  })
  return data
}

const updateInfo = async (data: ProfileUpdateData): GetOneResponse<Profile> => {
  const { data: response } = await axios.request<Profile>({
    method: 'PATCH',
    ...userEndpoints.updateInfo(),
    tracing: {
      stream: Streams.Modules,
      module: 'user',
      methodName: 'updateInfo',
    },
    data,
  })
  return response
}

export const userApi = {
  getProfile,
  getPermissions,
  updateInfo,
}
