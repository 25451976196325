import { axios } from 'config/axios'
import { workflowDashboardPerPage } from 'constants/pagination'
import { Streams } from 'constants/streams'
import { GetAllResponse, GetOneResponse, Pagination } from 'interfaces/api.interfaces'
import { GetAllData } from 'packages/api'
import { deleteEmpty, deleteNullable } from 'packages/helper'
import { defaultPagination } from 'utils/pagination'

import { workflowEndpoints } from './endpoints'
import { UpdateWorkflowData, WorkflowIndicator, WorkflowTableRow } from './interfaces'

const updateWorkflow = async (scenarioId: number, resultId: number, data: UpdateWorkflowData): Promise<void> => {
  await axios.request({
    method: 'PATCH',
    ...workflowEndpoints.updateWorkflow(scenarioId, resultId),
    data: deleteEmpty(data),
    tracing: {
      stream: Streams.Jume,
      module: 'workflow',
      methodName: 'updateWorkflow',
    },
  })
}

const getActiveRequests = async (pagination?: Pagination): GetAllResponse<WorkflowTableRow> => {
  const { data } = await axios.request<GetAllData<WorkflowTableRow>>({
    method: 'GET',
    ...workflowEndpoints.getActiveRequests(),
    params: defaultPagination(pagination, workflowDashboardPerPage),
    tracing: {
      stream: Streams.Jume,
      module: 'workflow',
      methodName: 'getActiveRequests',
    },
  })
  return data
}

const getFinishedRequests = async (pagination?: Pagination): GetAllResponse<WorkflowTableRow> => {
  const { data } = await axios.request<GetAllData<WorkflowTableRow>>({
    method: 'GET',
    ...workflowEndpoints.getFinishedRequests(),
    params: defaultPagination(pagination, workflowDashboardPerPage),
    tracing: {
      stream: Streams.Jume,
      module: 'workflow',
      methodName: 'getFinishedRequests',
    },
  })
  return data
}

const getWorkflowIndicator = async (date: string | null): GetOneResponse<WorkflowIndicator> => {
  const { data } = await axios.request<WorkflowIndicator>({
    method: 'GET',
    ...workflowEndpoints.getWorkflowIndicator(),
    params: deleteNullable({ date }),
    tracing: {
      stream: Streams.Jume,
      module: 'workflow',
      methodName: 'getWorkflowIndicator',
    },
  })
  return data
}

export const workflowApi = {
  updateWorkflow,
  getActiveRequests,
  getFinishedRequests,
  getWorkflowIndicator,
}
