import { useCallback, useMemo } from 'react'

import { DraggableItem } from 'jume/editMode/interfaces'
import { useEditModeWidgets } from 'jume/editMode/store'

export const useUpdateWidgetsLocal = () => {
  const { widgets, setWidgets } = useEditModeWidgets()

  const findWidget = useCallback(
    (id: number) => {
      const widget = widgets.filter((item) => item.id === id)[0]
      return {
        widget,
        index: widgets.indexOf(widget),
      }
    },
    [widgets],
  )

  const applyWidgets = useCallback(() => {
    setWidgets(() => widgets.map((item) => ({ ...item, order: item.newOrder ?? item.order })))
  }, [widgets])

  const changeWidgetOrderInternal = useCallback(
    (id: number, hoveredWidget?: DraggableItem) => {
      const { widget } = findWidget(id)

      setWidgets((prev) => {
        const newArr = [...prev]

        if (hoveredWidget === undefined) {
          newArr.forEach((item) => {
            item.newOrder = item.order > 0 && item.order < widget.order ? item.order + 1 : item.order
          })
          widget.newOrder = 1
          return newArr
        }

        if (widget.order === 0) {
          newArr.forEach((item) => {
            item.newOrder = item.order > hoveredWidget.order ? item.order + 1 : item.order
          })
          widget.newOrder = hoveredWidget.order + 1
          return newArr
        }

        if (hoveredWidget.order > widget.order) {
          newArr.forEach((item) => {
            item.newOrder = item.order > widget.order && item.order <= hoveredWidget.order ? item.order - 1 : item.order
          })
          widget.newOrder = hoveredWidget.order
        }
        if (hoveredWidget.order === widget.order) {
          newArr.forEach((item) => (item.newOrder = item.order))
        }
        if (hoveredWidget.order < widget.order) {
          newArr.forEach((item) => {
            item.newOrder = item.order > hoveredWidget.order && item.order < widget.order ? item.order + 1 : item.order
          })
          widget.newOrder = hoveredWidget.order + 1
        }
        return newArr
      })
    },
    [widgets],
  )

  const deleteWidget = (id: number) => {
    const deletedWidget = findWidget(id)

    setWidgets((prev) => {
      const newArr = [...prev]
      newArr.forEach((item) => {
        const newOrder = item.order > deletedWidget.widget.order ? item.order - 1 : item.order
        item.newOrder = newOrder
        item.order = newOrder
      })
      deletedWidget.widget.order = 0
      deletedWidget.widget.newOrder = 0
      return newArr
    })
  }

  const activeWidgetsCount = useMemo(() => widgets.filter((item) => item.order > 0).length, [widgets])

  return { deleteWidget, findWidget, changeWidgetOrderInternal, applyWidgets, activeWidgetsCount }
}
