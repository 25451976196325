import { cloneElement, forwardRef, ReactNode, useRef } from 'react'

import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { toArray } from 'packages/helper'
import { Loader, LoaderTypes } from 'ui/Loader'

import classes from './Block.module.scss'

export enum BlockColors {
  Default = 'default',
  Light = 'light',
  Primary = 'primary',
}

interface BlockProps {
  className?: string
  classNameContent?: string
  color?: BlockColors
  children?: ReactNode
  scrollable?: boolean
  scrollHorizontal?: boolean
  loadingOnScroll?: boolean
  onScrollBottom?: () => void
  locked?: boolean
}

export const Block = forwardRef<HTMLDivElement, BlockProps>(
  (
    {
      className,
      classNameContent,
      color = BlockColors.Default,
      children,
      scrollable = false,
      loadingOnScroll,
      onScrollBottom,
      scrollHorizontal,
      locked,
    },
    ref,
  ) => {
    const refScroll = useRef<HTMLDivElement>(null)
    const refContent = useRef<HTMLDivElement>(null)
    const { cbRef } = useCombinedRef<HTMLDivElement>(ref, refScroll)

    useInfiniteScroll(onScrollBottom, refScroll, refContent, { enabled: scrollable })

    if (scrollable) {
      return (
        <div className={cx(classes.wrap, className)}>
          <div
            className={cx(classes.scroll, classNameContent, classes[color], 'scroll', 'scroll-mini', {
              scrollHorizontal,
            })}
            ref={cbRef}
          >
            <div className={cx(classes.content, { [classes.scrollHorizontal]: scrollHorizontal })} ref={refContent}>
              {toArray(children).map((el) =>
                Array.isArray(el) || !el || typeof el !== 'object'
                  ? children
                  : cloneElement(el as any, { refScrollVirtual: refScroll }),
              )}
            </div>
          </div>
          <Loader show={loadingOnScroll} type={LoaderTypes.Paginate} />
        </div>
      )
    }

    return <div className={cx(classes.wrap, className, classes[color], { [classes.locked]: locked })}>{children}</div>
  },
)
