import { useCallback } from 'react'

import storage from 'config/storage'
import { authApi } from 'modules/auth/api'
import { useBroadcastAuth } from 'modules/auth/handlers/useBroadcastAuth'

import { useLogoutClear } from './useLogoutClear'

export const useLogout = () => {
  const { logoutClear } = useLogoutClear()
  const { broadcastLogout } = useBroadcastAuth()

  const logout = useCallback(() => {
    const refresh = storage.getRefresh()
    authApi.logoutInform({ refresh })
    logoutClear()
    broadcastLogout()
  }, [logoutClear, broadcastLogout])

  return { logout }
}
