import { devtools } from 'config/devtools'
import { JobsSortingType, JobsStatusFilter } from 'modules/jobs/api'
import { create } from 'zustand'

import { UsePulseTableFilters } from './types'

const initialFilters: JobsStatusFilter = {
  plannedStartBefore: '',
  plannedStartAfter: '',
  actualStartBefore: '',
  actualStartAfter: '',
  status: [],
}

export const useJobsStatusTableFilters = create<UsePulseTableFilters>()(
  devtools(
    (set) => ({
      filters: {
        ...initialFilters,
      },
      setFilterValue: (filter, value) => {
        set(({ filters }) => {
          filters[filter] = value
          return { filters }
        })
      },
      clearFilters: () => set({ filters: { ...initialFilters } }),
      search: '',
      setSearch: (search) => set({ search }),
      clearSearch: () => set({ search: '' }),
      sorting: JobsSortingType.Status,
      setSorting: (sorting) => set({ sorting }),
      clearSorting: () => set({ sorting: JobsSortingType.Status }),
    }),
    {
      store: 'jobsStatusTableFilters',
    },
  ),
)
