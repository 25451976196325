import { createFileRoute } from '@tanstack/react-router'
import { createPage } from 'utils/createPage'

export const Route = createFileRoute('/opti/create-rtm-scenario')({
  component: createPage({
    titleKey: 'titles.createRtmScenario',
    lazyImport: {
      fn: () => import('opti/rtmScenario/components/CreateRtmScenario'),
      name: 'CreateRtmScenario',
    },
  }),
})
