import { useEffect } from 'react'

export const useManualWSConnect = (
  url: string,
  readyState: number,
  connect?: VoidFunction,
  disconnect?: VoidFunction,
  enabled = true,
) => {
  useEffect(() => {
    const hasToken = url.indexOf('token') > -1
    if (hasToken && readyState !== 1 && enabled) {
      connect?.()
    }
    if (!hasToken) {
      disconnect?.()
    }
  }, [url])
}
