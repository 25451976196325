import { useEffect, useRef, useState } from 'react'

import { useMatchRoute, useNavigate, useRouterState } from '@tanstack/react-router'
import { demandRoutes, deployRoutes, jumeRoutes, optiRoutes, ppdsRoutes, prgmRoutes } from 'config/router/routes'
import { getStartDemandPage } from 'demand/shared/helpers/getStartDemandPage'
import {
  isDemand,
  isDeploy,
  isJume,
  isOpti,
  isPpds,
  isPrgm,
  streamDemand,
  streamDeploy,
  streamJume,
  streamOpti,
  streamPpds,
  streamPrgm,
} from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { useProfile } from 'modules/user/queries'

export const useNavigateStream = () => {
  const navigate = useNavigate()
  const matchRoute = useMatchRoute()
  const { profile } = useProfile()
  const currentStream = useCurrentStream((state) => state.currentStream)
  const setCurrentStream = useCurrentStream((state) => state.set)
  const [readyStream, setReadyStream] = useState(false)
  const [isFirstLoading, setIsFirstLoading] = useState(false)
  const refChangeFlag = useRef(true)
  const { isLoading } = useRouterState()

  const jume = profile?.streams?.find(({ title }) => title === streamJume)
  const opti = profile?.streams?.find(({ title }) => title === streamOpti)
  const demand = profile?.streams?.find(({ title }) => title === streamDemand)
  const prgm = profile?.streams?.find(({ title }) => title === streamPrgm)
  const deploy = profile?.streams?.find(({ title }) => title === streamDeploy)
  const ppds = profile?.streams?.find(({ title }) => title === streamPpds)

  useEffect(() => {
    if (isLoading && refChangeFlag.current) {
      setIsFirstLoading(true)
      refChangeFlag.current = false
    } else {
      setIsFirstLoading(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (!profile?.streams) {
      return
    }

    if (!readyStream) {
      if (jumeRoutes.find((path) => matchRoute({ to: path })) && jume) {
        setCurrentStream(jume)
        setReadyStream(true)
        return
      }
      if (optiRoutes.find((path) => matchRoute({ to: path })) && opti) {
        setCurrentStream(opti)
        setReadyStream(true)
        return
      }
      if (demandRoutes.find((path) => matchRoute({ to: path })) && demand) {
        setCurrentStream(demand)
        setReadyStream(true)
        return
      }
      if (prgmRoutes.find((path) => matchRoute({ to: path })) && prgm) {
        setCurrentStream(prgm)
        setReadyStream(true)
        return
      }
      if (deployRoutes.find((path) => matchRoute({ to: path })) && deploy) {
        setCurrentStream(deploy)
        setReadyStream(true)
        return
      }
      if (ppdsRoutes.find((path) => matchRoute({ to: path })) && ppds) {
        setCurrentStream(ppds)
        setReadyStream(true)
        return
      }
    }

    if (!jumeRoutes.find((path) => matchRoute({ to: path })) && isJume(currentStream)) {
      setReadyStream(true)
      navigate({ to: '/jume' })
      return
    }
    if (!optiRoutes.find((path) => matchRoute({ to: path })) && isOpti(currentStream)) {
      setReadyStream(true)
      navigate({ to: '/opti/promo-optimizer' })
      return
    }
    if (!demandRoutes.find((path) => matchRoute({ to: path })) && isDemand(currentStream)) {
      setReadyStream(true)
      navigate({ to: getStartDemandPage() })
      return
    }
    if (!prgmRoutes.find((path) => matchRoute({ to: path })) && isPrgm(currentStream)) {
      setReadyStream(true)
      navigate({ to: '/prgm/pulse' })
      return
    }
    if (!deployRoutes.find((path) => matchRoute({ to: path })) && isDeploy(currentStream)) {
      setReadyStream(true)
      navigate({ to: '/deploy/data-hub' })
      return
    }
    if (!ppdsRoutes.find((path) => matchRoute({ to: path })) && isPpds(currentStream)) {
      setReadyStream(true)
      navigate({ to: '/ppds/materials' })
      return
    }
  }, [profile?.streams, currentStream])

  return { readyStream: readyStream && !isFirstLoading }
}
