import { useEffect } from 'react'

import { useLang } from 'config/i18n'
import { getLang } from 'config/lang'
import { isJume, isOpti } from 'modules/streams/constants/streams'
import { useCurrentStream } from 'modules/streams/store'
import { useProfile } from 'modules/user/queries'

export const useSetLangByStream = () => {
  const currentStream = useCurrentStream((state) => state.currentStream)
  const setLang = useLang((state) => state.setLang)
  const { profile } = useProfile()

  useEffect(() => {
    if (profile && currentStream && !isJume(currentStream) && !isOpti(currentStream)) {
      setLang(profile?.language.code ?? getLang())
    }
  }, [currentStream, profile])
}
