import { useBlocker } from '@tanstack/react-router'
import { useTranslate } from 'config/i18n'
import { useAuth } from 'modules/auth/store'

export const useUnsavedChanges = (isDirty: boolean, message?: string) => {
  const translate = useTranslate()
  const unsavedMessage = message || translate('unsavedChangesWarning')
  const { isLogoutConfirm, setIsLogoutConfirm, isLogout, setIsLogout } = useAuth()
  useBlocker(() => {
    if (isLogout) {
      setIsLogoutConfirm(false)
      setIsLogout(false)
      return isLogoutConfirm
    }
    return window.confirm(unsavedMessage)
  }, isDirty)
}
